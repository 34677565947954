@import '~font-awesome/css/font-awesome.css';
@import './styles.scss';

/*
 * ------ ------ ------ ------ ------ ------
 * body & main-container
 * ------ ------ ------ ------ ------ ------
 */

.material-symbols-filled {
  font-family: 'Material Symbols Rounded';
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

body {
  height: 100vh;
  width: 100vw;
  background-color: $gray-lighter;
  font-family: $font-body;
  overflow: auto !important;
}

#root {
  min-height: 100vh;
  width: 100%;
}

.main-container {
  padding: 0 8px;
}

@media screen and (min-width: $md) {
  .main-container {
    margin: 0;
    padding: 10px 56px;
    width: 100%;
  }
}

.content-container {
  width: 100%;
}

.loader {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.inner-title {
  font-weight: 500;
  color: $text-dark;
}

.error {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  color: $warning;
}

.ReactTable {
  border: none;
  text-align: center;
  height: 100%;
  width: 100%;
  font-size: 16px;
  .rt-tr {
    align-items: stretch !important;
    // border-radius: 10px;
  }

  .rt-thead,
  .rt-thead.-header {
    // background-color: $primary;
    // border-radius: 10px;
    box-shadow: none;
    color: #000000;
  }

  .rt-tr {
    align-items: center;
  }

  .rt-thead .rt-th {
    border-radius: 0px;
    height: auto;
    border: none;
    background-color: #ffffff;
    // color: $light;
    border-bottom: 1px solid #000000;
    font-family: $font-body;
    font-size: 11px;
    @media (min-width: $xl) {
      font-size: 16px;
    }
    font-weight: 500;
    padding: 10px 0;

    // &:first-child {
    //   border-top-left-radius: 10px;
    //   border-bottom-left-radius: 10px;
    // }

    // &:last-child {
    //   border-top-right-radius: 10px;
    //   border-bottom-right-radius: 10px;
    // }
  }

  .rt-tbody {
    height: 100%;
    width: 100%;
    // min-height: 200px;
    background-color: #ffffff;
    overflow: hidden;

    .rt-tr {
      padding-top: 2.6px;
      padding-bottom: 2.6px;
      border-radius: 8px;
      @media (min-width: $lg) {
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 12px;
      }
    }
    .rt-td {
      text-align: center;
      border: none;
      color: $text-dark;
      font-size: 11px;
      @media (min-width: $xl) {
        font-size: 16px;
      }
      font-weight: normal;
      padding: 25px 0 10px;
      &:last-child {
        margin-top: -8px;
      }
    }
  }
  .migration .rt-tbody {
    .rt-td {
      &:last-child {
        margin-top: 0;
      }
    }
  }
  .rt-noData {
    //border-bottom: solid 3px $bg-body;
    font-family: $font-head;
    font-size: 24px;
    padding: 50px 0;
    position: inherit;
    left: 0;
    top: 0;
    transform: none;
  }

  .share-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    img {
      background-color: $primary;
      border-radius: 32px;
      height: 32px;
      width: 32px;
    }
  }

  .paged-drawer {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    //border-top: solid 3px $bg-body;
    margin-top: 20px;
    padding-top: 15px;
    width: 100%;
  }
}

/*
 * ------ ------ ------ ------ ------ ------
 * texts
 * ------ ------ ------ ------ ------ ------
 */

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: $font-head;
}

p,
span,
label {
  font-family: $font-body;
}

input,
textarea {
  font-family: $font-body;
}

a,
button {
  cursor: pointer;
  font-family: $font-body;
}

a {
  color: $primary;

  &:hover {
    color: #5397ff;
  }
}

i {
  height: 36px;
  width: 36px;
  display: flex !important;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}

/*
 * ------ ------ ------ ------ ------ ------
 * texts colors
 * ------ ------ ------ ------ ------ ------
 */

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-black {
  color: $dark;
}

.text-dark {
  color: $text-dark;
}

.text-regular {
  color: $text-regular;
}

.text-light {
  color: $text-light;
}

.text-white {
  color: $light;
}

.text-success {
  color: $success;
}

.text-warning {
  color: $warning;
}

/*
 * ------ ------ ------ ------ ------ ------
 * background colors
 * ------ ------ ------ ------ ------ ------
 */

.bg-primary {
  background-color: $primary;
}

.bg-secondary {
  background-color: $secondary;
}

.bg-black {
  background-color: $dark;
}

.bg-dark {
  background-color: $gray-dark;
}

.bg-regular {
  background-color: $gray-regular;
}

.bg-light {
  background-color: $gray-light;
}

.bg-white {
  background-color: $light;
}

.bg-success {
  background-color: $success;
}

.bg-warning {
  background-color: $warning;
}

/*
 * ------ ------ ------ ------ ------ ------
 * mixins
 * ------ ------ ------ ------ ------ ------
 */

@mixin box-shadow($x, $y, $blur, $z: 0, $color: rgba(85, 85, 85, 0.1)) {
  -webkit-box-shadow: $x $y $blur $color;
  -moz-box-shadow: $x $y $blur $color;
  -o-box-shadow: $x $y $blur $color;
  box-shadow: $x $y $blur $color;
}

@mixin transform($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin transition($property, $duration) {
  -webkit-transition: $property $duration;
  -moz-transition: $property $duration;
  -o-transition: $property $duration;
  transition: $property $duration;
}

/*
 * ------ ------ ------ ------ ------ ------
 * common classes
 * ------ ------ ------ ------ ------ ------
 */

.avoid-overflow {
  overflow: auto;
}

.card {
  @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.1));
  background-color: $bg-card;
  border-radius: 12px;
  @media (min-width: $lg) {
    border-radius: 8px;
  }
  padding: 20px 30px;
  margin-bottom: 30px;
  &:hover {
    @include box-shadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.1));
  }

  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: solid 1px #d6d6d6;
    margin-bottom: 20px;
    overflow: visible;
    flex-wrap: wrap;

    .card-header-left,
    .card-header-right {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      button {
        margin-left: 10px;
      }
    }

    .search-bar {
      margin-left: 20px;
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      border-radius: 30px;

      i {
        color: $gray-light;
      }

      .input {
        color: $gray-light;
        background-color: transparent;
        margin: 0;
        padding: 0;

        input {
          border: none;
          border-radius: 30px;
          background-color: #f5f5f5;
        }
      }
    }

    .back {
      display: flex;
      align-items: center;

      p {
        margin-left: 10px;
      }
    }

    p {
      color: $text-dark;
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .table {
    margin-bottom: 0;
  }
}

.wordwrap {
  white-space: pre-line !important;
  word-wrap: break-word;
}

// form {
//   .input,
//   .dropdown-container,
//   .autocomplete {
//     padding: 0;
//     margin-bottom: 15px;
//   }
// }

.clickable {
  cursor: pointer;
}

// .disabled {
//   opacity: 0.5;
// }

.font-head {
  font-family: $font-head;
}

.font-body {
  font-family: $font-body;
}

.no-border {
  border: none;
}

.message-success {
  opacity: 0;

  &.show {
    @include transition(all, 0.4s);
    opacity: 1;
  }
}

.message-warning {
  opacity: 0;

  &.show {
    @include transition(all, 0.4s);
    opacity: 1;
  }
}

.modal-close {
  cursor: pointer;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

/*
 * ------ ------ ------ ------ ------ ------
 * box-shadows
 * ------ ------ ------ ------ ------ ------
 */

.box-shadow-high {
  @include box-shadow(0, 6px, 30px);
}

.box-shadow-regular {
  @include box-shadow(0, 5px, 20px);
}

.box-shadow-low {
  @include box-shadow(0, 4px, 10px);
}

/*
 * ------ ------ ------ ------ ------ ------
 * transitions
 * ------ ------ ------ ------ ------ ------
 */

.transition-slow {
  @include transition(all, 1.2s);
}

.transition-regular {
  @include transition(all, 0.8s);
}

.transition-fast {
  @include transition(all, 0.4s);
}

/*
 * ------ ------ ------ ------ ------ ------
 * transaction states
 * ------ ------ ------ ------ ------ ------
 */

.REQUEST-SENT,
.REQUEST-CONFIRMED,
.REQUEST-UPDATED {
  color: $success !important;
}

.REQUEST-APPROVED,
.REQUEST-UNFUNDED {
  color: $primary !important;
}

.REQUEST-CANCELED {
  color: $warning !important;
}

.card-header-right {
  // position: absolute;
  // right: 25px;
  // top: 20px;
}
.nav-tabs {
  border-bottom: 0;
  opacity: 100%;
}
.nav-tabs > li {
  border-radius: 20px;
  z-index: 99;
}
.nav-tabs > li > a {
  font-size: 14px;
  font-weight: $font-regular;
  color: $dark;
  border-radius: 10px 10px 0px 0px;
  border: 0;
  line-height: normal;
  padding: 18px 32px;
  @media (min-width: $lg) {
    font-size: 20px;
    padding: 26px 46px;
  }
}

.nav-tabs > li.active > a {
  border: 0;
  color: black;
  font-weight: $font-bold;
}
.nav-tabs > li.active > a:focus {
  color: $dark;
  border: 0;
}
.nav-tabs > li.active > a:hover {
  color: $dark;
  border: 0;
}
.tab-content {
  background-color: $bg-card;
  padding: 24px 32px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0px 12px 12px 12px;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*
 * ------ ------ ------ ------ ------ ------
 * react-bootstrap navbar collapse control
 * ------ ------ ------ ------ ------ ------
 */

@media (max-width: 1279px) {
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin: 0;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-text {
    float: none;
    margin: 15px 0;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .collapsing {
    overflow: hidden !important;
  }
}
#notification-wrapper {
  .toast-notification {
    bottom: 20px;
    top: auto !important;
    span {
      background-color: transparent !important;
    }

    .snackbar {
      max-width: auto;
      min-height: 74px;
      background-color: #c4ffd8;
      border-radius: 12px !important;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
      font-size: $font-md;
      font-weight: $font-bold;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 2px 29px;
      @media (min-width: $fix1919) {
        font-size: $font-lg;
      }
      .title {
        display: flex;
        align-items: center;
        .material-symbols-rounded {
          padding: 14px 4px 0 0px;
        }
        h1 {
          font-weight: $font-bold;
          margin-left: 4px;
          margin-bottom: 3px;
          font-size: $font-lg;
          @media (max-width: 1279px) {
            font-size: 13px;
          }
        }
      }
      p {
        font-size: $font-md;
        font-weight: $font-regular;
        padding-left: 4px;
        @media (max-width: 1279px) {
          font-size: 11px;
        }
      }
    }
    .success {
      color: $success;
      border-left: solid 4px $success;
      background-color: #c4ffd8;
    }
    .error {
      color: $danger;
      border-left: solid 4px $danger;
      background-color: #ffc4c4;
    }
  }
}

.react-tooltip {
  z-index: 999;
  border-radius: 8px !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  padding: 4px 8px !important;
  @media (max-width: $xl) {
    border-radius: 12px !important;
    font-size: 13px !important;
  }
}
.react-tooltip-arrow {
  width: 0.1px;
  height: 0.1px;
}
.tab-content {
  height: calc(100vh - 206px);
  @media (min-width: $xl) {
    height: calc(100vh - 275px);
  }
}

.tab-content > .active {
  height: 100%;
  .table {
    padding-top: 20px;
    margin-bottom: 0;
    height: calc(100% - 20px);
    @media (min-width: $xl) {
      height: calc(100% - 40px);
    }
    .rt-table {
      height: 100%;
    }
  }
}

.ReactTable {
  .rt-table {
    overflow: hidden !important;
    height: auto;
  }
  .rt-thead {
    flex: 0;
  }
  .rt-tbody {
    overflow-y: auto;
    min-height: auto;
    // height: 100%;
  }
}
* {
  scrollbar-width: thin;
  scrollbar-color: $gray-light transparent;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    cursor: default;
    @media (min-width: $xl) {
      width: 8px;
      height: 8px;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-light;
    border-radius: 3px;
    @media (min-width: $xl) {
      border-radius: 6px;
    }
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
