@import '../../../styles/styles.scss'

.public-layout
    height: 100vh
    background-image: $gradient
    .row
        .wrapper-logo
            padding: 0
            display: flex
            justify-content: center
            &.bapro-container
                display: flex
                flex-direction: column
                align-items: center
                margin-bottom: 15px
            .bapro-header
                display: flex
            img
                width: 180px
                height: auto
            .baprologo
                width: 180px
                height: 125px
                object-fit: contain
            @media screen and (min-width: 992px)
                padding: 40px 0 0 40px
                display: block
            @media screen and (min-width: $lg)
                margin-top: 30px
                justify-content: flex-start

        .wrapper-content
            height: auto
            top: 0
            display: flex
            justify-content: center
            @media screen and (min-width: 992px)
                flex-direction: column
                align-items: center

            @media screen and (min-width: $lg)
                .children-content
                    max-width: 415px
                    max-height: 100%
                    left: -90px
                    position: relative
            .children-content
                height: auto
                margin-top: 0
        .wrapper-language
            padding-right: 40px
            text-align: center
            @media screen and (min-width: 992px)
                padding-top: 42px
                padding-right: 40px
                text-align: right
    .public-footer
        overflow: hidden
        text-align: center
        position: relative
        left: 0
        right: 0
        bottom: 25px
        @media screen and (min-height: 630px) and (min-width: 500px) and (max-width: $lg)
            position: absolute
        @media screen and (min-height: 780px) and (min-width: $lg)
            position: absolute
        .public-footer-span
            user-select: none
            letter-spacing: 1rem
            color: $light
            font-size: 13px
            text-transform: uppercase
            text-align: center
            @media screen and (min-width: 605px)
                letter-spacing: 2rem
            @media screen and (min-width: 815px)
                letter-spacing: 3rem
            @media screen and (min-width: 1025px)
                letter-spacing: 4rem
            @media screen and (min-width: 1235px)
                letter-spacing: 5rem
            @media screen and (min-width: $lg)
                letter-spacing: 5rem
                font-size: 19px
            @media screen and (min-width: 1520px)
                letter-spacing: 6rem
