@import '../../../styles/styles.scss'

button, a
  &.button
    margin: auto
    border-radius: 8px
    font-weight: $font-bold
    padding: 6px 15px
    display: flex
    min-width: 177px
    flex-direction: row
    align-items: center
    justify-content: center
    text-decoration: none
    @media (min-width: $lg)
      min-width: 240px
      border-radius: 12px
      padding: 10.5px 15px
    &:not(.text-link-button)
      box-shadow: 0 4px 15px 0 rgba($dark, 0.05)
    .material-symbols-rounded
      font-size: 15px
      @media (min-width: $lg)
        font-size: 20px
      &.icon-left
        margin-right: 8px
      &.icon-right
        margin-left: 8px
    img
      margin-right: 10px
    span
      text-transform: uppercase
      font-size: 10px
      @media (min-width: $lg)
        font-size: 14px
    &.primary
      background-color: $primary
      border: solid 2px $primary
      color: $light
      .material-symbols-rounded
        color: $light
      &.disabled
        background-color: $gray-light
        border-color: $gray-light
        color: $gray-regular
        span
          color: $gray-regular
        &:hover
          background-color: $gray-light
          border-color: $gray-light
          color: $gray-regular
      &:hover:enabled
        background-color: $primary-hover
        border-color: $primary-hover
        &.disabled
          background-color: $gray-light
          border-color: $gray-light
          color: $gray-regular
    &.secondary
      background-color: $light
      border: solid 2px $primary
      color: $primary
      .material-symbols-rounded
        color: $primary
      &:disabled
        background-color: rgba($dark, .05)
        border-color: $gray-light
        color: $gray-regular
        .material-symbols-rounded
          color: $gray-regular
    &.text-link-button
      color: $primary
      font-size: 12px
      &.normal-button
        color: $dark
    &.delete-button
      color: #cb3333
      font-size: 14px
      &.user-screen
        font-size: 16px
    &.language-toggle
      background-color: $gray-light
      margin: 0 11.1px
      padding: 7px 14px 6.5px 14px
      border-radius: 6px
      font-size: 11px
      color: $gray-regular
      &:active
        color: $language-font
        background-color: $lightblue-active
