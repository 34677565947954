@import '../../../../styles/styles.scss'
.PersonalData
    overflow: hidden
    .custom-field
        color: #1e7bb0
    div
        display: flex
        align-items: center
        white-space: nowrap
        border-bottom: none
        padding-bottom: 3px
        padding-top: 3px
        p
            margin: 0
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
            font-size: 11px
            @media ( min-width: $xl)
                font-size: 16px
        &.personal-data-div
            display: flex
            flex-direction: column   
            align-items: normal
        &.item
            display: flex     
            align-items: flex-end
            justify-content: space-between
            border-bottom:  1px solid #d6d6d6
    .item-label
        padding-right: 20px
        p
            font-weight: 700
            line-height: normal
        .material-symbols-filled
            font-size: 14px
            margin-right: 4px
            @media ( min-width: $xl)
                font-size: 20px
    .item-value
        font-weight: 500
        .content_copy
            font-size: 14px
            color: $primary
            margin-left: auto
            @media ( min-width: $xl)
                font-size: 20px
    .disabled-item
        color: #7a7a7a

    
        