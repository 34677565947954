@import '../../../styles/styles.scss'
.persons-custom-container
  .nav-tabs
    li
      a
        font-size: 14px
        @media (min-width: $lg)
          font-size: 20px
  .dropdown-container 
    &.users-dropdown
      .dropdown
        position: relative
        button
          background: rgba(255,255,255,0 )
          label
            span
              color: #333
          &:hover
            background-color: rgba(255,255,255,0 )
            color: $primary
          label
            span
              color: $gray-dark
        .dropdown-menu
          background-color: white
          position: absolute
          top: 0
          left: 0
          max-height: auto
          width: 224px
          padding: 0
          border-radius: 8px 0 8px 8px
          // height: 120px
          li
            //width: 224px
            //height: 60px
            // flex-grow: 0
            // display: flex
            // flex-direction: row
            // justify-content: flex-start
            // align-items: center
            // gap: 8px
            font-weight: 500
            padding: 0
            // border: solid 1px var(--colors-styles-gris-5)
            p
              padding: 12px 32px 
              margin: 0
              display: flex
              align-items: center
              @media (min-width: $lg)
                font-size: $font-lg
              span
                margin-right: 5px
                font-size: 20px
        .show
          left: -50px !important
    .form-dropdown
      .dropdown-menu
        .search-bar
          background-color: transparent !important
          padding-right: 15px
  .tab-content
    position: relative
    .card-header-right
      position: absolute
      top: -45px
      @media (min-width: $xl)
        top: -53px
      right: 0px
      padding-right: 14px
      display: flex
      z-index: 99
      .button
        margin-left: 8px
        padding-top: 8px
        padding-bottom: 8px 
        font-weight: 700
        &.button-download-report
          border: 1px solid $primary
          color: $primary
          &.disabled
            border: 1px solid $gray-regular
            color: $gray-regular
  .ReactTable
    .rt-thead
      .rt-tr
        border-bottom: 1px solid #000000
        .rt-th
          display: flex
          justify-content: flex-start
          padding: 0 9px
          //margin: 0 14px 0 
          font-size: 11px
          &.checkbox-header
            padding-left: 0
          &.email-table
            width: 150px !important
            @media (min-width: $lg)
              width: 200px !important
          &.game-date-table
            display: flex
            justify-content: center
          &.campaign-table
            display: flex
            justify-content: flex-start
          @media (min-width: $lg)
            font-size: $font-lg
          border-bottom: none
          font-weight: 500
          &.-sort-asc, &.-sort-desc
            .header-table
              background-color: $lightblue-active
              padding: 3px 12px
              padding-right: 2px
              border-radius: 8px
              font-weight: 600
              @media (min-width: $lg)
                border-radius: 12px
                padding: 5px 12px
                padding-right: 2px
          .header-table
            display: flex
            align-items: center
            padding: 0
            margin: 0
            .material-symbols-rounded
              @media (min-width: $lg)
                font-size: 30px
            .react-tooltip
              margin-left: 20px
              font-size: 11px !important
              @media (min-width: $lg)
                font-size: initial !important
        .-sort-asc
          box-shadow: none
        .-sort-desc
          box-shadow: none
    .rt-tbody
      //min-height: 270px
      .rt-tr-group
        padding: 0
        margin: 0
        flex: 0 1
        // height: auto
        transition: all .3s ease-in-out
        background-color: white
        max-height: 80px
        .rt-tr
          //padding: 0 9px
          // flex: 1
          margin: 9px 0
          &:hover
            background-color: $gray-lighter
          .rt-td
            display: flex
            justify-content: flex-start
            align-items: center
            //margin: 0 14px 0
            padding: 0
            padding-left: 9px
            // height: 37px
            font-size: 11px
            &.checkbox-column
              padding: 0
            .large-text
              text-overflow: ellipsis
              overflow: hidden
              white-space: nowrap
            &.email-table
              width: 150px !important
              padding-left: 9px
              @media (min-width: $lg)
                width: 200px !important
            @media (min-width: $lg)
              font-size: $font-lg
            &.actions-table
              overflow: visible
            &.game-date-table
              display: flex
              justify-content: center
              margin: 0
              padding: 0 .5rem 0 0
            &.campaign-table
              display: flex
              justify-content: flex-start
              padding-left: 14px
            .full-name
              display: flex
              flex-direction: column
              text-align: start
              overflow: visible
              width: 100%
              .code
                margin-top: 3px
                font-size: 8px
                width: 100%
                @media (min-width: $lg)
                  font-size: 11px
                color: #7a7a7a
              span
                overflow: hidden
                text-overflow: ellipsis
            &.person-profile
              span
                overflow: hidden
                text-overflow: ellipsis
          .resultt
            overflow: hidden
            text-overflow: ellipsis
            
      .selected-row
        background-color: $lightblue 
        border-radius: 12px
        transition: all .3s ease-in-out
        
  .text-link-button
    .icon-left
      font-size: 20px
      // color: #000
  .filters
    width: 100%
    @media (min-width: $lg)
      // margin-bottom: 29px
    .square-button
      label
        font-size: 11px
        @media (min-width: $lg)
            font-size: $font-lg
        color: $gray-regular
    .filters-container
      display: flex
      align-items: center
      flex-wrap: wrap
      justify-content: flex-start
      gap: 15px  
      .delete-button-container
        margin: 0
        margin-left: auto
        .button
          margin: 0
          padding: 0
          min-width: auto
          span
            text-transform: none
            font-weight: 500
          &.delete-button
            padding: 0
            margin: 0
      .filter-date
          display: flex
          align-items: center
      .dropdown-menu-test
          .material-symbols-rounded
            color: #000
            font-weight: 700
            font-size: 30px
            // padding: 2px
          .date-button
            width: 188px
            @media (min-width: $lg)
              width: auto
            label
                font-weight: 500
      .search-input-container
        input
          border: 1px solid $gray-lighter
          //height: 32px
          //margin: 0
          //padding: 9px 15px 9px 40px
          //width: 100%
          //line-height: normal
          //width: 216px
          //@media (min-width: $lg)
          //  height: 45px
          //  width: 340px
          //  padding: 9px 15px 9px 53px
          @media (min-width: $lg) 
            padding-top: 10px
            padding-bottom: 10px
          &.active
            border: 1px solid $lightblue-active
            border-radius: 8.5px
          &:focus
            outline: none
            border: 1px solid $lightblue-active
          
      .dropdown-menu-test
        .date-button
          font-size: 12px
          max-width: 184px
          @media (min-width: $lg)
            max-width: 300px
            font-size: $font-lg
          /* padding: 15 5px !important */
          &.test
            // height: auto
            // padding: 10px 14px
            border: 1px solid $gray-lighter
          &.active
            // border: 1px solid $lightblue-active
          label
              margin: 0
      .dropdown-container
          // min-width: 131px
          &.age
            .form-dropdown
              .dropdown-menu
                clip-path: inset(0px -10px -10px -10px)
                top: 65% !important
          .form-dropdown
              .material-symbols-rounded
                color: #000
                font-weight: 700
                // font-size: 23px
              // padding: 10px 5px
              text-align: flex-start
              max-width: 131px
              width: 131px
              @media (min-width: $lg)
                max-width: 184px
                min-width: 184px
              &.selected
                border: solid 1px $lightblue-active
              // button
              //     label
              //         overflow: auto
              .dropdown-menu
                  z-index: 100
                  min-width: 100%
                  border-top-left-radius: 0px
                  border-top-right-radius: 0px
                  
                  .dropdown-title
                    padding: 14px 17.5px
                    justify-content: start
                  @media (min-width: $lg)
                    .dropdown-title
                      padding: 20px 17.5px
                      span
                        font-size: $font-lg
                  li
                    font-size: 11px
                    @media (min-width: $lg)
                      font-size: $font-lg
                  .search-bar
                    position: relative
                    width: 100%
                    background-color: #000
                    .fa-search
                      position: absolute
                      background: none
                      top: -6px
                      left: -10px
                      @media (min-width: $lg)
                        top: -1px
                        font-size: $font-xl 
                      color: $gray-light
                      z-index: 2
                    .input
                      background: white
                      z-index: 1
                      input
                        padding-left: 26px
                        @media (min-width: $lg)
                          padding-left: 30px
              button
                  // gap: 56px
                  display: flex
                  justify-content: space-between
                  margin: 0
                  width: 100%
                  height: 100%
                  label
                      font-weight: 500
                      margin: 0
                      overflow: hidden
                      text-overflow: ellipsis
                      white-space: nowrap
                      text-align: left
    .emotional-intelligence-check
      margin-left: 6px
  .table-pagination-container
    .multi-select
      font-size: 11px
      .total-select
        color: $primary
        font-weight: 500
      .selected
        font-weight: 400
        color: #888888  
    .table-pagination-text
      margin-left: auto
    .table-pagination-buttons-container
      .material-symbols-rounded
        // color: $primary
        // font-weight: 700
      // .table-paginations-buttons-alt
      //   justify-content: center
      //   align-items: center
      //   .dropdown-container
      //     .dropdown
      //       button
      //         display: flex
      //         justify-content: center
      //         margin: 0
      //         padding: 5px 0
      //         text-align: center
      //         label
      //           margin: 0
       