@import '../../../styles/styles.scss'

.disabled
    opacity: 0.5
.input
    font-size: 11px
    font-weight: 400
    margin-bottom: 15px
    .input-label
        display: flex
        align-items: center
        .Tooltip
            button
                span
                    font-size: 16px
                    @media screen and (min-width: $lg)
                        font-size: 24px
    @media screen and (min-width: $lg)
        font-size: 16px
        margin-bottom: 25px
        .input-label
            margin-left: 7px
            margin-bottom: 7px
            font-weight: $font-bold

    .input-label-container
        .input-label
            font-size: 11px
            span.required-icon
                font-weight: $font-bold
                color: $danger

            @media screen and (min-width: $lg)
                font-size: 16px
                margin-left: 2px
                span.required-icon
                    font-size: 16px

    .input-holder
        position: relative
        font-size: 11px
        max-width: 216px
        @media screen and (min-width: $fix1919)
            font-size: 16px
            font-weight: 400
            min-width: 300px
        input
            padding: 5px 10px
            font-size: 11px            
            border-radius: 8px
            border: solid 1px $gray-light
            width: 100%

            &:focus-visible
                outline: 0
            &:active, &:focus, &.active
                border-color: $active !important
            &.border-error
                border-color: $danger
            &.border-success
                border-color: $success

        @media screen and (min-width: $lg)
            input
                border-radius: 12px
                padding: 8px 30px
                font-size: 16px

        button
            span
                top: 0
                color: $primary

        button.show-password
            top: 0
            position: absolute
            right: 15px
            bottom: 0
            color: $primary
            @media screen and (min-width: $lg)
                top: 3px
            span
                font-size: 20px

    label.bottom-text-label
        margin-top: 10px
        margin-left: 4px
        margin-right: 15px
        font-weight: $font-regular
        color: $gray-dark
        font-size: $font-xs
        &.danger
            color: $danger

    #inputId::placeholder
        color: #888
