@import '../../../styles/styles.scss';

.modal-content {
  min-width: 300px !important;
  .filter-modal-body {
    display: flex;
    flex-flow: column nowrap;
    padding: 30px 40px !important;

    form {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;

      .form-column > div {
        width: 100%;
        padding: 5px;
        padding-top: 0;
        padding-left: 0;

        .dropdown-container {
          max-width: 100%;
          max-height: 100px;
        }

        .dropdown {
          button {
            width: 100%;
            label {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .form-column:nth-child(1) {
        margin-right: 30px;
        width: 250px;
      }

      .form-column:nth-child(2) {
        width: 300px;

        //naw497 - if too tall, dropdown options overlap filter button
        .dropdown:nth-child(2) button label {
          -webkit-line-clamp: 2;
        }

        .age-title {
          padding: 0;
        }

        .age-row {
          display: flex;
          flex-flow: row nowrap;

          .dropdown-container:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 765px) {
  .modal-content .filter-modal-body form {
    flex-flow: row wrap;
  }
}

.inconsistent-profile-check {
  margin-left: 6px;
}
