@import '../../../styles/styles.scss'
.toolbox-column-container
    background-color: $gray-lighter
    border-radius: 12px
    display: flex
    width: 245px
    @media (min-width: $xl)
        width: 345px
    flex-direction: column
    .toolbox-column-body
        flex: 1
        overflow: auto
        padding: 0 15px
        @media (min-width: $xl)
            padding: 0 24px
        .bottom-text
            margin-top: auto
    .toolbox-column-footer
        padding-top: 10px
    .toolbox-column-head
        padding: 10px 15px
        .toolbox-column-filters
            .remove-selection
                margin-top: 10px
                flex: 1
            .search-input
                clear: both
                &:focus
                    border: none !important
                input
                    &:focus
                        border: none!important
                    &.active
                        box-shadow: none
                        border: 1px solid #AEE8F9 !important
            .dropdown-container
                width: 50%
                float: left
                margin: 0
                padding-bottom: 8px
                &:nth-child(2n+1)
                    padding-right: 4px
                &:nth-child(2n+2)
                    padding-left: 4px
                .form-dropdown
                    max-width: none
                    button
                        label
                            white-space: nowrap
                            text-align: left
            &.user-account
                .dropdown-container
                    &:first-child
                        width: 100%
                        padding-left: 0
                        padding-right: 0
                    &:nth-child(2n+2)
                        padding-right: 4px
                        padding-left: 0
                    &:nth-child(2n+3)
                        padding-left: 4px
                        padding-right: 0
        .header
            display: flex
            justify-content: space-between
        .Tooltip
            .tooltip
                bottom: 34px
                margin-left: 0px
                @media (min-width: $xl)
                    bottom: 42px
                .icon
                    padding-bottom: 5px
                .tooltip-inner
                    font-size: $font-xs
                    @media (min-width: $xl)
                        font-size: $font-sm
            button
                font-size: 18px
                @media (min-width: $xl)
                    font-size: 25px
    &.positions
        .toolbox-column-head
            .toolbox-column-filters
                .dropdown-container
                    width: 100%
                    padding-left: 0
                    padding-right: 0