@import '../../../../src/styles/styles.scss'

div
  .main-container
    .reports-container
      div
        .tab-content
          .tab-pane
            .ReactTable
              .rt-table
                .rt-thead
                  .rt-tr
                    .rt-th
                      display: flex
                      justify-content: flex-start
                      padding: 0 9px
                              //margin: 0 14px 0 
                      font-size: 11px
                      @media (min-width: $fix1919)
                        font-size: 16px
                      .rt-resizer
                        display: none
                      div
                        span
                          display: flex
                          align-items: center
                        .material-symbols-rounded
                          font-size: 30px
                      &.-sort-asc, &.-sort-desc
                        box-shadow: none
                        div
                          padding: 0.4rem
                          background-color: #8EE7FF
                          width: fit-content
                          border-radius: 10px
                        .header-table
                          background-color: $lightblue-active
                          padding: 3px 12px
                          padding-right: 2px
                          border-radius: 8px
                          font-weight: 600
                          @media (min-width: $lg)
                            border-radius: 12px
                            padding: 5px 12px
                            padding-right: 2px
                            .header-table
                              display: flex
                              align-items: center
                              padding: 0
                              margin: 0
                              .material-symbols-rounded
                                @media (min-width: $lg)
                                  font-size: 30px
                                  .react-tooltip
                                    margin-left: 20px
                                    font-size: 11px !important
                                  @media (min-width: $lg)
                                    font-size: initial !important
                              .-sort-asc
                                box-shadow: none
                              .-sort-desc
                                box-shadow: none
                .rt-tbody
                  .rt-tr-group
                    padding: 0
                    margin: 0
                    flex: 0 1
                    transition: all .3s ease-in-out
                    background-color: white
                    max-height: 80px
                    .rt-tr
                      margin: 9px 0
                      &:hover
                        background-color: $gray-lighter
                      .rt-td
                        display: flex
                        justify-content: flex-start
                        align-items: center
                        padding: 0
                        padding-left: 5px
                        text-align: start
                        font-size: 11px
                        @media (min-width: $fix1919)
                          font-size: 16px
                          &.checkbox-column
                            padding: 0
                            .large-text
                              text-overflow: ellipsis
                              overflow: hidden
                              white-space: nowrap
                              &.email-table
                                width: 150px !important
                                padding-left: 9px
                                @media (min-width: $lg)
                                  width: 200px !important
                                  @media (min-width: $lg)
                                    font-size: $font-lg
                                  &.actions-table
                                    overflow: visible
                                  &.game-date-table
                                    display: flex
                                    justify-content: center
                                    margin: 0
                                    padding: 0 .5rem 0 0
                                  &.campaign-table
                                    display: flex
                                    justify-content: flex-start
                                    padding-left: 14px
                                  .full-name
                                    display: flex
                                    flex-direction: column
                                    text-align: start
                                    overflow: visible
                                    width: 100%
                                  .code
                                    margin-top: 3px
                                    font-size: 8px
                                    width: 100%
                                    @media (min-width: $lg)
                                      font-size: 11px
                                      color: #7a7a7a
                                  span
                                    overflow: hidden
                                    text-overflow: ellipsis
                              .resultt
                                overflow: hidden
                                text-overflow: ellipsis

                          .selected-row
                            background-color: $lightblue
                            border-radius: 12px
                            transition: all .3s ease-in-out
                        &:last-child
                          overflow: visible
                      .share-row
                        width: 100%
                        .dropdown-container
                          display: flex
                          justify-content: center
                          .dropdown
                            position: relative
                            button
                              color: black
                              border: none
                              padding: 0
                              display: flex
                              justify-content: center
                              background-color: rgba(0,0 ,0 ,0 )
                              label
                                margin: 0
                                display: flex
                                align-items: center

                                span
                                  &:nth-child(2):not(.share-button)
                                    display: none

                                    .dropdown-menu
                                      border-radius: 12px
                                      border-top-right-radius: 0
                                      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                                      left: -50px !important
                                      li
                                        padding: 0
                                        margin: 0
                                        height: auto
                                        width: auto
                                        min-width: auto
                                        border-bottom: solid 1px #f6f6f6
                                        overflow: hidden
                                        button
                                          font-weight: 500
                                          background-color: transparent
                                          min-width: 1rem
                                          padding: 0
                                          color: black
                                          box-shadow: none
                                          margin: 0
                                          width: 100%
                                          min-height: 100%
                                          display: flex
                                          align-items: center
                                          padding: 12px 35px
                                          @media (min-width: $lg)
                                            padding: 18.5px 42px
                                            &.disabled
                                              opacity: 1
                                              span
                                                color: $gray-light
                                                opacity: 1
                                                .material-symbols-rounded
                                                  margin: 0
                                                  color: black
                                                  display: flex
                                                  justify-content: center
                                                  align-items: center
                                                  padding-right: 0.3rem
                                                  span
                                                    text-transform: none
                                                    &:last-child
                                                      display: flex
                                                      justify-content: flex-start
                                                      width: 100%
                                                      padding: 0.5rem
                                                      padding-top: 0.5rem
                                                      &:last-child
                                                        span
                                                          color: #cb3333
              .pagination-bottom
                display: none
