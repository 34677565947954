@import '../../../styles/styles.scss'

.filter-date
  .dropdown-container
    border-radius: 12px
    &:hover button.date-button
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important
    &.open-calendar button.date-button, .active
      box-shadow: none
      border: 1px solid #AEE8F9 !important
    .dropdown-menu-test
      .date-button
        height: auto
        float: right
        justify-content: space-between
        display: flex
        align-items: center
        padding: 5px 15px
        width: 184px
        @media (min-width: $lg)
          padding: 6.5px 15px
          width: 240px
          //border: 1px solid #d6d6d6
        .material-symbols-rounded
          font-size: 20px
          @media (min-width: $lg)
            font-size: 30px
            font-weight: bold
            color: #000

.test
  color: #aaaaaa
  border: 1px solid #f6f6f6
  border-radius: 8px
  span
    color: #000
    font-weight: 600
  @media (min-width: $lg)
    border-radius: 12px
  // width: 25rem
    width: auto
    width: 265px
  label
    line-height: normal
    margin: 0
    font-size: 11px
    @media (min-width: $lg)
      font-size: 16px

.test:hover
  background-color: white
  color: #aaaaaa

.date-button
  position: relative
  .custom-dropdown-menu-components
    // &.admin-buttons-fix
    //   .apply-date-button
    //     top: 36rem
    // &.on-dash
    //   right: 8rem
    //   .apply-date-button
    //     top: 36rem
    //   @media (min-width: $xl)
    //     right: 1rem
    // &.on-creds
    //   right: 8rem
    //   .apply-date-button
    //     top: 39rem !important
    //   @media (min-width: $xl)
    //     right: -0.5rem
    //     right: 1rem
    // &.buttons-fix
    //   .apply-date-button
    //     top: 39rem !important
    //   @media (min-width: $xl)
    //     right: -0.5rem
    .apply-date-button
      // position: relative !important
      // top: 39.2rem
      // left: 71rem
      // margin-left: 0 !important
      font-family: 'Poppins'
      font-style: normal
      font-weight: 700
      font-size: 14px
      line-height: 16px
      padding: 8px 16px
      z-index: 10
      // margin-left: 3.5rem !important
      // width: 92px
      // height: 32px
      // min-width: 0px
      // position: absolute
      // top: 23pc
      // right: -34pc
      background-color: #773bd0
      color: white
      // padding-left: 2rem !important
      // padding-right: 2rem !important
      // border-radius: 16px !important

// .space
//   width: 35px
#wrapper-date-picker-range
  display: inline-block
  // background-color: red
  position: relative
  padding-bottom: 10px
  padding-right: 15px
  margin-top: 20px
  border-radius: 14px
  background-color: #FFF
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
  .apply-date-button
    margin: 0
    min-width: auto
    float: right
    margin-right: 10px
    padding-inline: 25px
.collapse-date
  margin-top: 0px !important
  margin-bottom: 0px !important
  margin-left: 52pc

.rdrDateRangePickerWrapper
  border: 0rem
  // width: 51.5pc
  //margin-left: -35.9pc
  // margin-top: 20px
  // border-radius: 14px
  // border: 0rem
  // background-color: #EFF2F7
  // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
  // min-height: 425px
  .rdrMonthAndYearWrapper
    height: auto

.rdrCalendarWrapper
  background: transparent !important
  // box-shadow: -1px 7px 8px 4px rgba(0, 0, 0, 0.2)
  border-radius: 0 14px 14px 0
  border: 0px
  // padding-bottom: 6rem
  z-index: 0

.rdrStaticRanges
  padding: 10px
  .rdrStaticRange
    border-radius: 8px
    border: 0px
    button
      padding: 0

  .rdrStaticRange:hover
    background-color: #EFF2F7
    .rdrStaticRangeLabel:hover
      background-color: #EFF2F7 !important

.rdrStaticRangeLabel
  color: #7a7a7a
  font-weight: 100
  border-radius: 8px
  white-space: nowrap
  // padding-right: 0rem !important
  // margin-top: 1rem
  // margin-bottom: 1rem
  // padding-top: 0rem !important
  // padding-bottom: 0rem !important

.filter-date
  display: flex
  gap: 15px
  align-items: center
  .tooltip-container
    display: flex
    align-items: center
  .Tooltip
    display: flex
    z-index: unset
    button
      // padding-top: 12px

.filter-date-button
  margin-left: 5pc

.rdrDefinedRangesWrapper
  width: auto !important
  border-radius: 14px 0 0 14px
  border: 0px !important
  background-color: transparent !important
  // box-shadow: -7px 11px 8px rgba(0, 0, 0, 0.2)
  z-index: 1

.rdrMonthAndYearPickers
  display: flex
  justify-content: flex-start !important
  margin-left: 0.4rem

.rdrMonthAndYearPickers
  select
    text-align-last: left
    text-transform: capitalize
    padding-left: 5px !important

.rdrMonthName
  text-transform: capitalize
  color: #2B2B2B !important
  padding: 5px 5px !important

.rdrWeekDay
  text-transform: capitalize
  color: #2B2B2B !important

.rdrNextPrevButton
  display: none !important

.rdrMonth
  width: 20.30em !important
  &:first-child
    margin-right: 5px

.rdrDay
  height: 36px !important
  line-height: 36px !important
  position: relative
  // max-width: 32px
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge
    top: 2px !important
    bottom: 2px !important
  .rdrStartEdge
    position: absolute
    left: 0rem
    color: #8EE7FF !important
    border-radius: 10px 0 0 10px !important
    // margin-top: -0.45rem !important
    // height: 3.5rem
    // width: 4.5rem

.rdrInRange
  color: #D7F4FC !important
  // margin-top: -0.45rem !important
  border-radius: 0 !important
  // height: 3.5rem
  // width: 4.5rem

.rdrEndEdge
  color: #8EE7FF !important
  border-radius: 0 10px 10px 0 !important
  // margin-top: -0.45rem !important
  // height: 3.5rem
  // width: 4.5rem

.rdrMonth
  .rdrDays
    .rdrDay
      .rdrDayStartPreview
        color: #8EE7FF !important
        border-radius: 0rem
        height: 32px
        margin-top: -1px

.rdrMonth
  .rdrDays
    .rdrDay
      .rdrDayInPreview
        color: #8EE7FF !important
        height: 32px
        border-radius: 0rem
        margin-top: -1px

.rdrMonth
  .rdrDays
    .rdrDay
      .rdrDayEndPreview
        color: #8EE7FF !important
        border-radius: 0rem
        height: 32px
        margin-top: -1px
        // margin-top: -0.25rem
        // width: 4.5rem
        // height: 3.5rem

.rdrDayHovered
  .rdrDayEndPreview
    border-radius: 0rem !important

.rdrDay
  .rdrDayNumber
    span
      color: #7A7A7A !important

.rdrDayWeekend
  .rdrDayNumber
    span
      color: #AAAAAA !important

.rdrDay:not(.rdrDayPassive)
  .rdrStartEdge + .rdrDayNumber
    span
      color: white !important

.rdrDay:not(.rdrDayPassive)
  .rdrInRange + .rdrDayNumber
    span
      color: #6F9AEE !important

.rdrDay:not(.rdrDayPassive)
  .rdrEndEdge + .rdrDayNumber
    span
      color: white !important

.rdrDay:not(.rdrDayPassive)
  .rdrStartEdge + span + .rdrDayNumber
    span
      color: white !important

.rdrDay:not(.rdrDayPassive)
  .rdrInRange + span + .rdrDayNumber
    span
      color: #6F9AEE !important

.rdrDay:not(.rdrDayPassive)
  .rdrEndEdge + span + .rdrDayNumber
    span
      color: white !important

.rdrDayToday
  .rdrDayStartPreview + .rdrDayNumber,
  .rdrDayEndPreview + .rdrDayNumber,
  .rdrEndEdge + .rdrDayNumber,
  .rdrStartEdge + .rdrDayNumber,
  .rdrStartEdge + .rdrDayNumber,
  span:nth-child(2) + .rdrDayNumber,
  span:nth-child(2) + .rdrDayNumber,
  span:nth-child(2) + .rdrDayNumber,
  span:nth-child(2) + .rdrDayNumber,
  span:nth-child(2) + .rdrDayNumber
    background-color: transparent !important
  .rdrDayNumber, &.rdrDayHovered .rdrDayNumber
    border-radius: 3rem
    background-color: #ECD4FF !important
    color: #7A7A7A !important
    height: 32px
    top: 2px !important
    bottom: 2px !important
  span:after
    display: none
  .rdrDayNumber
    span
      font-weight: bold !important

.rdrDayPassive
  &.rdrDayToday
    background-color: white !important
  .rdrDayNumber
    span
      color: white !important

.rdrDayDisabled
  background-color: white !important

@media screen and (max-width: 1200px)
  .filter-date .primary
    width: auto !important

  .card-header-custom .header-container .header-right button
    margin-bottom: 5px
    margin-right: 0
    width: 20%

  .rdrDateRangePickerWrapper
    width: 31pc
    margin-left: 0px
    margin-top: 20px
    // box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.2)

  .date-button
    .custom-dropdown-menu-components
      .apply-date-button
        // left: 38rem

  .rdrStaticRangeLabel
    // width: 8pc
    // z-index: 1

  .test
    width: 25rem !important

  .rdrMonthAndYearPickers
    display: flex
    justify-content: flex-start !important
    select
      padding-left: 5px !important

  .rdrMonthAndYearPickers
    select
      text-align-last: left

  .rdrDay:not(.rdrDayPassive)
    .rdrStartEdge + .rdrDayNumber
      span
        color: white !important

  .rdrDay:not(.rdrDayPassive)
    .rdrInRange + .rdrDayNumber
      span
        color: #6F9AEE !important

  .rdrDay:not(.rdrDayPassive)
    .rdrEndEdge + .rdrDayNumber
      span
        color: white !important
