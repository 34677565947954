@import '../../../styles/styles.scss';

// hay muchas cosas comentadas como ayuda a ver los estilos
// que se heredan...

// note: el header de persons tiene 4 estados
.persons-custom-container {
  min-width: 300px;
  vertical-align: top;
}
.card-header-custom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .header-left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .search-bar {
        margin-left: 20px;
        margin-right: 10px;
        border-radius: 8px;
        &.active {
          border: 1px solid $active;
        }
        .input {
          .input-holder {
            color: #333;
          }
        }
        i {
          color: #7a7a7a;
        }
        @media (min-width: $lg) {
          border-radius: 12px;
        }
      }
      .filter-button {
        display: flex;
        color: white;
        border-radius: 8px;
        padding: 10.5px 15px;
        background-color: $primary;
        font-size: 15px;
        text-align: center;
      }

      .filter-container {
        display: flex;
        flex-direction: row;
        margin-right: 10px;

        button {
          height: 36px;
          margin-left: 7px;
          white-space: nowrap;
        }
      }
    }

    .header-right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .other-actions {
        display: flex;
        button {
          height: 36px;
          margin-right: 7px;
          white-space: nowrap;
        }
      }
      .date-picker {
        margin-right: 8px;
        .filter-date {
          .date-button {
            label {
              font-weight: 500;
            }
            .dropdown-menu-test {
              .ul-menu {
                width: 16pc;
                position: absolute;
                z-index: 1000 !important;
                max-height: 200px;
                border-radius: 15px;
                cursor: pointer;
                list-style: none;
                margin-top: -12px;
                padding: 0;
                width: 100%;
                right: 660px;
              }
            }
          }
          .tooltip-container {
            div {
              .dateRangeFilter {
                transform: translate(-110px, -122px);
              }
            }
          }
        }
      }
    }
  }
}

.consumed-credits-check {
  padding-top: 34px;
}

.filter-destination {
  margin: 5px;
}
.filter-submit {
  margin-top: 40px;
  margin-left: 6px;
}

.migration-table {
  .rt-table {
    .rt-thead {
      .rt-tr {
        .rt-th:nth-child(1) {
          flex: 0 0 auto !important;
        }
      }
    }
    .rt-tbody {
      .rt-td {
        padding: 0;
        display: flex;
        align-items: center;
        margin: 0;

        // range from 2 to 5 (covers name, surname, profile and email)
        &:nth-child(n + 2):nth-child(-n + 5) {
          span {
            display: flex;
            justify-content: start;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        // range from 8 to 11 (covers campaigns, city and gameDate)
        // &:nth-child(n + 8):nth-child(-n + 10) {
        //   text-align: start;
        // }
        // &:last-child {
        //   padding-top: 0rem !important;
        // }
        .cell-campaign {
          width: 100%;
          display: flex;
          justify-content: center !important;
        }
        .selection-name {
          display: flex;
          justify-content: start;
        }

        .delete-button {
          display: flex;
          justify-content: center;
        }
        .email-address {
          margin-left: 0;
        }
      }
    }
    .rt-th.-cursor-pointer {
      text-align: start;
      padding-top: 2%;
      padding-left: 2%;
    }

    .rt-th {
      &:last-child {
        padding-top: 2%;
      }
    }
  }

  .rt-thead {
    .square-button {
      margin-top: 5%;
    }
  }
}

.multi-select {
  display: flex;
  float: right;
}
.action-button {
  margin-left: 30%;
}

.date-person {
  margin-left: -10px;
  margin-right: -6px !important;
}

.list-person {
  display: block;
  z-index: unset;
}

.list-selected-person {
  display: block;
}

.cell-content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.cell-content-check {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header-title {
  font-size: 1.3rem;
  width: 100%;
  padding-top: 1rem;
}

// .rt-td {
//   .mail-address {
//     background-color: red;
//   }
// }

// .rt-tbody {
//   .rt-td {
//     background-color: green;
//     overflow: visible;
//     width: 100%;
//     span {
//       background-color: red;
//     }
//   }
// }

@media screen and (max-width: 1250px) {
  .persons-table {
    .rt-table {
      .rt-tbody {
        .rt-td {
          overflow: hidden;
        }
      }
    }
  }

  .card-header-custom {
    // display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin-bottom: 5px !important;
    }

    .header-container {
      // width: 100%;
      // display: flex;
      // flex-direction: row;
      flex-wrap: wrap;
      // align-items: center;
      // justify-content: space-between;

      .header-left {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        margin-bottom: 5px;

        .search-bar {
          margin-left: 0;
        }
      }

      .header-right {
        // display: flex;
        // flex-direction: row;
        // align-items: flex-start;
        margin-bottom: 5px;

        // button {
        //   height: 36px;
        //   margin-left: 5px;
        //   white-space: nowrap;
        // }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .card-header-custom {
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;

    .header-container {
      // width: 100%;
      // display: flex;
      flex-direction: column;
      align-items: center;
      // align-items: center;
      // justify-content: space-between;

      .header-left {
        // display: flex;
        // flex-direction: row;
        flex-direction: column;
        // align-items: center;
        width: 100%;
        // padding: 0 60px;

        .search-bar {
          width: 100%;
          margin-bottom: 5px;
          margin-right: 0;
          div {
            width: 100%;
          }
        }

        .filter-container {
          width: 100%;
          margin-right: 0;

          button {
            margin: 0;
          }

          button:nth-child(2) {
            width: 100%;
            margin-left: 5px;
          }
        }
      }

      .header-right {
        // display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        width: 100%;
        // padding: 0 60px;

        // report generate dropdown
        div {
          width: 100%;
        }

        button {
          // height: 36px;
          // margin-left: 7px;
          // white-space: nowrap;
          margin-bottom: 5px;
          margin-right: 0;
          width: 100%;

          // clear selection button
          &.button.p-0.bg-warning.text-white.no-border {
            width: max-content;
          }
        }
      }
    }
  }
}

@media (min-width: 1300px) {
  .rt-th {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 6rem;
  }

  .header-title {
    position: absolute;
    font-size: 1rem;
    padding-bottom: 1;
    width: 100%;
    left: 0;
    top: 2.5rem;
    display: flex;
    justify-content: center;
  }
}

.consumed-credits-check {
  padding-top: 34px;
}

.filter-destination {
  margin: 5px;
}
.filter-submit {
  margin-top: 40px;
  margin-left: 6px;
}
.persons-table {
  .rt-table {
    .rt-tbody {
      .rt-td {
        // range from 2 to 5 (covers name, surname, profile and email)
        &:nth-child(n + 2):nth-child(-n + 5) {
          text-align: flex-start;
        }

        // range from 8 to 11 (covers campaigns, city and gameDate)
        &:nth-child(n + 8):nth-child(-n + 10) {
          text-align: flex-start;
        }
      }
    }
    .rt-th.-cursor-pointer {
      text-align: flex-start;
      padding-top: 2%;
      padding-left: 2%;
    }

    .rt-th {
      &:last-child {
        padding-top: 2%;
      }
    }
  }

  .rt-thead {
    .square-button {
      margin-top: 5%;
    }
  }

  .remove-user {
    min-width: auto;
    span.icon-left {
      margin-right: 0;
      font-size: 20px;
    }
  }
}

.multi-select {
  display: flex;
  float: right;
}
.action-button {
  margin-left: 30%;
}

.date-person {
  margin-left: -10px;
  margin-right: -6px !important;
}

.list-person {
  display: block;
}

.list-selected-person {
  display: block;
}
