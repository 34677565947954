@import '../../../styles/styles.scss'

.ie-person-card-cointainer
    width: 100%
    .ie-header
        display: flex
        width: 100%
        align-items: center
        border-bottom: 1px solid #d6d6d6
        gap: 8px
        padding: 8px 0
        .material-symbols-filled
            font-size: 32px
            color: #B01E7E
        h3
            font-size: 20px
            font-weight: 500
            line-height: 1.06
            margin: 0
            color: #000
        button
            margin-left: auto
            padding: 8px 14px
            min-width: 0
            .material-symbols-rounded
                margin-right: 6px
        .loader
            margin: 0
            display: flex
            flex-grow: 1
            justify-content: end
            .nwm-loader-mini
                height: 40px
                width: 40px
                display: inline-block
                position: relative
    .ie-results-container
        margin: 20px 0 10px 0
    .invitation
        p
            font-size: 16px
            font-weight: 500
            text-align: center
        button
            margin-inline: auto
            span
                font-size: 16px
            .material-symbols-rounded
                font-size: 20px
        &.without-invitation
            margin-block: 32px
        &.with-invitation
            margin-top: 32px
            margin-bottom: 24px
    @media (max-width: $fix1919)
        .ie-header
            gap: 5px
            .material-symbols-filled
                font-size: 22px
            h3
                font-size: 14px
                line-height: 1.06
        p
            font-size: 11px
            margin-top: 16px
        .invitation
            p
                font-size: 12px
                font-weight: 500
                text-align: center
            &.without-invitation
                margin-block: 20px
            &.with-invitation
                margin-top: 20px
                margin-bottom: 16px
            button
                span
                    font-size: 12px
                .material-symbols-rounded
                    font-size: 20px
