@import '../../../styles/styles.scss'

body
    .modal-backdrop.in
        animation: fadeinFont 1s

@keyframes fadeinFont
    from 
        opacity: 0
    to   
        opacity: .5

@keyframes fadeinModal
    from 
        opacity: 0
    to   
        opacity: 1

.MainModal.modal
    &.in .modal-dialog
        width: 890px
        @media ( max-width: $lg )
            width: auto
        animation: fadeinModal 1s
    .carousel-control
        position: relative
        color: $dark
        &.right
            background-image: none
        &.left
            background-image: none
    .modal-content
        border-radius: 32px
        border: none
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
        @media (max-width: $fix1919)
            width: fit-content
            max-height: 551px
        .modal-footer
            display: flex
            flex-direction: row
            justify-content: flex-end
            border-top: none
            padding: 29px 21px
            @media ( max-width: $lg )
                padding: 0 21px 10px
            .footer-display
                width: 100%
                display: flex
                justify-content: space-between

                .footer-display-bottom-right
                    width: 100%
                    display: flex
                    align-items: flex-end
                    justify-content: flex-end
                    align-items: center
                .redirect-link
                    display: flex
                    justify-content: center
                    align-items: center
                    with-space: nowrap
                    width: 170px
                    border: 2px solid $primary
                    border-radius: 8px
                    text-decoration: none
                    font-weight: $font-bold
                    font-size: 10px
                    padding: 6px 0px
                    @media (min-width: $lg)
                        font-size: 14px
                        width: 250px
                    &:hover
                        color: $primary
                    &:visited
                        color: $primary
                    span
                        font-size: 17px
                        padding: 0 10px 0 0
                button.button
                    min-width: 170px
                    margin: 0 8px
                    &.primary
                        span.material-symbols-rounded
                            padding-top: 1px
                    &.secondary
                        min-width: 218px
            .onboarding-footer
                .redirect-link
                    width: inherit
