@import '../../../styles/styles.scss'

.player-detail-container
    flex: 1
    padding-right: 5px
    overflow: hidden
    margin: 10px 0 10px 0
    flex: 1
    p
        font-size: 10px
        font-weight: 500
        text-overflow: ellipsis
        overflow: hidden
        color: #000    
        margin: 0 0 2px 0
        @media (min-width: $xl)
            overflow: visible
            font-size: 14px
    .group-code
        @media (min-width: $lg)
            display: flex
        .detail-code
            margin: 0 5px 4px 0          
            padding: 2px 8px
            background: #F6F6F6
            width: fit-content
            display: flex
            align-items: center
            border-radius: 100px
            span
                font-weight: 500
                color: #7A7A7A
                padding: 0 5px 0 0
                font-size: 9px
                @media (min-width: $fix1919)
                    font-size: 11px