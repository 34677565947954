@import '../../../../styles/styles.scss';

.generatePdf-div {
  padding: 50px 30px 50px 20px;

  .header-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-bottom: 30px;

    img {
      width: 300px;
    }
  }

  // note: deje comentado todos los selectors a la tabla por si se tiene que cambiar en un futuro
  .ReactTable {
    padding: 20px 0;
    // border: none;
    // text-align: center;
    // height: 100%;
    // width: 100%;

    // .rt-tr {
    //     align-items: stretch !important;
    //     border-radius: 10px;
    // }

    // .rt-thead,
    // .rt-thead.-header {
    //     background-color: $primary;
    //     border-radius: 10px;
    //     box-shadow: none;
    // }

    // .rt-tr {
    //     align-items: center;
    // }

    .rt-thead .rt-th.-sort-desc,
    .rt-thead .rt-th.-sort-asc {
      box-shadow: none;
    }

    // .rt-thead .rt-th {
    //     border-radius: 0px;
    //     height: auto;
    //     border: none;
    //     color: $light;
    //     font-family: $font-body;
    //     font-size: 12px;
    //     font-weight: bold;
    //     padding: 10px 0;

    //     &:first-child {
    //         border-top-left-radius: 10px;
    //         border-bottom-left-radius: 10px;
    //     }

    //     &:last-child {
    //         border-top-right-radius: 10px;
    //         border-bottom-right-radius: 10px;
    //     }
    // }

    .rt-tbody {
      height: 100%;
      width: 100%;
      overflow: hidden;

      .rt-td {
        text-align: center;
        border: none;
        color: $text-dark;
        font-size: 12px;
        font-weight: normal;
        padding: 25px 0 10px;
        height: 60px;

        &:last-child {
          padding-top: 25px;
        }
      }
    }

    // .rt-noData {
    //     border-bottom: solid 3px $bg-body;
    //     font-family: $font-head;
    //     font-size: 24px;
    //     padding: 50px 0;
    //     position: inherit;
    //     left: 0;
    //     top: 0;
    //     transform: none;
    // }

    // .share-row {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-evenly;

    //     img {
    //         background-color: $primary;
    //         border-radius: 32px;
    //         height: 32px;
    //         width: 32px;
    //     }
    // }

    // .paged-drawer {
    //     display: flex;
    //     align-items: center;
    //     flex-direction: row-reverse;
    //     justify-content: space-between;
    //     border-top: solid 3px $bg-body;
    //     margin-top: 20px;
    //     padding-top: 15px;
    //     width: 100%;
    // }
  }
}

/*
    depending on how many columns, how much margin you need
    
    es probable que se rompa si se toca cualquier cosa de css o del
    render
    
    "> div:nth-child(3)":
        n=1 -> header; n=2 => primer tabla    
        segunda tabla, ajuste con respecto a la primera
    "> div:nth-child(n+4)":
        tercer div en mas son tablas (la segunda hasta la ultima)
        ubica las tablas en su pagina con respecto a las anteriores
*/
.one-column {
  > div:nth-child(3) {
    margin-top: 55px;
  }
  > div:nth-child(n + 4) {
    margin-top: 75px;
  }
}

.two-columns {
  > div:nth-child(3) {
    margin-top: 55px;
  }
  > div:nth-child(n + 4) {
    margin-top: 73px;
  }
}
.three-columns {
  > div:nth-child(3) {
    margin-top: 50px;
  }
  > div:nth-child(n + 4) {
    margin-top: 73px;
  }
}
.four-columns {
  > div:nth-child(3) {
    margin-top: 30px;
  }
  > div:nth-child(n + 4) {
    margin-top: 60px;
  }
}
