@import '../../../styles/styles.scss'

.container-ie-results
    .header
        display: flex
        align-items: center
        justify-content: space-between
        h3
            font-weight: 600
            font-size: 16px
            margin: 0
            @media (min-width: $lg)
                font-size: 20px
        .ads-result
            font-size: 12px
            font-weight: 500
            border-radius: 25px
            padding: 2px 4px
            max-height: 18px
            margin-left: 5px
            display: flex
            align-items: center
            @media (min-width: $lg)
                font-size: 14px
                padding: 4px 8px
                max-height: 22px
            &.very-high
                color: #1EB050
                background-color: #C4FFD8
            &.high
                color: #8AB01E
                background-color: #D7FFC4
            &.half
                color: #F2C242
                background-color: #FFF2C4
            &.low
                color: #FF9060
                background-color: #FFDDC4
            &.very-low
                color: #CB3333
                background-color: #FFC4C4
        .collapse-details
            display: flex
            align-items: center
            cursor: pointer
            p
                margin: 0
        .header-left
            display: flex
            align-items: center
    .bar
        margin-left: auto
        display: flex
        .result:first-child
            border-top-left-radius: 25px
            border-bottom-left-radius: 25px
            margin-left: 0
        .result
            height: 16px
            margin: 10px 3px 24px 3px
            flex-basis: 20%
            @media (min-width: $lg)
                height: 22px
            &.very-high
                background-color: #1EB050
            &.high
                background-color: #8AB01E
            &.half
                background-color: #F2C242
            &.low
                background-color: #FF9060
            &.very-low
                background-color: #CB3333
            &.none
                background-color: #F6F6F6
        .result:last-child
            border-top-right-radius: 25px
            border-bottom-right-radius: 25px
            margin-right: 0
    .details
        margin-bottom: 15px
        .first-row
            display: inline-flex
            flex-wrap: nowrap
            gap: 24px
            .strengths
                font-weight: 600
                flex-basis: 50%
                padding: 0 12px
                .title
                    font-size: 14px
                    display: flex
                    align-items: center
                    @media (min-width: $lg)
                        font-size: 16px
                    p
                        font-size: 14px
                        margin: 12px 0 15px 8px
                        @media (min-width: $lg)
                            font-size: 16px
                    span
                        font-size: 20px
                        @media (min-width: $lg)
                            font-size: 24px
                .strengths-detail
                    font-size: 12px
                    font-weight: 500
                    @media (min-width: $lg)
                        font-size: 14px
                    ul
                        padding: 0 0 0 20px
                        li
                            p
                                margin: 0
        .opportunities
            display: flex
            .opportunity
                border-radius: 12px
                background: var(--colors-styles-gris-5, #F6F6F6)
                display: flex
                padding: 10px 8px
                flex-direction: column
                align-items: center
                flex: 1 0 0
                font-size: 8px
                font-weight: 700
                margin-right: 24px
                color: #404040
                @media (min-width: $lg)
                    font-size: 14px
                    padding: 12px 16px
                span
                    font-size: 20px
            .opportunity:last-child
                margin-right: 0
