//fonts
$font-body: 'Poppins', sans-serif;
$font-head: $font-body;

$font-bold: 600;
$font-semibold: 500;
$font-regular: 400;
$font-light: 200;

//palette
$primary: #773db0;
$secondary: #ceb033;

$primary-hover: #643097;

$success: #1eb050;
$warning: #f48f50;
$danger: #cb3333;
$danger-background: #ffc4c4;
$active: #8ee7ff;
$gradient: linear-gradient(
  61deg,
  #976ee7,
  #9846ea 9%,
  #7551ef 24%,
  #506dea 35%,
  #288be5 52%,
  #11b3e6 64%,
  #00d1e6 76%,
  #9ddfe2 91%,
  #c1e0de 100%
);
$dark: #000;
$darkFont: #2b2b2b;
$gray-dark: #333;
$gray-regular: #aaaaaa;
$gray-light: #d6d6d6;
$gray-lighter: #f6f6f6;
$light: #fff;
$lightblue: #d7f4fc;
$lightblue-active: #aee8f9;
$lightblue-intense: #63ddff;
$light-yellow: #fff2c4;
$light-yellow-intense: #f2c242;
$yellow: #ffbf60;
$blue: #608dec;
$language-font: #1e7bb0;

//palette - backgrounds
$bg-head: #fff;
$bg-body: #dcebf9;
$bg-card: #fff;

//palette - borders
$input-border-color: #d4d8dd;
$input-border-color-selected: $primary;

//palette - buttons
$btn-primary: $primary;
$btn-secondary: $secondary;

$btn-primary-hover: $primary-hover;
$btn-secondary-hover: #ffdb40;

$btn-success: $success;
$btn-warning: $warning;

//palette - text
$text-dark: $gray-dark;
$text-regular: $gray-regular;
$text-light: $gray-light;

// text size
$font-xxs: 8px;
$font-xs: 10px;
$font-sm: 12px;
$font-md: 14px;
$font-lg: 16px;
$font-xl: 20px;
$font-xxl: 40px;

// screen size
$mobile: 480px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;

$xs: 400px;
$sm: 768px;
$md: 992px;
$lg: 1920px;
$xl: 1920px;

$fix1919: 1919px;
