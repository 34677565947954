@import '../../../styles/styles.scss'
.jobdetail-container
    .tab-content
        .tab-pane
            position: relative
            height: 100%
            &.active
                display: flex
                flex-direction: column
            .row
                &:last-child
                    width: 100%
                    position: absolute
                    bottom: 0
                    left: 0
                    padding: 0
    .title
        display: flex
        align-items: center
        font-size: 14px
        font-weight: $font-semibold
        width: 100%
        border-bottom: 1px solid $gray-light
        margin-bottom: 34px
        .arrow-icon
            font-size: 13px
            font-weight: $font-bold
        @media (min-width: $xl)
            margin-bottom: 74px
            font-size: 20px
            .arrow-icon
                font-size: 23px
    .form-body
        hr
            color: $gray-light
            margin-bottom: 24px
        .input
            margin-bottom: 26px
            width: 100%
        .dropdown
            margin-bottom: 34px
        .input
            .input-holder
                max-width: 100%
        label
            font-size: 11px
            @media (min-width: $lg)
                font-size: 16px

        
        .dropdown-container
            .form-dropdown
                &.selected
                    button
                        label
                            color: #000
                button
                    label
                        font-weight: 500


        .leader-container
            display: flex
            align-items: center
            .newIndicator
                position: relative
                margin-left: 2px
        ul.dropdown-menu.show
            max-width: 100%
            width: 100%
    .form-footer
        .buttons-container
            display: flex
            margin: 0 auto
            width: fit-content
            margin-top: 20px
            .button
                margin: 0
                margin-left: 18px
                padding: 0 25px
                min-width: auto
                @media (min-width: $lg)
                    padding: 7px 46px
                span
                    display: flex
                    align-items: center
                    @media (min-width: $lg)
                        font-size: 14px
                    .material-symbols-rounded
                        font-size: 20px
                        margin-right: 8px
                    .material-symbols-filled
                        font-size: 20px
                        margin-right: 8px
