@import '../../../styles/styles.scss'

.position-card-container
    // margin-bottom: 50px
    .cursor-pointer
        cursor: pointer
    .PositionCard
        z-index: 1
        border-radius: 8px
        box-shadow: 0px 2px 10px rgba($dark, .10)
        position: relative
        .header-container
            background-color: $active
            border-radius: 8px 8px 0 0
            padding: 9.5px 24px
            display: flex
            align-items: center
            width: 100%
            margin-bottom: 0
            @media (min-width: $xl)
                padding: 15px 24px
            p
                font-size: 14px
                color: $dark
                font-weight: bold
                margin-bottom: 0px
                @media (min-width: $lg)
                    font-size: $font-xl
            a
                // transition: all .3s ease-in-out
                opacity: 0
                .icon-left
                    font-size: 18px
                    margin: 0
                    margin-left: auto
                @media (min-width: $lg)
                    font-size: 14px

        .section
            display: flex
            padding: 8px 25px
            .behave
                font-size: 11px
                color: #7a7a7a
                // padding: 8px 15px
                @media (min-width: $lg)
                    font-size: $font-lg
                .profile-description-container
                    margin-bottom: 15px
                .tags-container
                    margin: 0
                    padding: 0
                    li
                        margin: 0px 10px 10px 0
                        padding: 2px 12px
                        font-size: 9px
                        @media (min-width: $lg)
                            padding: 4px 12px
                            margin: 0px 15px 15px 0
                            font-size: 12px
                        background-color: $gray-lighter
                        border-radius: 71px
                        color: $dark
                        font-weight: 500
                        line-height: normal
                        position: relative
                        &:hover
                            background-color: mix($dark, $gray-lighter, 10%)
                        &.primary-tag
                            background-color: $lightblue
                            &:hover
                                background-color: mix($dark, $lightblue, 10%)
                        &:nth-child(1n+6)
                            display: none
                        &:last-child
                            display: inline-block
                        span
                            display: none
                            width: 220px
                            position: absolute
                            bottom: 25px
                            @media (min-width: $lg)
                                bottom: 35px
                            left: 0
                            padding: 10px
                            background-color: #fff 
                            box-shadow: 2px 2px 5px rgba($dark, .10), -2px -2px 5px rgba($dark, .10)
                            border-radius: 8px
                            color: $dark
                    &.collapse-open
                        li
                            &:nth-child(1n+6)
                                display: inline-block
                            &:last-child
                                display: none
                            &:hover span
                                display: block
            .traits
                .traits-list
                    margin: 0
                    min-width: 250px
                    padding: 15px 30px
                    @media (min-width: $lg)
                        min-width: 350px
                    .item-list
                        display: flex
                        flex-direction: column
                        font-size: 11px
                        @media (min-width: $lg)
                            font-size: 16px
                        .trait-name
                            font-weight: 600
                        .compatibility-progress
                            .progress
                                border-radius: 8px
                                margin-bottom: 13px
                                max-height: 12px
                                @media (min-width: $lg)
                                    max-height: 16px
                                .low-percent
                                    color: #608dec
                                    font-weight: bold
                                    padding-left: 5px
                                    padding-top: 0px
                                    font-size: 8px
                                    @media (min-width: $xl)
                                        // padding-top: 1px
                                        font-size: 12px
                                .progress-bar
                                    background-color: #608dec
                                    margin-right: 3px
                                    .number
                                        padding-left: 1rem
                                        font-weight: bold
                                        text-align: right
                                        color: white
                                        font-size: 10px
                                        transform: translate(-7px, -2px)
                                    .percentage-indicator
                                        display: flex
                                        max-height: 12px
                                        align-items: center
                                        justify-content: flex-end
                                        padding: 0 2px
                                        font-size: 8px
                                        padding: 0 11px 0 0
                                        font-weight: bold
                                        @media (min-width: $lg)
                                            font-size: 12px
                                            max-height: 17px
                                            font-weight: bold
                                            background-color: #608dec
                                            border-radius: 8px

    .open
        .header-container
            a
                opacity: 1
                z-index: 100000
        .expand
            transition: all .3s ease-in-out
            transform: rotate(180deg)
    .closed
        &:hover
            .header-container
                background-color: $active
                a
                    // transition: all .3s ease-in-out
                    opacity: 1
        .header-container
            background-color: $lightblue
