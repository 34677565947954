@import '../../../styles/styles.scss'

.square-button 
    display: flex
    flex-direction: row
    align-items: center
    padding: 8px 15px
    @media screen and (min-width: $xl)
      padding: 11.5px 15px
    &.disabled-button 
      div
        color: $gray-light
      .inner 
        background: $gray-light
      button 
        border-color: $gray-light
      label 
        color: $gray-light
    input[type="checkbox"]
        appearance: none
        cursor: pointer
        border-radius: 4px
        border: solid 2px #888888
        display: grid
        place-content: center
        width: 17px
        height: 17px
        transform: translateY(-0.075em)
        // margin-bottom: 2px
        margin: 0
        @media (min-width: $lg)
          width: 24px
          height: 24px
    input[type="checkbox"]::before 
        content: ""
        width: 9px
        height: 9px
        @media (min-width: $lg)
          width: 12px
          height: 12px
        transform: scale(0)
        transition: 120ms transform ease-in-out
        box-shadow: inset 1em 1em $primary
    input[type="checkbox"]:focus
        outline: none
    input[type="checkbox"]:checked
        border-color: $primary
    input[type="checkbox"]:disabled
        border-color: #888888
    input[type="checkbox"]:disabled::before
      box-shadow: inset 1em 1em #888888
    input[type="checkbox"]:checked::before 
        transform: scale(1)
    
    label 
      margin-left: 16px
      font-weight: $font-regular
      font-size: 11px
      @media (min-width: $xl)
        font-size: $font-lg
      color: $text-regular
      display: block
      text-align: left
      color: $dark
      cursor: pointer
      margin-bottom: 0
      flex: 1
      &.is-download-link 
        color: $primary
        text-decoration: underline
      &.required:after 
        content: ' *'
        color: $warning
    .input-label 
      color: $text-regular
      font-size: 15px
      font-weight: 500
      &.required:after 
        content: ' *'
        color: $warning
    .woman-color 
      background: rgb(255, 192, 203) !important
    .gender-span 
      width: 65% !important
      height: 65% !important


#description-tooltip
  transform: translate(0%, -100%)
  &.fade.in
    display: visible
    opacity: 1
    .tooltip-arrow
      display: none
    .tooltip-inner
      color: $dark
      background-color: white
      font-size: $font-xs
      font-weight: $font-semibold
      font-family: $font-body
      text-align: start
      padding: 12px
      border-radius: 12px
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
      border-bottom-left-radius: 0px
      opacity: 1
      @media screen and (min-width: $xl)
        font-size: $font-sm
  &.fade:not(.in)
    display: none

