@import '../../../styles/styles.scss'

.profile-container
  height: 100%
  @media screen and (min-width: $md)
    // height: 60vh
  @media screen and (min-width: $lg)
    // height: 70vh
  
  .tag-container
    height: 100%
  .tags    
    background-color: $gray-lighter
    border-radius: 8px
    padding: 10px 16px 6px 16px
    position: relative 
    height: 100%
    display: flex
    flex-direction: column
    .tags-header
      margin-bottom: 8px
      @media (min-width: $lg)
        margin-bottom: 12px
    .search-input-container
      width: 100%
      .search-icon
        //font-weight: 500
        ////top: 50%
        ////margin-top: -2%
        //left: 16px
        //font-size: 20px
        //@media screen and (min-width: $lg)
        //  font-size: 30px
        //  left: 19px
      input
        width: 100%
        //  font-size: 11px
      //  margin: 0
      //  padding-left: 40px
      //  @media screen and (min-width: $lg)
      //    font-size: 16px
      //    padding-left: 47px
        &::placeholder
          color: #aaaaaa
      //    font-weight: 500
      //    font-size: 11px
      //    @media screen and (min-width: $lg)
      //      font-size: 16px
        &:focus
          border: 1px solid $lightblue-intense
          outline: none
    .tags-paginate
      font-size: 9px
      color: #888
      font-weight: 400
      @media (min-width: $lg)
        font-size: 12px
    .tag-list
      flex: 1
      width: 100%
      overflow-y: auto
      padding-bottom: 20px 
      .square-button
        border-bottom: 1px solid $gray-light
        padding-left: 0
        div
          padding-left: 15px
          cursor: default
          font-size: 11px
          @media screen and (min-width: $xl)
            font-size: $font-lg
        label
          font-size: $font-md
          font-weight: $font-regular
          line-height: 19px
          @media screen and (min-width: $lg)
            font-size: $font-lg  
  .profile-header
    font-size: $font-md
    font-weight: 500
    display: flex
    align-items: center
    justify-content: space-between
    @media screen and (min-width: $lg)
      font-size: $font-xl
    .tooltip-container
      color: $primary
      margin-left: auto
      font-size: 20px 
      @media screen and (min-width: $lg)
        font-size: 30px
  .results
    height: 100%
    display: flex
    flex-direction: column
    overflow-y: hidden
    .total-profiles
      font-size: 10px
      font-weight: 400
      color: #888888
      padding-top: 5px
      padding-left: 10px
      @media (min-width: $lg)
        font-size: 14px
    .header 
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 20px
      div
        display: flex
        align-items: center
        font-size: 11px
        color: #888888
        font-weight: 500
        @media screen and (min-width: $lg)
          font-size: $font-lg
        .privacy
          font-size: 42px
          @media screen and (min-width: $lg)
            font-size: 52px
        .selected-tags
          display: flex
          flex-wrap: wrap
          button
            color: black
            font-weight: 500
            font-size: 10px
            border-radius: 100px
            background-color: $gray-lighter 
            padding: 2px 5px
            margin-right: 5px
            margin-bottom: 5px
            @media screen and (min-width: $lg)
              font-size: $font-lg
              padding: 8px 16px
            span
              margin-left: 4px 
              font-size: 9px
              @media screen and (min-width: $lg)
                font-size: 14px
                margin-left: 8px
      .search-input-container
        margin-left: auto
        margin-left: 15px
        margin-right: 10px
        input
          border: 1px solid $gray-lighter
          &:focus
            border: 1px solid $lightblue-intense
            outline: none
          &::placeholder
            font-weight: 500
            color: #888888
        .search-icon
          //font-weight: 500
          //top: 50%
          //left: 18px
          //margin-top: -7%
          //font-size: 16px
          //@media screen and (min-width: $lg)
          //  font-size: 24px
          //  margin-top: -5%
          
    .profile-result-container
      overflow-y: auto
      padding: 0 10px
      flex: 1
      .profile-result
        display: flex
        flex-direction: column
        margin-bottom: 25px
        .PositionCard
          // margin-bottom: 11px
          // transition: all 3s ease-in-out
          display: flex
          flex-direction: column
          // padding-bottom: 5px
          .expand
            margin-left: auto
            margin-right: 16px
            align-self: flex-end
            padding-bottom: 5px
          .header-container
            a.button
              text-transform: uppercase
              height: auto
              width: auto
              padding: 4.5px 15px
              border-radius: 5px
              span
                margin-right: 8px
            a
              width: 142px
              height: 24px 
              min-width: 142px
              display: flex
              justify-content: center
              margin-left: auto
              margin-right: 0
              align-items: center
              @media screen and (min-width: $lg)
                width: 200px
                min-width: 200px
              span
                margin-left: 0
              &:not(span)
                font-size: $font-xs
                @media screen and (min-width: $xl)
                  font-size: $font-md
        