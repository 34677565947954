@import '../../../styles/styles.scss'

.large-text-container
    position: relative
    display: flex
    width: 100%
    .div-inactive
        position: absolute
        width: .1px
        height: .1px
        overflow: hidden
        opacity: 0
    .div-active
        height: fit-content
        min-width: 231px
        text-align: center
        gap: 8px
        font-size: 11px
        font-weight: 500
        color: #fff
        background-color: rgba(0,0,0, .8)
        position: absolute
        left: 0px
        bottom: 25px
        border-radius: 8px
        padding: 5px 15px
        z-index: 1000
        margin: 0
        @media (min-width: $lg)
            border-radius: 12px







