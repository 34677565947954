@import '../../../styles/styles.scss'


.new-campaign-wrapper
    .form-container
        .tab-content
            .tab-pane
                height: 100%
                overflow: auto
                .new-campaign-container
                    height: 100%
                    form
                        // height: 85%
                        display: flex
                        flex-direction: column

        .new-campaign-container
            .card-header
                .card-header-left
                    display: flex
                    border-bottom: 1px #f6f6f6 solid
                    padding-bottom: 1rem
                    margin-bottom: 5rem
                    .left-side
                        display: flex
                        justify-content: flex-start
                        width: 100%
                        margin-left: -1rem
                        button
                            box-shadow: none
                            min-width: 0
                            width: fit-content
                            padding: 0
                            margin: 0
                            .material-symbols-rounded
                                font-size: 40px
                                margin: 0
                                padding: 0
                                transform: rotate(90deg)
                        p
                            margin: 0
                            display: flex
                            align-items: center
                            font-size: 20px
                            font-weight: 500
                    .right-side
                        display: flex
                        justify-content: flex-end
                        width: 100%
                        p
                            margin: 0 15px 0 0
                            display: flex
                            align-items: center
                            color: #cb3333
            form
                .form-body
                    display: flex
                    .form-inputs
                        width: 50%
                        .optional-container
                            display: flex
                            .optional-original
                                padding-right: 63px
                                &.fields-right
                                    border-right: 1px solid #aaaaaa
                            .optional-account-field
                                margin-left: 56px
                                h3
                                    font-size: 10px
                                    @media (min-width: $xl) 
                                        font-size: 14px
                                    font-weight: 600
                                    margin: 0
                                    padding-bottom: 18px
                        .input
                            .input-holder
                                max-width: 796px
                                @media (max-width: $fix1919)
                                    max-width: 566px
                                input
                                    font-weight: normal
                        .associated-codes-section
                            max-width: 566px
                            @media (min-width: $fix1919)
                                max-width: 796px
                            .text-warning
                                color: #cb3333
                                font-size: 11px
                                margin: -7px 0 15px 0 !important
                                @media (min-width: $fix1919)
                                    font-size: 14px
                                    margin: -14px 0 25px 0 !important
                            .Tooltip
                                margin-left: 11px
                            position: relative
                            .tooltip-container
                                position: absolute
                                left: 16.5rem
                                top: -0.2rem
                            .input
                                .input-label-container
                                    label
                                        width: fit-content
                                .input-holder
                                    input
                                        font-weight: normal
                            &.max-error
                                .input
                                    .input-holder
                                        input
                                            border-color: #cb3333

                        .middle-form-section
                            border-top: 1px #d6d6d6 solid
                            border-bottom: 1px #d6d6d6 solid
                            padding: 3rem 0 3rem 0
                            display: flex
                            .middle-left
                                display: flex
                                justify-content: flex-start
                                align-items: center
                                @media (max-width: $fix1919)
                                    .Tooltip
                                        button
                                            .material-symbols-rounded
                                                font-size: 16px
                                .position-select
                                    position: relative
                                    margin: 0
                                    margin-right: 16px
                                    .dropdown-container
                                        margin: 0
                                        .form-dropdown
                                            border-radius: 12px
                                            border-color: #f6f6f6
                                            //padding: 5.5px 13px
                                            width: 184px
                                            display: flex
                                            align-items: center
                                            margin: 0 
                                            max-width: 130px
                                            @media (min-width: $lg)
                                                //padding: 6.5px 17px
                                                max-width: 184px   
                                            button
                                                font-weight: 500
                                                font-size: 16px
                                                @media (max-width: $fix1919)
                                                    font-size: 11px
                                                label
                                                    font-weight: 500
                                                    margin-bottom: 0
                                            &.selected
                                                border-color: #8ee7ff
                                                button
                                                    font-weight: 500
                                                    font-size: 16px
                                                    @media (max-width: $fix1919)
                                                        font-size: 11px
                                                    label
                                                        font-weight: 500
                                                        color: black
                                            ul
                                                width: 100%
                                                @media (max-width: $fix1919)
                                                    li
                                                        font-size: 11px
                                                .clean-filter
                                                    color: red
                                                    font-size: 14px
                                                    @media (max-width: $fix1919)
                                                        font-size: 9px
                                                
                                    .material-symbols-rounded
                                        margin-left: auto
                                        font-weight: 600
                                        font-size: 30px
                                .tooltip-container
                                    margin: 0.3rem 0 0 0
                                    
                                //div
                                //    margin-right: auto
                                .newIndicator
                                    position: relative
                                    margin-right: 0
                                    margin-left: 16px
                                   

                            .middle-right
                                display: flex
                                justify-content: flex-start
                                align-items: center
                                margin-left: 40px 
                                gap: 16px
                                @media (min-width: $lg)
                                    gap: 11px
                                @media (max-width: $fix1919)
                                    .Tooltip
                                        button
                                            .material-symbols-rounded
                                                font-size: 16px
                                .square-button
                                    float: right
                                    width: fit-content
                                    display: flex
                                    align-items: center
                                    justify-content: flex-start
                                    margin: 0
                                    @media (max-width: $fix1919)
                                        padding: 0
                                    input
                                        margin: 0 1rem 0 0
                                    label
                                        margin: 0
                                        font-weight: normal
                                .disabled-button
                                    input
                                        border-color: #888888
                                        cursor: default
                                    input#autoDelivery:checked::before
                                        content: ""
                                        width: 9px
                                        height: 9px
                                        transform: scale(1)
                                        transition: 120ms transform ease-in-out
                                        box-shadow: inset 1em 1em #888888
                                        cursor: default
                                        @media (min-width: $lg)
                                            width: 12px
                                            height: 12px
                                    label
                                        cursor: default
                                        color: #888888
                        .bottom-header
                            position: relative
                            .bottom-label
                                display: flex
                                padding: 1rem 0 1rem 0
                                align-items: center
                                @media (max-width: $fix1919)
                                    .Tooltip
                                        button
                                            .material-symbols-rounded
                                                font-size: 16px
                                .label-container
                                    margin: 0
                                    margin-right: 18px
                                    .custom-label
                                        font-size: 16px
                                        font-weight: 600
                                        @media (max-width: $fix1919)
                                            font-size: 11px
                            .bottom-tooltip
                                position: absolute
                                left: 24rem
                                top: 0.9rem
                                
                        .options-check
                            .square-button
                                padding: 0.5rem 0 0.5rem 0
                                input
                                    margin: 0.4rem 1rem 0 0
                                label
                                    margin: 0
                                    font-size: 14px
                                    font-weight: normal
                        .disabled
                            div
                                input
                                    border-color: #888888
                                    cursor: default
                                input[type="checkbox"]:checked::before
                                    cursor: default
                                    content: ""
                                    transform: scale(1)
                                    transition: 120ms transform ease-in-out
                                    box-shadow: inset 1em 1em #888888
                                label
                                    cursor: default
                .form-footer
                    display: flex
                    align-items: flex-end
                    justify-content: center
                    margin-top: auto
                    .buttons
                        margin: 1rem 0 0 0
                        .warning
                            display: none                    
    .campaign-credits-widget-container
        margin-top: 7.9rem
    .campaign-credits
        padding: 0
        @media (min-width: $lg)
            padding-inline: 10px
        margin-top: 8rem    
        .credits-body
            .credits-card
                .total-credits
                    width: 100%
                    display: flex
                    align-items: center
                    font-weight: 500
                    margin-inline: 0
                    border-radius: 12px
                    overflow: hidden
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                    background: white
                    .database
                        padding: 14px 14px
                        width: 115px
                        background-color: $gray-light
                        color: #404040
                        font-size: 15px
                        display: flex
                        align-items: center
                        justify-content: center
                        .material-symbols-rounded
                            font-size: 28px
                    .totalcredits-text
                        padding: 0 14px
                        .text
                            color: $gray-light
                        .number
                            margin-inline: 4px
                .col
                    margin: 0 0 19.6px 0
                    .credit-widget-container
                        background-color: white

@media (max-width: $fix1919)
    .new-campaign-wrapper
        .form-container
            div
                .nav-tabs
                    li
                        a
                            font-size: 14px
            .new-campaign-container
                .card-header
                    .card-header-left
                        .left-side
                            button
                                .material-symbols-rounded
                                    font-size: 30px
                            p
                                font-size: 14px
                    .back
                        .right-side
                            p
                                font-size: 9px

                form
                    .form-body
                        .form-inputs
                            .associated-codes-section
                                .Tooltip
                                    margin-left: 10px
                                .tooltip-container
                                    top: -0.5rem
                                    left: 11.5rem
                            .middle-form-section
                                .middle-left
                                    .position-select
                                        .dropdown-container
                                            .dropdown
                                                button
                                                    border-radius: 8px
                                                    label
                                                        font-size: 11px
                                                        font-weight: 500
                                            
                                        .material-symbols-rounded
                                            top: 1rem
                                            font-weight: 600
                                            font-size: 20px
                                        
                                        
                                    // .newIndicator
                                    //     max-height: 20px
                                    //     max-width: 57px
                                    //     padding: 1rem
                                    //     padding-right: 5.5rem
                                    //     margin-left: 18px
                                        span
                                            font-size: 10px
                                        h6
                                            font-size: 9px
                                            padding-top: 0.1rem
                                .middle-right
                                    .square-button
                                        label
                                            font-size: 10px
                            .options-check
                                .square-button
                                    label
                                        font-size: 10px
        .campaign-credits-widget-container
            margin-top: 6.9rem
        .campaign-credits
            margin-top: 55.9px
            .credits-body
                .credits-card
                    .total-credits
                        width: 100%
                        display: flex
                        align-items: center
                        font-size: 11px
                        font-weight: 500
                        margin-inline: 0
                        border-radius: 8px
                        overflow: hidden
                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                        .database
                            padding: 8px 7px
                            width: 82px
                            background-color: $gray-light
                            color: #404040
                            font-size: 15px
                            font-size: 11px
                            display: flex
                            align-items: center
                            justify-content: center
                            .material-symbols-rounded
                                font-size: 15px
                        .totalcredits-text
                            padding: 0 7px
                            font-size: 11px
                            .text
                                color: $gray-light
                            .number
                                margin-inline: 4px
                
