@import '../../../styles/styles.scss'
.export-excel-modal
    width: 100vw
    height: 100vh
    .modal-dialog
        width: fit-content
        height: fit-content
        margin: 0
        .modal-content
            width: 278px
            box-shadow: none
            border: none
            border-left: 5px solid $primary
            .modal-header
                border-bottom: none
                padding: 17px 17px 0px
                .header
                    display: flex
                    color: $primary
                    font-weight: $font-bold
                    .material-symbols-rounded
                        padding-right: 5px
                    .title
                        font-size: $font-md
                        padding-left: 5px
            .modal-body
                display: flex
                align-items: center
                margin-left: 5px
                .square-button
                    margin-right: 15px
                    input
                        width: 15px
                        height: 15px
                        border: solid 1px #888888
                        margin-right: 15px
                        @media screen and (min-width: $lg)
                            width: 20px
                            height: 20px
                            border: solid 2px #888888
                    input::before
                        width: 9px
                        height: 9px
                        @media screen and (min-width: $lg)
                            width: 12px
                            height: 12px
                    label
                        font-weight: 300
                        margin-left: 0
                        font-size: 12px
                        @media screen and (min-width: $lg)
                            font-size: 14px
                &.options
                    display: flex
                    flex-direction: row
                    align-items: center
            .modal-footer
                padding: 0px 15px 10px
                display: flex
                justify-content: flex-end
                border-top: none
                button.button
                    min-width: auto
                    margin: 0
