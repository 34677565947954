@import '../../../styles/styles.scss'

.campaign-campaign
    .row
        .table-side
            .tab-content
                // height: 61.7rem
            div
                div
                    .header-left
                        width: 100%
                        display: flex
                        .header-left-side
                            display: flex
                            width: 100%
                            .search-input-container
                                margin-right: 16px
                                input
                                    margin: 0
                                    width: 304px
                                    border: 1px solid #f6f6f6
                                    font-size: 16px
                                    font-weight: 500
                                    border-radius: 12px
                                    max-height: 45px
                                    @media (min-width: $lg)
                                        padding-top: 10px
                                        padding-bottom: 10px
                                    &::placeholder
                                        color: #aaaaaa
                                        font-size: 16px
                                        font-weight: 500
                                    &:focus
                                        border: 1px solid $lightblue-intense
                                        outline: none
                                    &.active
                                        border: 1px solid $active

                            .date-range-selector
                                .filter-date
                                    .date-button
                                        .dropdown-menu-test
                                            .test
                                                label
                                                    font-weight: 500

                        .filter-clear-button
                            .button
                                box-shadow: none
                                color: #cb3333
                                min-width: auto
                                padding: 0
                                font-weight: $font-regular
                                span
                                    width: max-content
                                    text-transform: inherit
                                &.disabled
                                    display: none
                    .tab-pane
                        div
                            &.table
                                .ReactTable
                                    // max-height: 516px
                                    // min-height: 516px
                                    // height: 516px
                                    // @media (min-width: $lg)
                                    //     max-height: 773px
                                    //     min-height: 773px
                                    //     height: 773px 
                                    .rt-table
                                        // max-height: 100%
                                        overflow: hidden
                                        .rt-thead
                                            margin-bottom: 15px
                                            @media screen and ( min-width: $lg )
                                                margin-bottom: 27px
                                            .rt-tr
                                                .rt-th
                                                    div
                                                        display: flex
                                                        justify-content: flex-start
                                                        width: 100%
                                                        span
                                                            display: flex
                                                            justify-content: center
                                                            align-items: center

                                                        .centered-values
                                                            display: flex
                                                            justify-content: center
                                                            width: 100%
                                                            span
                                                                display: flex
                                                                justify-content: center
                                                                align-items: center
                                                    &:nth-child(1)
                                                        box-shadow: none
                                                        &.-sort-asc, &.-sort-desc
                                                            div
                                                                padding: 0.4rem
                                                                background-color: #8EE7FF
                                                                width: fit-content
                                                                border-radius: 10px

                                                        &.-sort-desc
                                                            div
                                                                span
                                                                    .material-symbols-rounded
                                                                        transform: rotate(180deg)

                                                    &
                                                        box-shadow: none
                                                        display: flex
                                                        &.-sort-asc, &.-sort-desc
                                                            div
                                                                padding: 0.4rem
                                                                background-color: #8EE7FF
                                                                width: fit-content
                                                                border-radius: 10px
                                                        &.-sort-desc
                                                            div
                                                                span
                                                                    .material-symbols-rounded
                                                                        transform: rotate(180deg)
                                                .rt-th
                                                    &.codes_associated, &.codes_available, &.codes_assigned, &.codes_consumed
                                                        &.-sort-asc, &.-sort-desc
                                                            div
                                                                margin: auto
                                        .rt-tbody
                                            .rt-tr-group
                                                flex: none
                                                padding-top: 4.7px
                                                padding-bottom: 9px
                                                @media (min-width: $lg)
                                                    padding: 6px 5px 6px 0px
                                                .rt-tr
                                                    @media (min-width: $lg)
                                                        padding-top: 9px
                                                        padding-bottom: 9px
                                                    &:hover
                                                        background-color: $gray-lighter
                                                        border-radius: 8px
                                                    .rt-td
                                                        .position-field
                                                            overflow: hidden
                                                            text-overflow: ellipsis
                                                        padding: 0
                                                        display: flex
                                                        justify-content: flex-start
                                                        align-items: center
                                                        overflow: visible
                                                        .name-field
                                                            text-align: left
                                                            overflow: hidden
                                                            padding-right: 5px
                                                            position: relative
                                                            span
                                                                display: block
                                                            .number
                                                                text-overflow: ellipsis
                                                                overflow: hidden
                                                            .campaign-code
                                                                width: 100%
                                                                display: flex
                                                                color: #888888
                                                                font-size: 11px
                                                                margin-top: 4px
                                                                text-transform: uppercase
                                                        .centered-values
                                                            width: 100%
                                                            display: flex
                                                            justify-content: center
                                                        .associated
                                                            color: #1e7bb0
                                                        .available
                                                            color: #b366ef
                                                        .assigned
                                                            color: #ffbf60
                                                        .consumed
                                                            color: #cb3333
                                                        .share-row
                                                            width: 100%
                                                            .dropdown-container
                                                                display: flex
                                                                justify-content: center
                                                                .dropdown
                                                                    position: relative
                                                                    button
                                                                        color: black
                                                                        border: none
                                                                        width: 3rem
                                                                        min-width: 1rem
                                                                        padding: 0
                                                                        display: flex
                                                                        justify-content: center
                                                                        background-color: rgba(0,0 ,0 ,0 )
                                                                        label
                                                                            margin: 0
                                                                            display: flex
                                                                            align-items: center

                                                                        span
                                                                            &:nth-child(2):not(.share-button)
                                                                                display: none

                                                                    .dropdown-menu
                                                                        border-radius: 12px
                                                                        border-top-right-radius: 0
                                                                        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                                                                        left: -50px !important
                                                                        li
                                                                            padding: 0
                                                                            button
                                                                                font-weight: 500
                                                                                background-color: transparent
                                                                                min-width: 1rem
                                                                                padding: 0
                                                                                color: black
                                                                                box-shadow: none
                                                                                margin: 0
                                                                                width: 100%
                                                                                min-height: 100%
                                                                                display: flex
                                                                                align-items: center
                                                                                padding: 12px 35px
                                                                                @media (min-width: $lg)
                                                                                    padding: 18.5px 42px
                                                                                &.disabled
                                                                                    opacity: 1
                                                                                    span
                                                                                        color: $gray-light
                                                                                        opacity: 1
                                                                                .material-symbols-rounded
                                                                                    margin: 0
                                                                                    color: black
                                                                                    display: flex
                                                                                    justify-content: center
                                                                                    align-items: center
                                                                                    padding-right: 0.3rem
                                                                                span
                                                                                    text-transform: none
                                                                                    &:last-child
                                                                                        display: flex
                                                                                        justify-content: flex-start
                                                                                        width: 100%
                                                                                        padding: 0.5rem
                                                                                        padding-top: 0.5rem
                                                                            &:last-child
                                                                                span
                                                                                    color: #cb3333
                                                        .creator-name
                                                            overflow: hidden
                                                            text-overflow: ellipsis
                                                        &:last-child
                                                            overflow: visible
        .credits-side
            padding: 0
            .card-header
                margin: 17px 0 19.5px 0
                button
                    line-height: normal
                    width: 240px
                    //height: 45px
                    display: flex
                    justify-content: center
                    align-items: center
                    font-size: 14px
                    @media (min-width: $lg)
                        padding: 9px 0
                    span
                        line-height: normal
                    .material-symbols-rounded
                        font-size: 14px
                        @media (min-width: $lg)
                            font-size: 20px

            .campaign-credits
                padding-right: 0px
                padding-left: 0px
                @media (min-width: $xl)
                    padding-right: 12px
                    padding-left: 12px
                .credits-body
                    .credits-card
                        .total-credits
                            width: 100%
                            display: flex
                            align-items: center
                            font-weight: 500
                            margin-inline: 0
                            border-radius: 12px
                            overflow: hidden
                            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                            background: white
                            .database
                                padding: 14px 14px
                                //width: 115px
                                background-color: $gray-light
                                color: #404040
                                font-size: 15px
                                display: flex
                                align-items: center
                                justify-content: center
                                .material-symbols-rounded
                                    font-size: 28px
                            .totalcredits-text
                                padding: 0 14px
                                @media (min-width: $xl)
                                    font-size: 15px
                                    text-align: center
                                    width: 100%
                                .text
                                    color: $gray-light
                                .number
                                    margin-inline: 4px
                        div
                            .credit-widget-container
                                margin: 1.5rem 0 1.5rem 0
                                &:last-child
                                    //margin-bottom: 0
                                .credit-widget-body-container
                                    .value-container
                                        line-height: 1.1
                                    .description-container
                                        line-height: normal
                                        &.column-view
                                            @media (min-width: $xl)
                                                padding: 0px 9px 18px
                            .column-view
                                max-width: 100%
                                .credit-widget-body-container
                                    background-color: white
                                    width: 100%
                                    // span
                                    //     font-size: 14px
                            .row-view
                                width: 100%
                                margin: 0
                                padding: 0
                                .title-wrapper
                                    display: flex
                                    justify-content: center
                                    background-color: rgba(0,0,0,0.1)
                                    min-width: 40%
                                    width: 50%
                                    height: 5rem

                                    .title-container
                                        padding: 0
                                        width: 70%
                                        span
                                            padding: 0
                                            margin: 0
                                            font-size: 35px
                                        h3
                                            font-size: 14px
                                .credit-widget-body-container
                                    display: flex
                                    justify-content: space-evenly
                                    .label-container
                                        font-size: 14px
                                        width: fit-content
                                    .value-container
                                        font-size: 14px
                                        width: fit-content
                                        line-height: 1.1
                                    .description-container
                                        font-size: 14px
                                        width: fit-content

///media queries///

@media (max-width: $fix1919)
    .campaign-campaign
        .row
            .table-side
                div
                    .nav-tabs
                        li
                            a
                                font-size: 14px
                    div
                        .header-left
                            width: 100%
                            display: flex
                            .header-left-side
                                .search-input-container
                                    margin-right: 16px
                                    input
                                        margin: 0
                                        width: 216px
                                        border: 1px solid #f6f6f6
                                        font-size: 11px
                                        font-weight: 500
                                        border-radius: 8px
                                        max-height: 32px
                                        &::placeholder
                                            font-size: 11px
                                            color: #888888
                                            font-weight: 500

                                .date-range-selector
                                    .filter-date
                                        .date-button
                                            .dropdown-menu-test
                                                .test
                                                    max-height: 3.2rem
                                                    font-size: 11px
                                                    border-radius: 8px
                                                    max-width: 188px
                                                    label
                                                        font-weight: 500
                                                    .material-symbols-rounded
                                                        font-size: 20px
                                        .tooltip-container
                                            display: none
                        .tab-pane
                            div
                                .table
                                    .ReactTable

                                        .rt-table
                                            .rt-thead
                                                .rt-tr
                                                    .rt-th
                                                        div
                                                            font-size: 11px
                                            .rt-tbody
                                                overflow-y: auto
                                                .rt-tr-group
                                                    .rt-tr
                                                        .rt-td
                                                            font-size: 11px
                                                            .name-field
                                                                .campaign-code
                                                                    font-size: 8px
                                                                    text-transform: uppercase
            .credits-side
                .card-header
                    margin: 6px 0 19.6px 0
                    button
                        width: 170px
                        padding: 5.5px 0
                        //max-height: 31px
                        //height: 31px
                        span
                            display: flex
                            justify-content: center
                            align-content: center
                            font-size: 10px
                            span
                                display: flex
                                align-items: center
                                .material-symbols-rounded
                                    font-size: 15px
                .campaign-credits
                    .credits-body
                        .credits-card
                            .total-credits
                                width: 100%
                                display: flex
                                align-items: center
                                font-size: 11px
                                font-weight: 500
                                margin-inline: 0
                                border-radius: 8px
                                overflow: hidden
                                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                                line-height: normal
                                .database
                                    padding: 6.5px 7px
                                    width: 82px
                                    background-color: $gray-light
                                    color: #404040
                                    font-size: 15px
                                    font-size: 11px
                                    display: flex
                                    align-items: center
                                    justify-content: center
                                    .material-symbols-rounded
                                        font-size: 15px
                                .totalcredits-text
                                    padding: 0px
                                    font-size: 11px
                                    padding-left: 4px
                                    width: 75%
                                    text-align: center
                                    .text
                                        color: $gray-light
                                    .number
                                        margin-inline: 4px
                            div
                                .row-view
                                    .title-wrapper
                                        .title-container
                                            span
                                                font-size: 15px
                                            h3
                                                font-size: 11px
                                    .credit-widget-body-container
                                        width: 100%
                                        display: flex
                                        justify-content: space-evenly
                                        .label-container
                                            font-size: 11px
                                            margin: 0
                                        .value-container
                                            text-align: flex-start
                                            font-size: 11px
                                            line-height: 1.1
                                        .description-container
                                            font-size: 11px
                                .column-view
                                    .title-wrapper
                                        .title-container
                                            .title
                                                font-size: 11px
