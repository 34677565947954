@import '../../../styles/styles.scss'

.search-input-container
    position: relative
    .search-icon
        position: absolute
        left: 18px
        color: #7a7a7a
        font-weight: 500
        font-size: 20px
        line-height: 32px
        @media screen and (min-width: $lg)
            left: 28px
            line-height: 46px
            //top: 22px
            font-size: 30px
    input
        margin: 0
        padding: 6.5px 9px 6.5px 47px
        border-radius: 8px  
        border: none
        line-height: normal
        border: 1px solid transparent
        font-size: 11px
        @media screen and (min-width: $lg)
            border-radius: 12px  
            padding: 9px 28px 9px 64px
            width: 92%
            font-size: $font-lg
    input::placeholder
        color: #7a7a7a
        font-weight: 500