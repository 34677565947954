.ie-body-container
    margin-bottom: 150px
    header
        img
            width: 400px
    .personal-data
        display: flex
        flex-direction: column
    .traits
        div
            .trait-paragraph
                .trait-key
                    font-weight: 600
                    // background-color: green
                    height: fit-content
                    width: fit-content
h4
    font-weight: 600
