@import '../../../../src/styles/styles.scss'

.edit-report
    height: 80vh
    .header-container
        display: flex
        align-items: center
        .material-symbols-rounded
            color: $primary
            cursor: pointer
        .container-name
            font-size: 14px
            font-weight: 600
            @media (min-width: $fix1919)
                font-size: 24px
    .edit-screen
        display: flex
        justify-content: space-evenly
        align-content: center
        align-items: stretch
        gap: 15px
        height: 100%
        .upload-reports
            width: 50vw
            margin: 20px 0
        .edit-container
            display: flex
            flex-direction: column
            align-content: center
            align-items: center
            width: 50vw
            margin: 20px 0
            .edit-header
                display: flex
                align-items: center
                gap: 10px
                .title
                    font-size: 20px
                    font-weight: 500
            .dropdown-container
                margin: 10px 0
                min-width: 160px
                // max-width: 180px
                width: 530px
                @media (min-width: $fix1919)
                    width: 560px
                    margin: 15px 0
                    // height: 160px
                .report-detail
                    font-size: 11px
                    @media (min-width: $fix1919)
                        font-size: 15px
                .button-container
                    display: flex
                    gap: 15px
                .form-dropdown
                    .dropdown-menu
                        min-width: 160px
                        max-width: 180px
                        @media (min-width: $fix1919)
                            min-width: 180px
