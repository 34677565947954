@import '../../../styles/styles.scss'

.ie-indicator
  display: flex
  flex-direction: column
  align-items: center
  border: 1px solid #B01E7E
  border-radius: 10px
  margin: 24px 0
  &.false
    border: 1px solid #AAAAAA
    .header-ie-indicator
      color: #AAAAAA
      background-color: #f6f6f6
    .body-ie-indicator
      color: #AAAAAA
  .header-ie-indicator
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 3.5px
    background-color: #FFE6F6
    color: #B01E7E
    gap: 8px
    font-size: 15px
    font-weight: 600
    width: 100%
    height: 100%
    border-radius: 10px 10px 0 0
    .icon
      font-size: 32px
  .body-ie-indicator
    padding: 16px
    font-weight: 700
