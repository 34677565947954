@import '../../../styles/styles.scss'
.recover-screen
  padding: 24px 32px 16px
  margin: auto
  min-width: 420px
  @media (min-width: $fix1919)
    min-width: 586px
    min-height: 348px
  .card
    .wrapper-title
      border-bottom: 1px solid $gray-light
      color: $primary
      display: flex
      cursor: pointer
      flex-direction: row
      align-items: center
      padding-bottom: 13px
      margin-bottom: 48px
      .recover-password-title
        font-size: 13px
        font-weight: $font-bold
        margin: 0
        @media (min-width: $fix1919)
          font-size: 20px
        .material-symbols-rounded
          font-size: 32px
          margin-right: 5px
          font-weight: bold
.recover-form
  display: flex
  flex-direction: column
  align-items: center
  .form-body
    // width: 339px
    margin-bottom: 23px
    display: flex
    flex: 1
    justify-content: center
    flex-direction: column
    .input
      // margin-left: 5rem
      // margin-right: 5rem
      min-width: 216px
      .input-holder
        font-size: 16px
        font-weight: 400
    .error-message
      font-size: 10px
      @media (min-width: $xl)
        font-size: 14px
      &.text-warning
        padding-bottom: 9px
        margin: 0
        color: #cb3333
        font-weight: 500
  .form-footer
    // margin-bottom: 59px
    .version
      color: $gray-light
      font-size: 11px
      margin: 23px 0
      @media screen and (min-width: $lg)
          font-size: 16px
    .disabled
      .material-symbols-rounded
          color: #aaaaaa
