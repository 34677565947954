@import '../../../styles/styles.scss'

.campaign-detail-screen
    .campaign-codes-not-consumed
        .info-list
            .info-container
                overflow: visible
                .badge-container
                    margin-bottom: 5px
                    position: relative
                    .playing-badge
                        cursor: pointer
                        width: fit-content
                        height: 20px 
                        padding: 4px 8px !important
                        border-radius: 20px
                        display: flex
                        align-items: center
                        gap: 4px
                        &.playing
                            color: #ff9060
                            background-color: #ffddc4
                        &.pending
                            color: #ffbf60
                            background-color: #fff2c4
                        &.inconsistent
                            color: #aaaaaa
                            background-color: #f6f6f6
                        .icon
                            display: flex
                            align-items: center
                            font-weight: 200
                            font-size: 12px
                    .tooltip-container
                        position: absolute
                        background-color: #2B2B2B
                        border-radius: 12px
                        opacity: 0.85
                        box-shadow: none
                        max-width: 100px
                        left: -4px !important
                        .tooltip-box
                            display: block
                            border-bottom: 0px 
                            padding: 0 20px 0 20px 
                            span
                                width: 100%
                                &.icon
                                    display: flex
                                    justify-content: center
                                    color: #ffffff
                                    font-size: 28px 
                                    font-weight: 500 
                            p
                                font-family: 'Poppins' 
                                font-style: normal 
                                font-size: 12px 
                                color: #ffffff 
                                line-height: 17px 
                                text-align: center