@import '../../../styles/styles.scss'

.table-pagination-container
  flex-wrap: wrap
  flex-direction: row !important

  .table-pagination-text
    color: #888
    font-size: 11px
    margin-left: auto

  .table-pagination-buttons-container
    display: flex
    flex-flow: row wrap

    .table-paginations-buttons-alt
      display: flex
      flex-flow: row nowrap

      .dropdown
        .selected
          background-color: #f6f6f6
          color: #888
          justify-content: center
          border-radius: 8px
          font-size: 9px
          padding: 4.25px 3.25px
          min-width: auto
          border: 0
          @media (min-width: $lg)
            padding: 7px 8.5px
            font-size: 12px
            border-radius: 12px
          label
            margin-left: 0
            font-weight: 500
            margin-bottom: 0
            .page-size
              font-size: 9px
            .material-symbols-rounded
              font-size: 18px
            @media (min-width: $lg)
              .page-size
                font-size: 12px
              .material-symbols-rounded
                font-size: 24px
      div
        display: flex
        flex-flow: row nowrap
        align-items: center

        p
          margin-bottom: 0

        *
          margin-left: 5px
        li
          margin-left: 0

    .table-paginations-buttons-navigation
      display: flex
      flex-flow: row nowrap
      align-items: center
      color: #888
      button
        align-items: center
        display: flex
        // margin: 0 13px
        .material-symbols-rounded
          font-size: 18px
        @media (min-width: $lg)
          margin: 11px
          font-size: 12px

      .pages-container
        display: flex
        flex-direction: row
        height: 32px
        margin: auto
        .current-page
          cursor: pointer
          font-size: 9px
          @media (min-width: $lg)
            margin: 10px
            font-size: 12px
          .active-page
            font-weight: 600
            color: #888888

@media screen and (max-width: 1100px)
  .table-pagination-container
    .table-pagination-text
      width: 100%
      margin-bottom: 8px
      font-size: 11px

    .table-pagination-buttons-container
      width: 100%
      justify-content: center

@media screen and (max-width: 750px)
  .table-pagination-container
    .table-pagination-buttons-container
      flex-flow: column nowrap
      align-items: center
