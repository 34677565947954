@import '../../../styles/styles.scss'

#TableMenuItem
    .dropdown-container
        .dropdown
            & > button
                border-radius: 0
                label span
                    font-size: 20px
                    @media (min-width: $xl)
                        font-size: 24px
            .dropdown-menu
                margin: 0
                min-width: 140px
                border-radius: 8px 0 8px 8px
                max-height: none
                width: auto
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                clip-path: none
                @media (min-width: $xl)
                    border-radius: 12px 0 12px 12px
                li
                    padding: 0
                    margin: 0
                    height: auto
                    width: auto
                    min-width: auto
                    border-bottom: solid 1px $gray-lighter
                    overflow: hidden
                    &:hover
                        background-color: $gray-lighter
                    button, p
                        line-height: normal
                        padding: 12px 20px
                        border-radius: 0
                        @media (min-width: $xl)
                            padding: 12px 34px
                        justify-content: flex-start
                        align-items: center
                        span
                            line-height: normal
                            padding: 0
                            text-transform: none
                            font-size: 11px
                            font-weight: 500
                            @media (min-width: $xl)
                                font-size: 16px
                        .material-symbols-rounded, .material-symbols-filled
                            font-size: 16px
                            @media (min-width: $xl)
                                font-size: 20px
                            margin-right: 8px