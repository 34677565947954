@import '../../../styles/styles.scss'
.export-excel-modal
    .modal-dialog
        transform: translate(0, 0) !important
        width: auto
        .modal-content
            // width: calc(392px + 64px)
            width: auto
            padding: 16px 32px
            border-left: 5px solid $primary
            border-radius: 12px
            margin: auto
            .modal-header
                border-bottom: solid 1px $gray-lighter
                padding: 0
                display: flex
                justify-content: flex-start
                padding-bottom: 14px
                .header
                    display: flex
                    color: $primary
                    font-weight: $font-bold
                    .material-symbols-rounded
                        margin-right: 5px
                        font-size: 32px
                    .title
                        font-size: 20px
                        padding-left: 5px
                        margin: 0
            .modal-body
                display: block
                padding: 0
                padding-top: 32px
                margin-left: 0
                .options
                    margin-bottom: 24px
                    &:last-child
                        margin-bottom: 0
                label
                    font-size: 14px
                    font-weight: $font-regular
                    color: $dark
                    display: flex
                    align-items: center
                    justify-content: flex-start
                    margin: 0
                    cursor: pointer
                    &.disabled
                        cursor: not-allowed
                        color: #888
                        opacity: 1
                input[type="radio"]
                    margin: 0
                    margin-right: 14px
                    appearance: none
                    border-radius: 50%
                    border: solid 2px #888888
                    display: grid
                    place-content: center
                    width: 20px
                    height: 20px
                    &:disabled
                        color: #888
                    &::before
                        content: ""
                        width: 10px
                        height: 10px
                        transform: scale(0)
                        transition: 120ms transform ease-in-out
                        box-shadow: inset 1em 1em $primary
                    &:focus
                        outline: none
                    &:checked
                        border-color: $primary
                    &:checked::before
                        transform: scale(1)
                        border-radius: 50%
            .modal-footer
                margin-top: 32px
                padding: 0
                display: flex
                justify-content: flex-end
                border-top: none
                button.button
                    min-width: auto
                    margin: none
