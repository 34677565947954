@import '../../../styles/styles.scss'

.credits-history-container
    .credits-history-branch
        // padding-right: 0
        .credits-tabs-container
            .tab-content
                .tab-pane
                    .table
                        .ReactTable
                            .rt-table
                                .rt-tbody
                                    .rt-tr-group
                                        flex: 0

    .credits-history-branch
        div
            ul
                li
                    a
                        font-size: 20px
            .tab-content
                .tab-pane
                    .card-header-branch
                        position: relative
                        margin: 0
                        .card-header-right
                            // position: relative
                            // top: 0
                            // left: 0

                            // padding: 0 0 1rem 0
                            // max-width: 100%

                            .credit-title
                                p
                                    margin: 0
                                    font-size: 14px
                                    font-weight: 500
                                    padding-left: 1rem
                            display: flex
                            flex-direction: row
                            align-items: center
                            // border-bottom: 1px solid #f6f6f6
                            // padding-bottom: 11px
                            // margin-bottom: 62px
                            .search-input-container
                                margin-right: 16px
                                .search-icon
                                    font-size: 16px
                                    font-weight: 500
                                    @media (min-width: $fix1919)
                                        font-size: 24px
                                        position: absolute
                                        z-index: 1
                                        left: 18px
                                        font-weight: 500
                                        color: #888
                                        flex-grow: 0
                                input
                                    margin: 0
                                    width: 192px
                                    border: 1px solid #f6f6f6
                                    font-weight: 500
                                    max-height: 32px
                                    padding: 9px 15px 9px 40px
                                    @media (min-width: $lg) 
                                        border-radius: 12px
                                        max-height: 45px
                                        font-size: 16px
                                        padding: 10px 15px 10px 53px
                                        width: 300px
                                    &::placeholder
                                        color: #888888
                                        font-weight: 500
                                    &:focus
                                        border: 1px solid $lightblue-intense
                                        outline: none
                                    &.active
                                        border: 1px solid $active
                            .dropdown-container
                                .form-dropdown
                                    margin-right: 15px
                                    button label
                                        font-weight: 500
                                        white-space: nowrap
                            .date-range-picker
                                .filter-date
                                    .dropdown-container
                                        .dropdown-menu-test
                                            .test
                                                label
                                                    font-weight: 500
                            .filter-clear-button
                                display: flex
                                justify-content: flex-end
                                width: 100%
                                .button
                                    box-shadow: none
                                    color: #cb3333
                                    margin: 0
                                    span
                                        text-transform: lowercase
                                        &::first-letter
                                            text-transform: uppercase
                                    &.disabled
                                        display: none
                                    .material-symbols-rounded
                                        font-size: 20px
                    .table
                        // margin-top: 9rem
                        .ReactTable
                            //max-height: 69vh
                            //min-height: 69vh
                            //height: 69vh
                            //@media (min-width: $lg)
                            //    max-height: 73.5vh
                            //    min-height: 73.5vh
                            //    height: 73.5vh  
                            .rt-table
                                max-height: 100%
                                overflow: hidden
                                .rt-thead
                                    .rt-tr
                                        .rt-th
                                            font-size: 11px
                                            @media  (min-width: $lg)
                                                font-size: 16px
                                                font-weight: 500
                                            div
                                                padding: 0px 12px
                                                justify-content: flex-start
                                                span
                                                    display: flex
                                                    justify-content: flex-start
                                                    align-items: center
                                            &:nth-child(2)
                                                display: flex
                                                justify-content: flex-start
                                                padding-left: 30px
                                            &:nth-child(3)
                                                display: flex
                                                justify-content: center
                                                text-align: left
                                                div
                                                    .credit-date-head
                                                        span
                                                            display: flex
                                                            justify-content: space-around
                                                            .material-symbols-rounded
                                                                display: flex
                                                                justify-content: center
                                                                max-width: 9%
                                            &.-sort-asc
                                                box-shadow: none
                                                div
                                                    background-color: #8EE7FF
                                                    border-radius: 12px
                                                    font-weight: 600
                                                    display: flex
                                                    align-items: center
                                                    height: 40px
                                                    span
                                                        .material-symbols-rounded
                                                            transform: rotate(180deg)
                                            &.-sort-desc
                                                box-shadow: none
                                                div
                                                    background-color: #8EE7FF
                                                    border-radius: 12px
                                                    font-weight: 600
                                                    display: flex
                                                    align-items: center
                                                    height: 40px

                                .rt-tbody
                                    .rt-tr-group
                                        .rt-tr
                                            padding-top: 16.6px
                                            padding-bottom: 16.6px
                                            @media (min-width: $lg)
                                                padding-top: 24px
                                                padding-bottom: 24px
                                            .rt-td
                                                // padding: 0
                                                display: flex
                                                justify-content: flex-start
                                                font-size: 11px
                                                @media  (min-width: $lg)
                                                    font-size: 16px
                                                span
                                                    display: flex
                                                    align-items: center
                                                    overflow: hidden
                                                    text-overflow: ellipsis
                                                    white-space: nowrap
                                                    word-wrap: break-word
                                                &:nth-child(1)
                                                    padding-left: 12px
                                                &:nth-child(2)
                                                    display: flex
                                                    justify-content: flex-start
                                                    padding-left: 60px
                                                    @media (min-width: $fix1919)
                                                        padding-left: 70px
                                                &:nth-child(3)
                                                    display: flex
                                                    justify-content: center
                                                    padding-right: 18px
                                                &:nth-child(4)
                                                    padding-left: 12px
                                                &.credit-amount-cell
                                                    padding-right: 10px
                                                &.credits-user-cell
                                                    padding-left: 18px
                                                &.create-date-cell
                                                    padding-right: 5px

    .widget-side
        padding-top: 8rem
        .credits-card
            margin-top: -6.3rem
            padding-inline: 0
            @media (min-width: $lg)
                padding-inline: 12px
            .value-container
                line-height: 1.1
            .description-container
                line-height: normal
                @media (min-width: $xl)
                    padding: 0 9px 18px
            button
                margin: 0 0 19.6px 0
                min-width: 175px
                max-width: 240px
                width: 100%
                //max-height: 40px
                margin-inline: auto
                font-weight: 700
                span
                    line-height: normal
                @media (min-width: $lg)
                    padding: 9px 15px
                .icon-left
                    font-size: 14px
                    @media (min-width: $fix1919)
                        font-size: 20px

            .total-credits
                width: 100%
                display: flex
                align-items: center
                font-weight: 500
                margin-inline: 0
                border-radius: 12px
                overflow: hidden
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                line-height: normal
                background: white
                .database
                    padding: 14px 14px
                    //width: 115px
                    background-color: $gray-light
                    color: #404040
                    font-size: 15px
                    display: flex
                    align-items: center
                    justify-content: center
                    .material-symbols-rounded
                        font-size: 28px
                .totalcredits-text
                    padding: 0 14px
                    font-size: 15px
                    .text
                        color: $gray-light
                    .number
                        margin-inline: 4px
                /* .credit-widget-container
                 margin: 1.5rem 0 1.5rem 0
                 .title-wrapper
                 min-width: auto
                 background-color: rgba(0, 0, 0, 0.1)
                 .title-container
                 padding: 0.5px 0px 0.5px 12px
                 h3
                 padding: 0 5px 0 0
                 .credit-widget-body-container
                 .label-container
                 padding: 0 0 0 5px */
            div
                .credit-widget-container
                    margin: 1.5rem 0 1.5rem 0
@media (max-width: $fix1919)
    .credits-history-container
        .credits-history-branch
            div
                .nav-tabs
                    li
                        a
                            font-size: 14px
                .tab-content
                    .tab-pane
                        .card-header-branch
                            .card-header-right
                                .credit-title
                                    p
                                        font-size: 14px
                                        font-weight: 500
                                .filters-container
                                    display: flex
                                    flex-direction: row
                                    align-items: center
                                    // border-bottom: 1px solid #f6f6f6
                                    .search-input-container
                                        // margin-right: 16px
                                        input
                                            width: 216px
                                            border: 1px solid #f6f6f6
                                            font-size: 11px
                                            font-weight: 500
                                            border-radius: 8px
                                            max-height: 32px
                                            &::placeholder
                                                font-size: 11px
                                                color: #888888
                                                font-weight: 500
                                      

                                    .date-range-picker
                                        .filter-date
                                            .dropdown-container
                                                .dropdown-menu-test
                                                    .test
                                                        max-height: 3.2rem
                                                        font-size: 11px
                                                        border-radius: 8px
                                                        width: 184px
                                                        // border: 1px solid #d6d6d6
                                                        @media (min-width: $lg)
                                                            width: 304px
                                                        label
                                                            font-weight: 500
                                                            font-size: 11px
                                                        .material-symbols-rounded
                                                            font-size: 20px
                        .table
                            .ReactTable
                                .rt-table
                                    .rt-thead
                                        .rt-tr
                                            .rt-th
                                                font-size: 11px
                                                display: flex
                                                align-items: center
                                                &:nth-child(2)
                                                    div
                                                        // height: 40px
                                                        display: flex
                                                        align-items: center
                                    .rt-tbody
                                        .rt-tr-group
                                            .rt-tr
                                                .rt-td
                                                    font-size: 11px
        .widget-side
            padding-top: 7rem
            .card
                margin: 0 15px 19.6px 15px
            .credits-card
                margin-top: -64px
                padding-left: 0px
                padding-right: 0px
                button
                    max-width: 177px
                    max-height: 31px
                    font-size: 10px
                    margin-inline: auto
                    font-weight: 700
                .total-credits
                    width: 100%
                    display: flex
                    align-items: center
                    font-size: 11px
                    font-weight: 500
                    margin-inline: 0
                    border-radius: 8px
                    overflow: hidden
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                    .database
                        padding: 6.5px 7px
                        width: 82px
                        background-color: $gray-light
                        color: #404040
                        font-size: 15px
                        font-size: 11px
                        display: flex
                        align-items: center
                        justify-content: center
                        .material-symbols-rounded
                            font-size: 15px
                    .totalcredits-text
                        padding: 0px
                        padding-left: 4px
                        font-size: 11px
                        width: 75%
                        text-align: center
                        .text
                            color: $gray-light
                        .number
                            margin-inline: 4px
                /* .total-credits
                 .credit-widget-container
                 .title-wrapper
                 .title-container
                 .title
                 font-size: 11px
                 .credit-widget-body-container
                 display: flex
                 justify-content: flex-start
                 min-width: 60%
                 max-width: 100%
                 overflow: hidden
                 margin: 0
                 padding: 0 2px
                 font-size: 11px
                 .label-container
                 margin: 0
                 padding: 0 0 0 2px
                 font-size: 11px
                 margin: 0
                 padding: 0
                 font-size: 11px */
                .value-container
                    line-height: 1.1
                .description-container
                   line-height: normal 
                .credit-widget-container
                    background-color: white
                    margin-bottom: 19.6px
                    .title-wrapper
                        .title-container
                            h3
                                font-size: 11px
                    .credit-widget-body-container
                        .label-container
                            font-size: 9px
                        .description-container
                            font-size: 11px