@import '../../../styles/styles.scss'

.DropdownUserMenu
	position: relative
	.dropdown-container
		.custom-dropdown-menu
			.buttons
				.custom-dropdown-menu
					justify-content: center
	.dropdown-menu
		min-width: 240px
		margin: 0
		padding: 0
		position: absolute
		margin-left: -240px
		border-radius: 10px
		border-top-right-radius: 0
		border: none
		top: 4.7rem
		right: 0.7rem
	.row
		margin: 0 0 0 10

	ul		
		&.ul-menu
			list-style: none
			padding: 0
			margin: 0
			margin-top: 0 !important
			// padding-left: 10px
			li
				&.li-menu			
					display: list-item
					justify-content: space-between
					align-items: center
					margin-bottom: 0
					list-style: none
					padding: 0
					border-bottom: 1px solid $gray-lighter
					padding: 0 15px
					.dropdown-container 
						.custom-dropdown-menu
							padding-left: 0
					button.menu-link-button
						border-radius: 12px
						font-weight: 600
						padding: 12px 0
						min-width: 240px
						text-align: left
						justify-content: left
						margin: 0
						box-shadow: none
						.material-symbols-rounded
							font-size: 20px
						span			
							text-transform: capitalize
							font-size: 11px
							@media (min-width: $xl)
								font-size: 16px
							font-weight: 500
						&.red
							color: red

	.no-link
		justify-content: center
		align-content: center
		align-items: center
		span			
			text-transform: capitalize

	.menu-user
		display: block
		padding: 10px 15px
		background: $gray-lighter
		border-radius: 10px 0 0 0

		.menu-user-name 
			font-family: Poppins
			font-size: 16px
			font-weight: 500
			line-height: normal
			margin: 0
			//margin: 16px 0 0 0
			//padding-left: 30px
			//color: var(--colors-styles-gris-tooltip)

		.menu-company-name 
			font-family: Poppins
			font-size: 12px
			margin: 0
			//margin: 0 0 25px 0
			//padding-left: 30px
			//color: var(--colors-styles-gris-medio-claro)		
