@import '../../../styles/styles.scss'

form#update-credits-form 
  .form-body 
    display: flex
    flex-direction: row
    justify-content: space-between
  

  .radio-button 
    margin-right: 10px
  

  .form-group 
    display: flex
    margin: 0 15px
    width: 100%

    &.flex-drow 
      flex-direction: row
    

    &.flex-dcolumn 
      flex-direction: column

      .input-text-container 
        display: flex
        flex-flow: row wrap
        justify-content: space-between

        span 
          font-weight: 400
        
      
    

    .input 
      width: 100%
    

    a 
      margin-left: 15px
      margin-top: 35px
    
  

  span.weak 
    display: block
    margin-top: 20px
    font-weight: lighter
  

  .update-credits-error 
    margin-top: 15px
    margin-left: 15px
  

  .form-footer 
    margin-top: 40px
    margin-bottom: 20px
    text-align: center

    p 
      color: $text-dark
      font-size: 24px
      font-weight: normal
      margin-bottom: 30px
    
  


.main-container 
  width: 100%

  .flex-container 
    display: flex
    width: 100%
    flex-flow: column nowrap
    align-items: center
    flex-grow: 1
  

  .update-credits.card 
    &:hover 
      box-shadow: none
      transform: none
    
    width: 100%
    max-width: 1150px


.client-detail-screen
  .client-detail
    .detail-client
      .client-credit-card
        .card-header-right
          height: 100%
          .update-credit-main
            height: 100%
            .update-credits
              height: 100%
              .card-header
                .back
                  display: flex
                  justify-content: space-between
                  .container-title
                    p
                      font-size: 15px
                      font-weight: 500
                      @media (max-width: 1366px)
                        font-size: 14px
                  .input-text-container
                    label
                      font-size: 13px
                      font-weight: normal
                      @media (max-width: 1366px)
                        font-size: 12px
                    .code-quantity-label
                      padding: 0 .5rem 0 0
                        
              form#update-credits-form
                position: relative
                height: 100%
                .input-text-container
                  font-size: 16px
                  @media (max-width: 1366px)
                    font-size: 11px
                .user-input
                  padding-top: 2rem
                  .form-group
                    .input
                      .input-holder
                        width: 100%
                        max-width: 100%
                        input
                          font-weight: normal
                          font-size: 16px
                          @media (max-width: 1366px)
                            height: 28px
                            font-size: 11px
                .radio-select
                  .form-group
                    margin: 0
                    display: flex
                    justify-content: center
                    .radio-button
                      button
                        @media (max-width: 1366px)
                          min-width: 14px
                          min-height: 14px
                        .inner
                          @media (max-width: 1366px)
                            width: 6px
                            height: 6px
                      label
                        display: flex
                        align-items: center
                        span
                          @media (max-width: 1366px)
                            font-size: 10px
                .form-footer
                  position: absolute
                  // bottom: 33px
                  width: 100%
                  background-color: transparent
                  .button
                    background-color: #773DB0
                    color: white
                    border: 1px solid #773DB0
                    padding: 1rem
                    &.disabled
                      background-color: #d6d6d6
                      border: 1px solid #d6d6d6
                      color: #aaaaaa
                      .material-symbols-rounded
                        color: #aaaaaa
                    .material-symbols-rounded
                      color: white


  

