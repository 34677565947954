.table-loader {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  top: 0;
  bottom: 0;
  text-align: center;
  z-index: 300;

  .loader {
    margin-top: 80px;
  }
}
