@import '../../../styles/styles.scss'

.user-detail-screen
    #user-tab
        .tab-content
            padding: 0
            .tab-pane
                .card-with-tab
                    overflow-y: auto
                    form
                        .row
                            .no-padding
                                padding-left: 0px
                                padding-right: 0px
                                @media (min-width: $xl)
                                    padding-left: 20px
                                    padding-right: 20px
                                .email-form
                                    .email-input
                                        .input
                                            margin-bottom: 15px
                                            .input-holder
                                                max-width: 100%
                                .disable-user
                                    div
                                        padding-left: 0
                                        margin: 21px 0 0 0
                                        @media (min-width: $fix1919)
                                            margin: 36px 0 0 0
                                        label
                                            color: #cb3333
                                .input
                                    .input-holder
                                        max-width: 100%

        button.button
            min-width: none
        .validate-button
            padding: 6px 20px
            min-width: 101px
            width: 101px
            margin-bottom: 12px
            box-shadow: none
            filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.05))
            @media (min-width: $lg)
                padding: 8px 30px
                min-width: 142px
                width: 142px
                margin-top: -5px
                margin-bottom: 21px
            span
                font-weight: $font-bold
    .card
        box-shadow: none
        height: 100%
        &:hover
            box-shadow: none
        &.card-with-tab
            border-top-left-radius: 0
        &.edit-user-container
            // min-height: 832px
        .second-col
            width: 27.2%
            .input
                .input-holder
                    .show-password
                        right: 11px
            .text-on-password
                font-size: 8px
                margin-top: -5%
                margin-bottom: 7%
                padding-top: 2%
                @media (min-width: $lg)
                    font-size: 10px
                    // margin-top: -19px
                    // margin-bottom: 19px
        .card-header
            border-bottom: solid 1px $gray-light
            .p
                margin-left: none
                font-weight: $font-semibold
                font-size: $font-xl
            .card-header-left
                .back
                    .button
                        margin: 0
                        padding: 0
                    .button.button.back-icon
                        min-width: auto
                        span
                            &.material-symbols-rounded
                                margin-right: none
                                font-weight: $font-semibold
                                font-size: 40px
        form
            padding: 0 1.4rem 0 1.4rem
            .input
                .input-holder
                    @media (min-width: $xl)
                        max-width: 419px
    .dropdown-menu.show
        word-break: break-all
    .permissions-container
        .flex
            .square-button
                padding: 8px 15px 8px 0
    .language-selector
        .dropdown-container
            label
                margin: 0
                margin-left: 0
            .custom-dropdown-menu
                padding-left: 1px
                justify-content: flex-start
    .form-footer
        margin-top: 20px
        /* &.validated
         margin-top: 210px */

.choose-partner-container
    .choose-partner-item
        display: flex
        justify-content: space-between
        align-items: center
        margin: 20px 0

#user-tab-tab-new-user
    // position: absolute
    // bottom: -18px
    // width: 184px
    // height: 70px
    // margin: 21px 579px 15px 14px
    // padding: 27px 46px 26px 47px
    // background-color: #fff
    font-size: $font-xl
    font-weight: $font-bold
    // border-radius: 8px
    z-index: 3
    // border-bottom-right-radius: 0px
