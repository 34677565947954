@import '../../../styles/styles.scss'



.language-selector
    .dropdown-container
        margin: 0
        padding: 0
        cursor: default
        .input-label
            margin-left: 7px
            margin-bottom: 7px
            font-size: 11px
            @media (min-width: $xl)
                font-size: 16px
            font-weight: $font-bold
            span.required-icon
                font-size: 16px
                color: $danger
                margin-left: 2px
                font-weight: $font-bold
        .title
            display: flex
            align-items: center
            padding-top: 12px
            .menu-link-no-button
                font-weight: 600
                padding: 0
                min-width: 240px
                cursor: none
                font-size: 11px
                @media (min-width: $xl)
                    font-size: 16px
                text-transform: capitalize
                // text-align: center
                // justify-content: center

            .material-symbols-rounded
                font-size: 20px
                margin-right: 10px        
        .buttons
            .custom-dropdown-menu
                padding: 0
                margin: 0
                padding-left: 10px
                display: flex
                justify-content: end
                li
                    display: flex
                    justify-content: center
                    align-items: center
                    cursor: pointer
                    background-color: $gray-lighter
                    border-radius: 6px
                    margin: 12px
                    max-width: 41.9px
                    min-width: 41.9px
                    max-height: 30.48px
                    min-height: 30.48px
                    padding: 6.5px 14px
                    font-size: 11px
                    font-family: Poppins
                    color: #888888
                    text-transform: uppercase
                    &:hover
                        background-color: #AEE8F9
                        color: #1e7bb0        
                    &:last-child
                        margin-right: 0
                    &:first-child
                        margin-left: 0
                    &.selected
                        background-color: #8EE7FF
                        color: #000000
                        

