@import '../../../styles/styles.scss'
.dropdown-container
    .bottom-text-label
        margin-top: 10px
        margin-left: 4px
        margin-right: 15px
        font-weight: $font-regular
        color: $gray-dark
        font-size: $font-xs
        &.danger
            color: $danger
    .form-dropdown
        // width: 184px
        // height: 44.8px
        max-width: 200px
        //display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center
        //gap: 66px
        border-radius: 8px
        padding: 0
        border: solid 1px transparent
        background-color: $light
        position: relative
        border: 1px solid #f6f6f6
        &:hover
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
        @media (min-width: $lg)
            border-radius: 12px
        .dropdown-title
            width: 100%
            display: flex
            justify-content: center
            span
                color: $gray-regular
                font-size: 12px
                font-weight: 500
        &.show
            box-shadow: none
            border: 1px solid #AEE8F9 !important
            button
                margin-top: 0
                span.material-symbols-rounded
                    transform: rotate(180deg)
        &.selected
            box-shadow: none
            border: 1px solid #AEE8F9 !important

        .custom-dropdown-menu
            min-width: 300px
            background-color: $light
            z-index: 1
            transform: none !important
            max-height: 225px
            padding: 0
            overflow-y: auto
            overflow-x: hidden
            font-family: "Poppins", sans-serif
            position: relative
            margin: 238px 0 0 -178px
            .search-bar
                .input
                    margin-bottom: 0
                    .input-holder
                        input
                            background-color: $gray-lighter
                            border: none
                i
                    color: $gray-light
                    display: flex
                    align-items: center
                    background-color: #f5f5f5
                    border-radius: 8px
                    margin: 0 9px
            li
                padding: 5px 17.5px
                font-size: 16px
                font-weight: 500
                text-align: left
                font-weight: $font-semibold
                border-bottom: solid 1px $gray-lighter
            li:hover
                background-color: $gray-light

        button
            width: 100%
            display: flex
            justify-content: space-between
            align-items: center
            margin: 0px
            padding: 6px 8px
            color: #7a7a7a
            @media (min-width: $lg)
                padding: 7.5px 15px
            label
                flex: 1
                overflow: hidden
                text-overflow: ellipsis
                text-align: left
            .placeholder
                max-height: 18px
                overflow: hidden
                text-overflow: ellipsis
                margin-bottom: 8px
            label
                // max-height: 18px
                cursor: pointer
                margin: 0
                font-size: 11px
                font-weight: 500
                @media (min-width: $lg)
                    font-size: 16px
                    overflow: hidden
                // margin-bottom: 8px
                // max-width: 150px
                    text-overflow: ellipsis
                    white-space: nowrap
            span.material-symbols-rounded
                font-size: 20px
                font-weight: $font-bold
                color: #000000
                @media (min-width: $lg)
                    font-size: 30px
                // padding-bottom: 3px

        .multi
            width: 100%
            display: flex
            justify-content: space-between
            align-items: center
            margin-top: 6px
            color: #7a7a7a
            label
                max-height: 18px
                overflow: hidden
                text-overflow: ellipsis
                margin-bottom: 8px
            span.material-symbols-rounded
                font-size: 30px
                color: $dark
                font-weight: $font-bold
                padding-bottom: 3px

            ul.dropdown-menu.show
                z-index: 1
                box-shadow: none
                border: none
                top: 32px !important
                max-width: 300px
                transform: none !important
                max-height: 225px
                padding: 0
                overflow-y: auto
                overflow-x: hidden
                .search-bar
                    .input
                        margin-bottom: 0
                        .input-holder
                            input
                                background-color: $gray-lighter
                                border: none
                    i
                        color: $gray-light
                        display: flex
                        align-items: center
                        background-color: #f5f5f5
                        border-radius: 8px
                        margin: 0 9px
                li
                    padding: 5px 17.5px
                    font-size: 16px
                    font-weight: 500
                    text-align: left
                    font-weight: $font-semibold
                    border-bottom: solid 1px $gray-lighter
                li:hover
                    background-color: $gray-light
        ul.dropdown-menu.show
            z-index: 1
            // box-shadow: none
            border: none
            top: 100% !important
            //max-width: 300px
            max-width: 100%
            transform: none !important
            max-height: 225px
            padding: 0
            overflow-y: auto
            overflow-x: hidden
            background: #fff
            border-radius: 12px
            margin-top: 7px
            // @media (min-width: $xl)
            //     top: 33px !important
            .search-bar
                margin: 10px 8px
                border-radius: 12px
                background-color: $gray-lighter
                .input
                    margin-bottom: 0
                    .input-holder
                        input
                            width: 140px
                            padding-left: 0
                            background-color: $gray-lighter
                            border: none
                i
                    color: $gray-light
                    display: flex
                    align-items: center
                    background-color: #f5f5f5
                    border-radius: 8px
                    margin: 0 9px
            li
                padding: 7px 17.5px
                line-height: normal
                font-size: 11px
                font-weight: 500
                text-align: left
                font-weight: $font-semibold
                border-bottom: solid 1px $gray-lighter
                @media (min-width: $xl)
                    font-size: 16px
            .clean-filter
                color: #cb3333
            li:hover
                background-color: $gray-light
            li.selected
                background-color: $lightblue
        ul.dropdown-menu.with-search.show
            top: 70% !important
            margin-left: 0.2rem
            border-top-left-radius: 0px
            border-top-right-radius: 0px
            left: -2px !important
            clip-path: inset(0px -10px -10px -10px)
    .border-error
        border: solid 1px red
