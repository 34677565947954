@import '../../../styles/styles.scss';

.label-container {
  margin: 20px 0 10px 0;
}

.custom-label {
  color: #333;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
