@import '../../../styles/styles.scss'

.container-fluid.dashboard-screen
  .dashboard-progress-bar-container 
    display: flex
    flex-flow: column nowrap
    align-items: center
    margin-bottom: 20px

    .text-header 
      width: 100%
      color: #344563 !important
      align-items: center
      display: flex
      font-weight: bold
      flex-grow: 1
      justify-content: space-between
      white-space: nowrap
      min-width: 40px
      margin-bottom: 12px
      &.hovered
        color: #50b5d0 !important
    

    .progress-bar 
      width: 100%
      background-color: $bg-body
      border: none
      border-radius: 10px
      height: 10px
      box-shadow: none
      &:hover
        .bar
          background-color: #50b5d0
      .bar
          background-color: #344563
          height: 25px
          border-radius: 6px
  


.persons-progress-bar-container 
  display: flex
  flex-flow: row nowrap
  align-items: center
  margin: 0 0 15px 15px
  margin-bottom: 20px

  .progress-bar 
    width: 100%
    background-color: transparent
    border: solid 1px $primary
    border-radius: 30px
    box-shadow: none
    height: 10px
  

  .bar 
    height: 100%
    background-color: $primary
    border-radius: 30px
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    box-shadow: none
  

  span 
    min-width: 40px
    font-weight: bold
  


.compatibilities-progress-bar-container 
  display: flex
  flex-flow: row nowrap
  align-items: center
  position: relative
  padding-right: 5px
  .progress-bar 
    width: 100%
    background-color: transparent
    height: 15px
    border-radius: 12px
    border-radius: 30px
    box-shadow: none
    display: flex
  

  .bar 
    background-color: $blue
    border-radius: 12px
    box-shadow: none
    height: 15px
  

  .number 
    padding-left: 1rem
    font-weight: bold
    text-align: right
    color: white
    font-size: 10px
    transform: translate(-7px, -2px)
    &.low-percent 
      color: $blue
    
  

