@import '../../../styles/styles.scss'
.jobs-screen
    .header-container
        // margin-bottom: 12px
        // padding-left: 10px !important
    .button
        margin-left: 18px
        span
            display: flex
            align-items: center
            // font-size: 10px
            .material-symbols-rounded
                font-size: 20px
                margin-right: 8px
    #uncontrolled-tab-example
        .nav-tabs
            li > a
                font-size: 14px
                padding: 19px 37px
                @media (min-width: $xl)
                    font-size: 20px
                    padding: 27px 51px
    .tab-content
        position: relative
        .card-header-right
            position: absolute
            top: -45px
            right: 15px
            @media (min-width: $xl)
                top: -53px
    .filters-container
        display: flex
        align-items: center
        justify-content: space-between
        // padding-bottom: 2rem
        .left-side
            display: flex
            gap: 3rem
            .filter-date
                display: flex
                align-items: center
            .dropdown-menu-test
                .date-button
                    label
                        font-weight: 500
            .search-bar
                .bar-filter-container
                    .input
                        margin: 0
                        .input-holder
                            span
                                font-size: 20px
                                @media (min-width: $lg)
                                    font-size: 30px
                                    //top: 18px
                            input
                                padding: 6.5px 5px 6.5px 30px
                                max-width: 216px
                                color: #888
                                font-weight: 500
                                border: 1px solid #f6f6f6
                                line-height: normal
                                font-size: 11px
                                @media (min-width: $lg)
                                    font-size: 16px
                                    padding: 9.5px 5px 9.5px 40px
                                    max-width: 304px

            .dropdown-menu-test
                .date-button
                    font-size: 12px
                    max-width: 184px
                    //padding: 0 5px
                    @media (min-width: $lg)
                        max-width: 264px
                    label
                        margin: 0
            .dropdown-container
                min-width: 131px
                display: flex
                align-items: center
                .form-dropdown
                    font-size: 12px
                    height: auto
                    //padding: 0 5px
                    text-align: flex-start
                    width: auto
                    max-width: 131px
                    @media (min-width: $lg)
                        max-width: 184px
                        width: 184px
                        // height: 45px
                    // button
                    //     label
                    //         overflow: auto
                    .dropdown-menu
                        z-index: 100
                        li
                            font-size: 11px
                            @media (min-width: $fix1919)
                                font-size: 16px
                    button
                        // gap: 56px
                        display: flex
                        justify-content: space-between
                        //margin: 2px 0
                        label
                            font-weight: 500
                            margin: 0
                            overflow: hidden
                            text-overflow: ellipsis
                            color: #aaaaaa
            .input-holder
                display: flex
                max-width: 305px
                .material-symbols-rounded
                    position: absolute
                    top: 12px
                    left: 5px
                    color: $gray-regular
                    font-size: 22px
                    margin-right: 8px
                    margin-top: -7px
        .delete-button-container
            .delete-button
                min-width: auto
                padding: 0
                margin: 0
                .icon-left
                    font-size: 20px
                span
                    font-weight: $font-semibold
                    text-transform: none
            .button
                &.disabled
                    display: none
    .table
        .ReactTable
            .rt-thead
                padding-left: 55px
                border-bottom: 1px solid $dark
                .rt-tr
                    text-align: justify
                    display: flex
                    align-items: center
                .rt-th
                    border: none
                    &:nth-last-child(2)
                        justify-content: start
                        padding-left: 50px
                    &.-sort-asc, &.-sort-desc
                        box-shadow: none
                        .header-container
                            background-color: $lightblue-active
                            border-radius: 8px
                            @media (min-width: $xl)
                                border-radius: 12px
                                font-weight: 600

                    div
                        .header-container
                            font-size: 11px
                            padding: 4px 6px 4px 0
                            width: fit-content
                            display: flex
                            align-items: center
                            @media (min-width: $xl)
                                font-size: 16px
                                padding: 8px 12px 8px 0
                    &.created-date-header
                        display: flex
                        justify-content: center

            .rt-tbody
                .rt-tr-group
                    padding-left: 56px
                    flex: 0
                    .rt-tr
                        @media (min-width: $fix1919)
                            padding: 15.5px 0 !important
                        .rt-td
                            display: flex
                            align-items: center
                            text-align: justify
                            padding: 10px 0 10px
                            // padding-left: 10px
                            @media (min-width: $lg)
                                padding: 0

                            span
                                white-space: nowrap
                                text-overflow: ellipsis
                                overflow: hidden
                            &:nth-last-child(2)
                                justify-content: start
                                font-weight: 500
                                font-size: 12px
                                padding-left: 50px
                                overflow: visible
                                max-height: min-content
                            &.enabled
                                width: 50px !important
                                &.roles
                                    margin-right: 15px
                                &.dropdown-options
                                    width: 25px !important
                            .campaigns-container
                                display: flex
                                flex-direction: row
                                align-items: center
                                max-width: 100%
                                &.empty-center
                                    padding-left: 40px
                                .campaigns-name
                                    display: flex
                                    max-width: 100%
                                    overflow: hidden
                                    .all-campaigns-container
                                        overflow: hidden
                                        .campaigns-name-container
                                            display: flex
                                            flex-direction: column
                                            text-overflow: ellipsis
                                            white-space: nowrap
                                        &:hover
                                            color: $primary
                                            transition: all ease-in-out .3s
                                            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                                            padding: 0 5px
                                            border-radius: 12px
                                            // width: inherit
                                            margin: 0
                                            line-height: 1.2

                                        .all-campaigns
                                            opacity: 0
                                            width: .1px
                                            display: none
                                        &:hover
                                            .all-campaigns
                                                width: auto
                                                transition: all ease-in-out 0.3s
                                                display: block
                                                color: $primary
                                                opacity: 1

                                    .others-campaigns
                                        position: relative
                                        background-color: $gray-light
                                        color: $gray-dark
                                        width: fit-content
                                        font-size: 10px
                                        margin: 11px 0 12px 8px
                                        padding: 2px 4px 2px 3px
                                        border-radius: 50%
                                    &:hover
                                        .others-campaigns
                                            transition: all ease-in-out 0.3s
                                            display: none
                                .number
                                    font-size: 10px
                                    @media (min-width: $fix1919)
                                        font-size: 12px
                            &.created-date-cell
                                display: flex
                                justify-content: center
                                padding-right: 35px
                                @media (min-width: $fix1919)
                                    padding-right: 40px
                            &.job-campaign-cell
                                padding-left: 50px
                        .number
                            font-size: 12px
                            font-weight: $font-semibold
                            @media (min-width: $fix1919)
                                font-size: 16px
                        .rt-td:last-child
                            text-align: center
                            overflow: visible
                            .dropdown-container
                                button label
                                    margin: 0
                                ul
                                    padding: 0
                                    border-radius: 0
                                    border-bottom-left-radius: 15px
                                    border-bottom-right-radius: 15px
                                    left: -50px !important
                                    // transform: translate3d(-18%, 29px, 0px) !important
                                    @media (min-width: $lg)
                                        // transform: translate3d(-35%, 29px, 0px) !important
                                    li
                                        flex-grow: 0
                                        display: flex
                                        flex-direction: row
                                        justify-content: flex-start
                                        align-items: center
                                        gap: 8px
                                        font-weight: 500
                                        padding: 0
                                        button
                                            padding: 12px 32px 12px 32px
                                            width: 100%
                                        &:hover
                                            background-color: $gray-lighter
                                            button
                                                background-color: transparent
                                        &:last-child:hover
                                            border-bottom-left-radius: 8px
                                            border-bottom-right-radius: 8px
                                            @media screen and ( min-width: $lg )
                                                border-bottom-left-radius: 12px
                                                border-bottom-right-radius: 12px
                                    button
                                        border: 0
                                        margin: 0
                                        justify-content: flex-start
                                        min-width: auto
                                        &.delete-button
                                            color: $danger
                                button:hover
                                    background-color: transparent
                                    color: inherit
                                    cursor: pointer
                                &.users-dropdown
                                    // display: flex
                                    // justify-content: center
                                    width: 100%
                                    img
                                        display: none
                                    .dropdown-menu
                                        &.show
                                            // padding: 16px 0px
                                            border-radius: 8px
                                            border-top-right-radius: 0px
                                            max-width: 254px
                                            margin-left: -50px
                                            // width: 217px
                                            //height: 168px
                                            display: flex
                                            //padding: 12px 0 12px 0
                                            z-index: 1000
                                            margin: 0px 0px 0 -80px
                                            @media (min-width: $lg)
                                                margin: 0px 0px 0 -125px
                                                border-radius: 12px
                                                border-top-right-radius: 0px
                                .dropdown > button
                                    padding: 0
                                    margin: 0
                                    justify-content: flex-end
                                    width: fit-content
                                    span
                                        font-weight: $font-bold
                                        color: $dark
                .pagination-bottom
                    .paged-drawer
                        &.table-pagination-container
                            border: none
                            // padding-bottom: 35px
                            // padding-left: 39px
