@import '../../../styles/styles.scss'

.bapro-questions-container
    display: flex
    flex-direction: row
    gap: 20px
    height: 70vh
    @media (max-width: $fix1919)
        height: fit-content
    .bapro-mail-container
        h3
            padding: 10px 0
            font-size: 25px
        h5
            padding: 30px 0
            font-size: 20px
        .add-answer
            textarea
                resize: none
                width: 100%
                margin: 0
                padding: 13px 22px 4px 22px
                border-top-left-radius: 8px
                border-top-right-radius: 8px
                border: 1px solid $gray-light
                border-bottom: none
                height: 80px
                margin-bottom: 0
                font-size: 11px
                @media (min-width: $lg)
                    font-size: $font-lg
                    border-top-left-radius: 12px
                    border-top-right-radius: 12px
                &::placeholder
                    font-size: 11px
                    font-weight: 400
                    color: $gray-light
                    @media (min-width: $lg)
                        font-size: $font-lg
                &:focus
                    outline: none
            .send
                display: flex
                margin: 0
                align-items: center
                justify-content: space-between
                padding: 15px 22px
                background-color: rgba(217, 217, 217, 0.23)
                color: #7a7a7a
                margin-top: -5px
                border: 1px solid $gray-light
                border-top: none
                border-bottom-left-radius: 8px
                border-bottom-right-radius: 8px
                @media (min-width: $lg)
                    border-bottom-left-radius: 12px
                    border-bottom-right-radius: 12px
                span
                    font-size: 10px
                    @media (min-width: $lg)
                        font-size: 12px
                    &.error-note
                        color: #cb3333
                button
                    &.button
                        width: auto
                        margin-right: initial
                        min-width: auto
                        height: 34px
                        box-shadow: 0 2.8px 10.7px 0 rgba(0, 0, 0, 0.05)
                        border-radius: 8px
                        padding: 4px 15px
                        @media (min-width: $fix1919)
                            height: 49px
                        span
                            font-size: 18px
                        @media (min-width: $fix1919)
                            font-size: 18px
    .bapro-video-container
        h3
            padding: 10px 0
            font-size: 25px
        h5
            padding: 30px 0
            font-size: 20px
        .upload-file-container
            div
                .file-charged
                    .button-delete
                        display: none
