@import '../../../styles/styles.scss'

.expand_more
  width: 24px
  height: 24px
  margin: 18px 0 14px 55px
  padding: 8.4px 6.4px 9px 6.4px

.nav-brand
  display: flex
  align-items: baseline
  flex-direction: row
  margin: 9px 0

  img
    margin-right: 20px

    &.logo
      height: 70px

      &.logo-text
        height: 77.99px

nav
  background-color: #d7f4fc
  border-bottom: solid 1px $gray-light
  @media (min-width: $md)
    padding: 0 56px 0 71px
  @media (min-width: $lg)
    padding: 0 40px
  width: 100%
  z-index: 999
  margin-bottom: 12px
  .navbar-header
    padding: 15px 23px
    @media (min-width: $md)
      padding: 5px 0
    @media (min-width: $xl)
      padding: 0
    .navbar-brand
      height: 50px
      padding: 0
      @media (min-width: $xl)
        margin-top: 15px
      @media (min-width: $lg)
        // padding: 0 80px
    .navbar-toggle
      margin-right: 0
  .nav-brand
    display: flex
    align-items: baseline
    flex-direction: row
    margin: 9px 0
    img
      &.logo
        height: 70px
      &.logo-text
        height: 77.99px

  .left-container
    display: flex
    align-items: center
    justify-content: flex-end
    z-index: 1000

  .user-container
    display: flex
    align-items: center
    justify-content: center
    margin-left: 16px
    overflow: hidden
    border-radius: 50%
    backdrop-filter: blur(0.6rem)
    
    .partner-logo
      height: 45px
      width: 45px
      border-radius: 50%
      object-fit: contain

    .person-logo
      height: 45px
      width: 45px
      border-radius: 50%
      background-color: white
      display: flex
      align-items: center
      justify-content: center
      span
        font-size: 40px
        color: #aaa

    .material-symbols-rounded
      font-size: 45px
      color: $gray-regular
      background-color: #fff
      border-radius: 50%

    .dropdown
      margin-left: 15px
      margin-right: 10px

      button
        min-width: 3rem
        padding: 0
        border: 1px solid black
        background: white !important
        margin-left: 10px

      .dropdown-menu
        margin-top: 10px
        margin-right: 110px

      i
        color: grey
        font-size: 20px
        font-weight: 100

      img
        display: none

@media screen and (max-width: 1200px)
  nav
    flex-direction: column
