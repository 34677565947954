@import '../../../styles/styles.scss'

.Tooltip
	position: relative
	max-width: 20px
	max-height: 20px
	z-index: unset
	@media (min-width: $lg)
		max-width: 28px
		max-height: 28px
	.material-symbols-rounded
		color: $primary
		font-size: 20px
		@media (min-width: $lg)
			font-size: 28px
	button
		padding: 0
	.tooltip
		font-family: Poppins
		bottom: 34px
		min-width: 150px
		&.right
			margin-left: -168px
		.tooltip-inner
			padding: 8px
			width: 196px
			background-color: $darkFont
			opacity: .9
			font-family: Poppins
			border-radius: 8px
			@media (min-width: $lg)
				border-radius: 12px
			.material-symbols-rounded
				color: $light
				padding: 0
				font-size: 28px
		span.icon
			display: block
		.tooltip-arrow
			display: none
