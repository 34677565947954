@import '../../../styles/styles.scss'

.delete-client
    display: flex
    justify-content: center
    /* .modal-content
     border-left: 2px solid #CB3333
     width: 392px
     height: 205px
     border-radius: 12px
     .modal-header
     border: 0
     display: flex
     h4
     display: flex
     align-items: center
     color: #cb3333
     .material-symbols-rounded
     font-size: 29px
     img
     display: none
     .modal-body
     padding: 2rem 0 0 0
     .modal-footer
     border: 0
     display: flex
     justify-content: flex-end
     padding-top: 2rem
     .primary
     border: none
     background-color: #CB3333
     min-width: 0
     width: 129px
     height: 45px
     margin: 0
     padding: 0
            /* .alternative-gray-modal
             box-shadow: none
             color: #773db0
             min-width: 0
             width: 129px
             height: 45px
             margin: 0
             padding: 0 */ 

.client-detail-screen
    padding: 0
    .tab-content
        overflow: auto
    #uncontrolled-tab-example
        .tab-content
            overflow: visible
            padding: 0
            background-color: transparent
            box-shadow: none
        .nav-tabs
            li
                z-index: 1
                a
                    @media (max-width: 1366px)
                        font-size: 14px
    .client-detail
        height: 100%
        .card-header
            border-bottom: solid 1px #d6d6d6
            padding-bottom: 0rem
            .card-header-left
                width: 100%
                display: flex
                justify-content: space-between
                .required-text
                    font-size: 12px
                    color: #cb3333
                    display: flex
                    align-items: center
            .back-parent
                .back
                    display: flex
                    justify-content: flex-start
                    align-items: center
                    .button-back
                        background-color: white
                        color: #8551B8
                        padding-left: 0rem
                        padding-right: 0rem
                        min-width: 0rem
                        margin: 0
                        box-shadow: none
                        span
                            font-size: 3.5rem
                            font-weight: bold
                    button.button
                        padding: 0
                        .material-symbols-rounded.icon-left
                            margin-right: 0rem
                    p
                        font-weight: 500
                        font-size: 14px
                        margin: 0
                        @media ( min-width: $lg )
                            font-size: 20px
        form
            height: 100%
            .detail-client
                display: inline-flex
                width: 100%
                margin: 0
                padding: 0
                background-color: transparent
                box-shadow: none
                height: 100%
                @media (max-width: 800px)
                    flex-wrap: wrap

                .client-detail-card
                    overflow: auto
                    background-color: white
                    border-radius: 0 12px 12px 12px
                    padding: 23px 3rem 3rem 3rem
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
                    .card-header
                        width: 100%
                        .back-parent
                            .back
                                p
                                    @media (max-width: 1366px)
                                        font-size: 14px
                                        display: flex
                                        align-items: center
                            .client-detail-dropdown
                                .dropdown
                                    button
                                        min-width: 0
                                        padding: 0
                                        margin: 0
                                        display: flex
                                        align-items: center
                                        color: black
                                        &:hover
                                            background-color: transparent
                                            color: black
                                    label
                                        margin: 0
                                    .dropdown-menu
                                        left: -2rem !important
                                        border-radius: 12px 0 12px 12px
                                        li
                                            padding: 0
                                            button
                                                display: flex
                                                justify-content: flex-start
                                                width: 100%
                                                max-width: none
                                                min-width: 0
                                                padding: 1rem
                                                border: none
                                                &:hover
                                                    background-color: transparent
                                                &.normal-button
                                                    color: black
                                                &.delete-button
                                                    color: #cb3333

                    .logo-card
                        margin-top: 33px
                        width: 100%
                        display: flex
                        justify-content: center
                        div
                            margin: 0
                            padding: 1rem
                            overflow: hidden
                            max-width: 271px
                            max-height: 271px
                            img
                                max-width: 242.8px
                                max-height: 242.8px
                    .branch-name-date
                        margin-top: 24px
                        margin-bottom: 103px
                        .branch-name
                            p
                                text-align: center
                                font-size: 24px
                                font-weight: bold
                        .branch-date
                            p
                                text-align: center
                                font-size: 14px
                    .branch-credit-widget
                        .credit-widget-container
                            margin-bottom: 1rem
                            .title-wrapper
                                &.row-view
                                    padding: 7px 0
                                    @media ( min-width: $lg )
                                        padding: 7.5px 0
                            .credit-widget-body-container
                                display: flex
                                justify-content: center
                                .label-container
                                    padding: 0
                    .update-main-body
                        .input
                            padding-top: 72px
                        .label-user-type
                            font-size: 11px
                            font-weight: 700
                            margin-bottom: 7px
                            @media (min-width: $fix1919)
                                font-size: 16px
                        .square-button
                            padding: 11.5px 15px 11.5px 5px
                            margin-top: 15px
                            @media (min-width: $fix1919)
                                margin-top: 25px
                        .footer-main-body
                            padding-top: 14rem
                            .button
                                width: 240px
                                height: 45px
                                display: flex
                                align-items: center
                                background-color: #773db0
                                color: white
                                @media (max-width: 1366px)
                                    width: 177px
                                    height: 37px
                                .material-symbols-rounded
                                    color: white
                                    padding-bottom: .2rem
                                span:not(.material-symbols-rounded)
                                    display: flex
                                    align-items: center
                                    height: 100%
                .client-credit-card
                    background-color: white
                    border-radius: 12px
                    margin: 0 2rem 0 2rem
                    max-width: 100%
                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
                    padding: 0 2rem 30px 2rem
                    @media (max-width: 800px)
                        margin: 2rem 2rem 0 0
                    .card-header-right
                        padding-top: 28px
                        position: static
                        .report-detail-container
                            display: flex
                            flex-direction: column
                            padding-bottom: 35px
                            height: 50%
                            .report-detail
                                border-bottom: solid 1px #d6d6d6
                                padding-bottom: 10px
                                span
                                    font-weight: 500
                                    @media (min-width: $fix1919)
                                        font-size: 15px
                            .report-detail-types
                                padding: 15px 0
                                font-size: 10px
                                .report-type
                                    font-weight: 500
                                    font-size: 11px
                                    @media (min-width: $fix1919)
                                        font-size: 15px
                                .report
                                    font-size: 11px
                                    @media (min-width: $fix1919)
                                        font-size: 15px
                        .update-credit-main
                            height: 50%
                            .update-credits
                                form#update-credits-form
                                    .form-footer
                                        margin-bottom: 0
                .client-credit-widget
                    padding: 0
                    background-color: transparent
                    border-radius: 12px
                    max-width: 100%
                    @media (max-width: 800px)
                        margin-top: 2rem
                    .credits-card
                        .total-widget
                            .credit-widget-container
                                .title-wrapper
                                    min-width: 40%
                                    background-color: #d6d6d6
                                .credit-widget-body-container
                                    width: 60%
                                    height: 100%
                                    padding-top: 2px
                                    .label-container
                                        padding: 0 0 0 5px
                                        margin: 0
                    .credit-widget-container
                        margin-bottom: 1rem
                        background-color: white
            .new-client-row
                display: flex
                flex-wrap: wrap
                background-color: white
                margin: 0
                // padding: 2rem
                border-radius: 0 12px 0 0
                .card-header
                    width: 100%
                    border-radius: 0 12px 0 0
                .new-client
                    width: 100%
                    .right-container
                        padding-right: 0
                        .text-on-password
                            font-size: 8px
                            margin-top: -2%
                            margin-bottom: 9%
                            @media (min-width: $lg)
                                font-size: 10px
                                margin-top: -5%
                            &.error-password
                                color: $danger
                        .client-form-subtitle
                            padding-bottom: 1rem
                            .input-label
                                height: 16px
                                margin-top: 1rem
                                font-family: Poppins
                                font-size: 15px
                                font-weight: 500
                                font-stretch: normal
                                font-style: normal
                                line-height: 1.06
                                letter-spacing: normal
                                text-align: left
                                color: #404040
                        .row
                            div
                                .input
                                    .input-holder
                                        max-width: none
                                        input
                                            padding: 8px 10px
                                            padding-left: 30px
                                        @media screen and (min-width: $lg)
                                            input
                                                font-size: 13px
                                                min-width: 270px
                                            /* button.show-password
                                             margin-right: -40px */

                                        @media screen and (min-width: 1600px)
                                            input
                                                font-size: 16px
                                                min-width: 340px
                                            /* button.show-password
                                             margin-right: -110px */

                                    .input-label-container
                                        .input-label
                                            height: 24px
                                            width: 100%
                                            font-family: Poppins
                                                // font-size: 16px
                                            font-weight: 600
                                            font-stretch: normal
                                            font-style: normal
                                            line-height: normal
                                            letter-spacing: normal
                                            text-align: left
                                            color: #000
                    .left-container
                        margin-top: 4.6rem
                        padding-left: 0
                        div.row
                            .input
                                .input-holder
                                    max-width: none
                                    input
                                        padding: 8px 10px
                                        padding-left: 30px
                                    @media screen and (min-width: $lg)
                                        input
                                            font-size: 13px
                                            min-width: 270px
                                    @media screen and (min-width: 1600px)
                                        input
                                            font-size: 16px
                                            min-width: 340px
                            .button-container
                                margin-top: 2rem
                                .label-user-type
                                    font-size: 11px
                                    @media (min-width: $fix1919)
                                        margin-bottom: 7px
                                        font-size: 16px
                                .required-icon
                                    color: #cb3333
                                    font-size: 11px
                                    font-weight: 600
                                    @media (min-width:$fix1919)
                                        font-size: 16px
                                .dropdown-container
                                    .form-dropdown
                                        padding: 0
                                        border: none
                                        min-width: 184px
                                        max-height: 42px
                                        @media (min-width:$fix1919)
                                            max-height: 52px
                                        &:hover
                                            box-shadow: none
                                        &.selected
                                            button
                                                color: black
                                        button
                                            border-radius: 8px
                                            border: 1px solid $gray-lighter
                                            padding: 10px 17px
                                            min-width: 184px
                                            &:hover
                                                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
                                            @media (min-width: $lg)
                                                border-radius: 12px
                                            label
                                                font-weight: 500
                                        ul
                                            width: 100%
                                    .dropdown
                                        button
                                            border-radius: 12px
                                            color: #d6d6d6
                                            border: 1px solid #AEE8F9
                                            &:hover
                                                background-color: white
                        .upload-container
                            @media (min-width: $xl)
                                margin-top: 1rem
                        .left-bottom
                            display: flex
                            align-items: center
                            .admin-user
                                margin-top: 0.4rem

                                .button-back
                                    background-color: white
                                    color: #8551B8
                                    padding-left: 0rem
                                    padding-right: 0rem
                                    min-width: 0rem
                                    margin: 0
                                    box-shadow: none
                                    span
                                        font-size: 3.5rem
                                        font-weight: bold
                                p
                                    font-weight: 500
                                    font-size: 2rem
                        .language-selector
                            width: fit-content
                            .dropdown-container
                                .custom-dropdown-menu
                                    padding: 0 0 0 10px
            .new-client-footer
                div.row
                    div
                        background-color: white
                        border-radius: 0 0 12px 12px
                        padding-bottom: 2rem
                        .button
                            background-color: #773db0
                            border: none
                            color: white
                            span
                                display: flex
                                justify-content: center
                                padding: .3rem
                                .material-symbols-rounded
                                    display: flex
                                    align-items: center
                                    color: white
                                span:not(.material-symbols-rounded)
                                    padding-top: .4rem
                            &.disabled
                                background-color: #d6d6d6
                                span
                                    color: #aaaaaa

            .form-footer
                background-color: white
                border-radius: 0 0 12px 12px
                div.row
                    div
                        button.button.secondary
                            background-color: #643097
                            color: white
                            span
                                display: flex
                                align-items: center
                                .material-symbols-rounded
                                    padding-right: 0.5rem
                                    color: white

    .logo-container
        font-family: Poppins
        margin: 40px 0
        border-radius: 12px
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05)
        background-color: #fff
        padding: 10px 32px
        display: flex
        justify-content: space-between
        flex-direction: column
        @media (min-width: $md)
            flex-direction: row
            align-items: center
        .wrapper-text
            padding-bottom: 12px
            text-align: center
            @media (min-width: $md)
                text-align: left
                flex: 1
                margin-right: 15px
            @media (min-width: $xl)
                padding-bottom: 22px
        .wrapper-file-upload
            padding-bottom: 22px
            @media (min-width: $md)
                padding-bottom: 0
                // padding-top: 18px
        .title-logo
            margin: 0
            margin-bottom: 10px
            // padding: 15px 0 0 32px
            font-weight: 600
            font-size: 17px
            @media (min-width: $lg)
                margin-bottom: 20px
                font-size: 24px
        .description-without-logo
            // padding: 15px 0 0 32px
            display: flex
            color: #888
            .icon-without-logo
                margin-right: 8px
                .material-symbols-rounded
                    font-size: 17px
                    @media (min-width: $lg)
                        font-size: 24px
            .text-without-logo
                font-size: 10px
                @media (min-width: $lg)
                    .logo-format
                        font-size: 12px
                .logo-without-file
                    font-size: 11px
                    font-weight: 500
                    @media (min-width: $lg)
                        font-size: 14px
        .description-with-logo
            display: flex
            align-items: center
            color: #888
            font-family: Poppins
            // padding: 5px 0 0 42px
            word-break: break-all
            @media (min-width: $lg)
                max-width: 70%
            .icon-with-logo
                margin-right: 8px
                // font-size: 20px !important
                // padding: 10px 15px 0 60px
                display: flex
                align-items: center
                color: $primary
            .logo-with-file
                font-size: 14px
                font-weight: 500
                // padding: 20px 0 0 12px
            .logo-preview
                margin: 0 12px 0 0
                width: 48px
                height: 48px
                backdrop-filter: blur(0.6rem)
                border-radius: 50%
                img
                    width: 48px
                    height: 48px
                    margin: 0
                    border-radius: 50%
                    object-fit: contain
            .text-with-logo
                // padding: 15px 0 0 0
            .logo-filename
                font-size: 12px
                font-weight: 600
                color: #2b2b2b
            .logo-detail
                font-size: 12px
            .delete-logo
                color: #cb3333
                // padding: 0 0 0 15px
                display: flex
                align-items: center
                img
                    height: 20px
            // @media screen and (min-width: 992px)
            //     min-width: 600px
        .upload-container
            // display: flex
            // justify-content: center
            // min-height: 130px
            // padding-right: 30%
            //transform: translate(0px, -4px)
            // @media (min-width: $xl)
            //     min-width: 600px
            //     padding-right: auto
            .fileUploader
                width: auto
            .fileContainer
                padding: 0
                margin: 0 auto
                box-shadow: none
                display: block
                // min-height: 125px
                .errorsContainer
                    display: none
                p
                    margin: 0
            button
                &.chooseFileButton
                    //background-color: $primary
                    max-width: 240px
                    border-radius: 12px
                    //padding: 12px 0
                    text-transform: uppercase
                    // font-weight: 600
                    font-size: 10px
                    margin: 0 auto
                    min-width: auto
                    @media (min-width: $lg)
                        // max-width: 240px
                        font-size: 14px
                    .material-symbols-rounded.icon-left
                        margin-right: 1rem
                .icon-left
                    font-weight: 400
                    font-size: 20px
                    margin-right: 8px
                .button-text-image-uploader
                    font-weight: 600
                &.button.primary.disabled
                    display: flex
                    justify-content: center
                    min-width: auto

        //     @media screen and (min-width: 1600px)
        //         min-width: auto
        //         justify-content: flex-end
        // @media screen and (min-width: 992px) and (max-width: 1600px)
        //     .row
        //         display: flex
        //         flex-direction: column
