@import '../../../styles/styles.scss';

.delete-job .modal-content {
  min-width: 100px;

  .modal-header {
    border: none;
  }
  .modal-body {
    text-align: center;
    padding: 10px;
  }
  .modal-footer {
    display: flex;
    flex-flow: row wrap;
    border: none;
    justify-content: center;
    padding-bottom: 35px;

    button {
      width: 250px;
      margin-left: 10px;
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
}
