@import '../../../styles/styles.scss';

.radio-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  button {
    min-width: 30px;
    min-height: 30px;
    border-radius: 30px;
    margin-right: 10px;
    background-color: white;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    .inner {
      background: $primary;
      width: 14px;
      height: 14px;
      border-radius: 15px;
    }
  }
  label {
    font-weight: 400;
    font-size: 15px;
    color: $text-regular;
    margin: 0;

    &.bold {
      font-weight: 500;
    }

    &.required:after {
      content: ' *';
      color: $warning;
    }
  }

  .input-label {
    color: $text-regular;
    font-size: 15px;
    font-weight: 500;
    &.required:after {
      content: ' *';
      color: $warning;
    }
  }
}
