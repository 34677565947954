@import '../../../styles/styles.scss'

.permission-label.input-label
    font-size: 11px
    @media (min-width: $xl)
        font-size: 16px
    font-weight: $font-bold
    span.required-icon
        font-size: 16px
        color: $danger
        margin-left: 2px
        font-weight: $font-bold
.multi-select-container
    display: flex
    flex-direction: column
    margin-top: 9px
    .multi-select-items
        margin-bottom: 18px
        @media (min-width: $lg)
            margin-bottom: 24px
        display: flex
        align-items: center
        label
            margin-left: 20px
            font-size: 11px
            @media (min-width: $xl)
                font-size: $font-lg
            font-weight: $font-semibold
            margin-bottom: 0
        input[type="checkbox"]
            appearance: none
            border-radius: 4px
            border: solid 2px #888888
            display: flex
            justify-content: center
            align-items: center
            // place-content: center
            width: 18px
            height: 18px
            @media (min-width: $xl)
                width: 24px
                height: 24px
            transform: translateY(-0.075em)
            margin-bottom: 2px
            &:hover
                cursor: pointer
        input[type="checkbox"]::before 
            content: ""
            width: 8px
            height: 8px
            @media (min-width: $xl)
                width: 12px
                height: 12px
            transform: scale(0)
            transition: 120ms transform ease-in-out
            box-shadow: inset 1em 1em $primary
        input[type="checkbox"]:focus
            outline: none
        input[type="checkbox"]:checked
            border-color: $primary
        input[type="checkbox"]:checked::before 
            transform: scale(1)
    .square-button
        display: flex
        flex-direction: row
        align-items: center
        padding: 0 0 18px 0
        label
            margin-left: 20px
            
        