@import '../../../styles/styles.scss'

.sidebar
  background-color: #d7f4fc
  @media (min-width: 1280px)
    padding: 10px 80px
  @media (min-width: $lg)
    padding: 16px 56px
  z-index: 1000
  border: 0
  .navbar-right
    // margin-right: -50px
    &.mobile-user
      @media (min-width: $md)
        display: block !important
      @media (min-width: 1280px)
        display: none !important
    @media (min-width: 1280px)
      padding: 7.5px 0
    @media (min-width: $xl)
      padding: 17.5px 0
  .sidebar-list
    margin: 0
    padding: 10px 0px
    @media (min-width: 1280px)
      margin-left: 50px
    @media (min-width: $xl)
      padding: 22px 0px
      // margin-left: 150px
    .navitem 
      border-radius: 12px
      margin: 0 10px
      @media (min-width: $lg)
        margin: 0 10px
      @media (min-width: $xl)
        margin: 0 15px
      &:first-child
        margin-left: 0
        margin-top: 0
      &:last-child
        margin-right: 0
        margin-bottom: 0
      &:hover:not(.open) , &.open > a
        box-shadow: none !important
      &:hover > a
        background-color: $lightblue-active
        border-radius: 12px
      &.active-navitem, &:hover:not(.open), &.open > a
        background-color: #ffffff
        border-radius: 12px
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15)
      &.open 
        box-shadow: none
        & > a
          box-shadow: none
          background-color: $lightblue-active
      a
        font-size: 11px
        @media (min-width: $xl)
          font-size: 16px
        display: flex
        padding: 10px 14px
        align-items: center
        font-weight: 500
        color: $dark
        .material-symbols-rounded
          font-size: 20px
          &:not(.expand)
            margin-right: 5px
        .newIndicator
          position: absolute
          top: -5px
          left: 70px
          @media (min-width: $fix1919)
            top: -15px
            left: 80px
      &.dropdown
        a
          position: relative
          .newIndicator
            position: absolute
            top: -12px
            right: -35px
            @media (min-width: $xl)
              top: -20px
              right: -50px
            span
              margin-right: 3px             
        &.open .dropdown-menu
          opacity: 1
          transition: all .3s linear
        .dropdown-menu
          border: none
          box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.05)
          background-color: #fff
          border-radius: 12px
          margin-top: 6px
          padding: 0
          opacity: 0
          transition: all .3s linear
          overflow: hidden
          .dropdown-active-navitem
            background-color: $lightblue-active
          .dropdown-navitem
            border-bottom: solid 1px $gray-light
            &:last-child
              border-bottom: 0
            a
              padding: 12px 30px
            &.dropdown-navitem
              a:hover
                background-color: $gray-light
            &:first-child:hover a
              border-top-left-radius: 12px
              border-top-right-radius: 12px
            &:last-child:hover a
              border-bottom-left-radius: 12px
              border-bottom-right-radius: 12px