@import '../../../styles/styles.scss'

.super-dashboard
  display: flex
.ClientScreenWrapper
  margin-bottom: 30px
  .table-tabs
    padding: 0
  .tab-content
    background-color: #fff
    .tab-pane
      .table
        .ReactTable
          .rt-table
            overflow: visible
            .rt-tbody
              overflow: auto
              
              .rt-tr-group
                // padding-top: 13px
                // padding-bottom: 33px
                flex: 0
                padding: 6px 5px
                .rt-tr
                  // cursor: pointer
                  padding: 5px 0
                  &:hover
                    background-color: $gray-lighter
                    border-radius: 12px
                    @media (max-width: $fix1919)
                      border-radius: 8px
                  .rt-td
                    &.user-td
                        padding: 0 2.8rem
                    span
                      overflow: hidden
                      text-overflow: ellipsis
                      white-space: nowrap
                    .dropdown-container
                      .dropdown
                        button
                          background-color: transparent
                        ul
                          // transform: translate3d(-43px, 45px, 0px) !important

  .widgets
    padding: 0
    margin-top: 6px
    @media (min-width: $lg)
      margin-top: 1.7rem
      padding: 0 12px
    .button
      min-width: auto
      width: 100%
      max-width: 177px
      padding: 6.5px 15px
      @media (min-width: $lg)
        // margin-top: 2rem
        max-width: 240px
        padding: 11px 15px
      .icon-left
        font-size: 14px
        @media (min-width: $fix1919)
          font-size: 20px
    .credits-card
      margin-top: 20px
      @media (max-width: $fix1919)
        margin-top: 2rem
      .col
        margin-bottom: 15px
        .credit-widget-container
          margin: 1.5rem 0 1.5rem 0
          .title-wrapper
            padding: 7px 0
            @media ( min-width: $xl)
              padding: 8px 0
            .title-container
              .material-symbols-rounded
                padding-bottom: 1px
          .credit-widget-body-container

            .value-container
              line-height: 1.1
              padding: 2px 0 9px 0
              @media (min-width: $xl)
                padding: 3px 0 5px 0
                &.actives, &.consumed
                  padding: 4px 0
            .description-container
              line-height: normal
              padding: 0px 0px 10px 0px
              @media (min-width: $xl)
                padding: 0 9px 18px
        &:last-child
          margin-bottom: 0
      // .total-credits
      //   .credit-widget-container
      //     .title-wrapper
      //       min-width: 0px
      //       width: 40%
      //       @media (max-width: $fix1919)
      //         width: 42%
      //       .title-container
      //         padding: 4.5px 0 4.5px 6px
      //         @media (min-width: $lg)
      //           padding: 10px 20px 10px 20px
      //         span
      //           margin: 0
      //           font-size: 15px
      //           @media (min-width: $lg)
      //             font-size: 28px
      //     .credit-widget-body-container
      //       font-size: 15px
      //       padding: 0 14px
      //       .label-container
      //         color: #d6d6d6
      //       .description-container
      //         color: #d6d6d6
      //     .credit-widget-body-container
      //       min-width: 0px
      //       width: 60%
      //       display: flex
      //       justify-content: center
      //       padding-bottom: 1px
      //       @media (max-width: $fix1919)
      //         width: 58%
      //         padding-top: 3px
      //       span
      //         margin: 0
      //         padding: 0 2px 0 2px
      //         @media (max-width: $fix1919)
      //           font-size: 11px
      .total-credits
        width: 100%
        display: flex
        align-items: center
        font-size: 11px
        font-weight: 500
        margin-inline: 0
        border-radius: 8px
        overflow: hidden
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
        line-height: normal
        background: white
        @media (min-width: $lg)
          border-radius: 12px
        .database
          padding: 6px 7px 7px 7px
          width: 82px
          background-color: $gray-light
          color: #404040
          font-size: 11px
          display: flex
          align-items: center
          justify-content: center
          @media (min-width: $lg)
            padding: 13px 14px 15px 14px
            width: 115px
            font-size: 15px
        .material-symbols-rounded
          font-size: 15px
          @media (min-width: $lg)
            font-size: 28px
        .totalcredits-text
          padding: 0px
          padding-left: 4px
          padding-bottom: 1px
          font-size: 11px
          width: 75%
          text-align: center
          @media (min-width: $lg)
            padding: 0 14px
            font-size: 15px
            margin-bottom: 1px
        .text
          color: $gray-light
        .number
          margin-inline: 4px
.table-tabs
  margin: 0
  padding: 0 15px
  .tab-content
    .tab-pane
      .table
        .ReacTable
          .rt-table
            .rt-body
              .rt-tr-group
                padding-top: 13px
                padding-bottom: 33px
                .rt-tr
                  cursor: none
                  &:hover
                    background-color: $gray-light
  &:last-child
    .tab-content
      .DMUser
        .table
          .ReactTable
            .rt-table
              .rt-thead
                .rt-tr
                  .rt-th
                    &.credits-assigned
                      @media (min-width: 1366px)
                        min-width: 145px
                    &:first-child
                      max-width: 15%
                      display: flex
                      justify-content: flex-start
                    &:nth-child(4)
                      width: 120px !important
                    .client-table-headers
                      &.DMUser-side
                        &:first-child
                          // padding: 3px

              .rt-tbody
                .rt-tr-group
                  .rt-tr
                    .rt-td
                      padding: 0
                      &:first-child
                        max-width: 15%
                        // padding: 2rem 0.5rem 1rem 0.5rem
                        text-align: flex-start
                        span
                          text-overflow: ellipsis
                          overflow: hidden
                        // display: block
                      &:nth-child(6)
                        padding: 0 2.8rem
                      
      .tab-pane
        // padding-top: 1rem
        .table
          .ReactTable
            .rt-thead
              border-bottom: 1px solid $dark
              .rt-tr
                text-align: flex-start
                .rt-th
                  border: none
                  &.credits-assigned, &.credits-active, &.credits-consumed,
                    div
                      // display: flex
                      // justify-content: center
                      .client-table-headers
                        padding: 5px 3px 5px 3px !important
                  
                  &.date-created
                    div
                      display: flex
                      justify-content: center
                  &.dropdown-options
                    max-width: 50px !important
                  div
                    .client-table-headers
                      width: 100%
                      .credits-header
                        display: flex
                        justify-content: center
                        align-items: center
                        text-align: center
                        padding-left: 5px
                      .client-user-header
                        display: flex
                        justify-content: center
                        align-items: center
                        padding-left: 8px
                      .client-account-header
                        display: flex
                        justify-content: flex-start
                        align-items: center
                        padding-left: 8px
                      .client-name-header
                        display: flex
                        justify-content: flex-start
                        align-items: center
                        padding-left: 8px
                      .client-id-header
                        display: flex
                        justify-content: flex-start
                        align-items: center
                      .date-header
                        display: flex
                        justify-content: center
                        align-items: center
                        text-align: center
                        padding-left: 8px
                      .client-type
                        display: flex
                        justify-content: center
                        align-items: center
                        text-align: center
                  &.client-id
                    max-width: 5rem
                    div
                      width: 100%
                      display: flex
                      align-items: center
                  &.client-type.-sort-asc, &.client-type.-sort-desc
                    div
                      display: flex
                      justify-content: center
                  &.-sort-asc, &.-sort-desc
                    box-shadow: none
                    font-weight: 700
                    .client-table-headers:not(.client-account-header)
                      background-color: #8ee7ff
                      border-radius: 12px
                      width: fit-content

                  div
                    width: 100%
                    .client-table-headers
                      &:first-child
                        padding: 0.5rem 0.5rem
                  &.transaction > div
                    display: flex
                    justify-content: flex-start

                .-sort-asc
                  div
                    .client-table-headers
                      span
                        span
                          transform: rotate(180deg)
                    // .client-name-header:not(.client-account-header)
                    //   span
                    //     transform: rotate(180deg)

            .rt-tbody
              .rt-tr-group
                .rt-tr
                  .rt-td
                    &.client-id
                      max-width: 5rem
                    display: flex
                    justify-content: flex-start
                    align-items: center
                    width: 100%
                    padding: 0rem 1rem
                    &:first-child:not(.account-name)
                      //max-width: 5rem
                      display: flex
                      justify-content: flex-start
                      align-items: center
                      padding: 0
                    .nombre-cliente
                      text-align: flex-start
                    .creditos-asignados
                      display: flex
                      justify-content: center
                      width: 100%
                      color: #1e7bb0

                    .creditos-activos
                      color: #ffbf60
                      display: flex
                      justify-content: center
                      width: 100%
                    .creditos-consumidos
                      color: #cb3333
                      display: flex
                      justify-content: center
                      width: 100%

                    .centered-values
                      display: flex
                      justify-content: flex-start
                    .fecha-creacion
                      display: flex
                      justify-content: center
                      width: 100%
                      padding-left: 20px
                    &.client-type
                      justify-content: center
                      text-align: left
                  .dropdown-options
                    overflow: visible
                    display: flex
                    justify-content: center
                    max-width: 50px !important
                    padding: 0
                    .dropdown-container
                      background-color: transparent
                      .dropdown
                        width: 100%
                        display: flex !important
                        justify-content: center
                        button:not(.text-link-button)
                          padding: 0
                          min-width: 0
                          width: 3rem
                          display: flex
                          justify-content: center
                          align-items: center
                          border: none
                          color: #000000
                          &:hover
                            background-color: transparent
                            color: #000000
                          label
                            padding-top: 1rem
                        .dropdown-menu
                          border-radius: 8px 0 8px 8px
                          left: -50px !important
                          padding: 0
                          margin: 0
                          min-width: 0
                          // height: 8rem
                          // width: 15rem
                          z-index: 9999
                          @media (max-width: $fix1919)
                            // height: 6rem
                            // width: 11rem
                            left: -5rem !important
                          li
                            display: flex
                            justify-content: flex-start
                            align-items: center
                            padding: 0
                            margin: 0
                            // height: 50%
                            &:hover
                              color: #000
                            .button
                              padding: 1rem
                              height: 100%
                              min-width: 100%
                              border-radius: 0
                              border: 0
                              justify-content: flex-start
                              .icon-left
                                display: flex
                                justify-content: center
                                align-items: center
                                margin: 0
                                padding: 0
                            .normal-button, .button
                              &:hover
                                color: #000
                                display: flex
                                justify-content: flex-start
                                align-items: center
                                margin: 0
                                background-color: transparent
                                padding: 1rem
                                border: none
                              span:not(.icon-left)
                                padding-left: 0.5rem
                            .delete-button
                              border: none
                              margin: 0
                              display: flex
                              justify-content: flex-start
                              align-items: center
                              color: #CB3333
                              padding: 1rem
                              background-color: transparent
                              &:hover
                                color: #CB3333
                              span:not(.icon-left)
                                padding-left: 0.5rem

        
      .header-left
        // margin-bottom: -4rem
        display: flex
        gap: 15px
        .input
          margin-bottom: 0
        .date-range-selector
          .filter-date
            .date-button
              .dropdown-menu-test
                .test
                  // border: 1px solid $gray-light
                  border-radius: 8px
                  padding: 10px 15px
                  width: 184px
                  max-height: 32px
                  // height: 4.23rem
                  @media (min-width: $lg)
                    border-radius: 12px
                    max-height: 45px
                    width: 264px
                  label
                    font-weight: 500
                    // padding-top: 0.2rem
                .date-button
                  span
                    font-size: 23px
              ul
                &:last-child
                  // .apply-date-button
                  //   position: absolute
                  //   top: 35rem

        .search-bar
          display: flex
          justify-content: flex-start
          position: relative
          z-index: 1
          // max-width: 28rem
          .search-icon
            position: absolute
            z-index: 1
            // bottom: 50%
            left: 18px
            font-weight: 500
            color: #888888
            flex-grow: 0
            @media (max-width: $fix1919)
              // bottom: 40%
              font-size: 16px
              padding-top: 2px
              font-weight: 500

          .input
            //margin-top: 1px
            .input-holder
              input
                border-color: #f6f6f6 
                max-height: 32px
                line-height: normal
                padding: 9px 15px 9px 40px
                border-radius: 8px
                @media (min-width: $lg)
                  max-height: 45px
                  padding: 10px 15px 10px 53px
                  font-weight: 500
                  border-radius: 12px
        .client-type-selector
          .dropdown-menu
            li
              border: none

  .header-right
    position: relative
    right: 40px
    top: 20px
    .add-client
      span
        display: flex
        align-items: center
    .filter-button-client
      position: absolute
      top: 8.3rem
      right: 0
      box-shadow: none
      color: #cb3333
      z-index: 1
      min-width: auto
      padding: 0
      margin: 0
      span
        text-transform: none
        font-weight: $font-semibold
      .icon-left
        font-size: 20px
      &.disabled
        opacity: 0

.clients-credits-history
  .credits-tabs-container
    width: 100%
    box-shadow: none
    max-width: 100%
    padding: 0
    .card-header
      border-bottom: 0rem
      padding: 0.5rem
      margin: 0
      .card-header-left
        width: 100%
      .card-header-right
        max-width: 100%
        padding-left: 31rem
        @media  (max-width: $fix1919)
          padding-left: 27rem
          top: 10.3rem
          right: 0.4rem
          display: flex
          align-items: center
          justify-content: flex-start
          padding-right: 5rem
          gap: 15px
        .filters-container
          display: flex
          margin-top: 0.1rem
          margin-left: 0.1rem
          @media ( min-width: $lg )
            margin-left: 4.1rem
        .dropdown-client
          display: flex
          margin-left: -1rem
          // gap: 1rem
          // padding-left: 1rem
          /* .clients-filter
            .dropdown-container
            .form-dropdown
            button
            min-width: 0
            max-width: 109.7px
            max-height: 37px
            background-color: 
            border-radius: 14px
            border: 1px solid #AEE8F9
            color: #888888
            &:hover
            background-color: white
            label
            line-height: 2.6rem */
          .clients-filter
            .dropdown-container
              margin-right: 15px
              max-width: 184px
              width: 184px
              .form-dropdown
                padding: 0
                button
                  display: flex
                  align-items: center
                  border-radius: 12px
                  border: 1px solid $gray-light
                  color: #7a7a7a
                  font-size: 11px
                  padding: 6.5px 15px
                  min-width: 184px
                  max-width: 184px
                  white-space: nowrap
                  border-radius: 8px
                  max-height: 32px
                  @media (min-width: $lg)
                    border-radius: 12px
                    max-height: 45px
                  label
                    margin: 0
                    font-weight: 500
                  .material-symbols-rounded
                    font-size: 30px
                    font-weight: 600
                  @media (min-width: $xl)
                    font-size: 16px
                  &:hover
                    background-color: white
                .custom-dropdown-menu
                  max-width: 100%
                  
                  li
                    padding-left: 1rem

        .filters-right
          display: flex
          button
            margin-right: 20rem
            color: white
          .filter-container
            display: flex
            .filter-button
              box-shadow: none
              min-width: 0rem

        button.button:not(.text-link-button)
          margin-right: 0rem
          box-shadow: none
          min-width: auto
          padding: 12.5px 15px
          // margin-right: 18rem
    .table
      .ReactTable
        .rt-table
          .rt-thead
            .rt-tr
              .-sort-asc
                div
                  span
                    .material-symbols-rounded
                      transform: rotate(180deg)
              .rt-th
                max-width: 100%
                height: 100%
                padding-left: 0.3rem
                &.credit-date-created
                  div
                    display: flex
                    justify-content: center !important
                &.credit-amount
                  div
                    display: flex
                    justify-content: center !important
                div
                  .credits-user
                    display: flex
                    justify-content: flex-start
                  .credit-branch
                    display: flex
                    justify-content: flex-start

                  span
                    display: flex
                    justify-content: center
                &.-sort-asc, &.-sort-desc

                  div
                    width: fit-content
                    height: 100%
                    display: flex
                    justify-content: center
                    .credit-account
                      display: flex
                      justify-content: flex-start
                      align-content: center
                      width: fit-content
                      padding: 0
                      span
                        display: flex
                        align-items: center
                        justify-content: flex-start
                        padding: 0.4rem
                        background-color: #8ee7ff
                        border-radius: 12px
                        max-width: max-content
                    .credit-branch
                      display: flex
                      justify-content: flex-start
                      align-content: center
                      width: 100%
                      span
                        display: flex
                        align-items: center
                        justify-content: flex-start
                        padding: 0.4rem
                        background-color: #8ee7ff
                        border-radius: 12px
                        max-width: max-content
                    
                    .credit-transaction-head
                      display: flex
                      justify-content: flex-start
                      align-content: center
                      width: 100%
                      span
                        display: flex
                        align-items: center
                        justify-content: flex-start
                        padding: 0.4rem
                        background-color: #8ee7ff
                        border-radius: 12px
                        max-width: max-content
                    .credit-amount-head
                      display: flex
                      justify-content: center
                      align-content: center
                      width: 100%
                      span
                        display: flex
                        align-items: center
                        justify-content: flex-start
                        padding: 0.4rem
                        background-color: #8ee7ff
                        border-radius: 12px
                        max-width: max-content
                    .credit-date-head
                      display: flex
                      justify-content: center
                      align-content: center
                      width: 100%
                      span
                        display: flex
                        align-items: center
                        justify-content: flex-start
                        padding: 0.4rem
                        background-color: #8ee7ff
                        border-radius: 12px
                        max-width: max-content
                    .credits-user
                      display: flex
                      justify-content: flex-start
                      align-content: center
                      width: 100%
                      span
                        display: flex
                        align-items: center
                        justify-content: flex-start
                        padding: 0.4rem
                        background-color: #8ee7ff
                        border-radius: 12px
                        max-width: max-content

                    span
                      display: flex
                      justify-content: center
                      align-content: center
                  &.transaction
                    display: flex
                    justify-content: start !important
                  &.credit-amount
                    display: flex
                    justify-content: center
                  &.credit-branch-header
                    display: flex
                    justify-content: start !important
                  &.credit-date-created
                    display: flex
                    justify-content: center
          .client-table-headers
            padding: 0.5rem 0.5rem
            align-items: center
            justify-content: flex-start
            display: flex
          .rt-tbody
            .rt-tr-group
              padding: 6.6px 0
              .rt-tr
                .rt-td
                  max-width: 100%
                  padding: 0
                  &:first-child
                    max-width: 100%
                    padding-left: 0.5rem
                  &.account-name
                    padding-left: 18px !important
                  &.credit-branch-cell
                    display: flex
                    justify-content: start !important
                    padding-left: 18px !important
                  &.credits-user-cell
                    padding-left: 24px
                    @media (min-width: 1367px)
                      padding-left: 18px
                  .transaction-name
                    margin: 0
                    padding-left: 10px
                    width: 100%
                    display: flex
                    justify-content: flex-start
                  .credit-name
                    margin: 0
                    width: 100%
                    display: flex
                    justify-content: center
                    padding-right: 10px
                  .credit-date
                    display: flex
                    justify-content: center
                    width: 100%
                    padding-right: 10px
            
.branch-credits-history
  .rt-thead
    .transaction
      display: flex
      justify-content: start !important
    .credit-date-created
      display: flex
      justify-content: center
  .rt-tbody
    .credit-name
      padding: 0 0 0 5px !important
    .credit-date
      padding: 0 15px 0 0 !important
    .transaction-name
      padding-left: 18px !important
    

.DMTable
  padding: 17px 0 0 0
  .credits-card
    .total-credits
      .credit-widget-container
        height: 40px
        .title-wrapper
          background-color: #d6d6d6
          min-height: 100%
          min-width: 0px
          width: 50%
          display: flex
          justify-content: center
          text-align: flex-start
          span
            font-size: 24px
            padding-left: 1rem
            width: fit-content
            @media (max-width: $fix1919)
              font-size: 16px
              padding-left: 0.5rem
          h3
            font-size: 15px
            @media (max-width: $fix1919)
              font-size: 11px

        .credit-widget-body-container
          display: flex
          justify-content: center
          text-align: center
          min-width: 0px
          @media (max-width: $fix1919)
            padding-top: 1px
          .label-container
            padding: 0
            margin: 0
            font-size: 15px
            @media (max-width: $fix1919)
              font-size: 11px
          .value-container
            padding: 0
            margin: 0
            font-size: 15px 
            @media (max-width: $fix1919)
              font-size: 11px
          .description-container
            padding: 0 5px 0 0
            margin: 0
            font-size: 15px
            @media (max-width: $fix1919)
              font-size: 11px

    div
      .credit-widget-container
        margin: 1.5rem 0 1.5rem 0
      .column-view
        max-width: 100%
        overflow: hidden
        .title-wrapper
          .title-container
            .title
              font-size: 14px
              @media (max-width: $xl)
                font-size: 11px
                .material-symbols-rounded
                  font-size: 35px
                  @media (max-width: $xl)
                    font-size: 15px
          .credit-widget-body-container
            background-color: white
            width: 100%
            display: flex
            align-items: center
            span
              font-size: 13px
    .row-view
      width: 100%
      margin: 0
      padding: 0
      .clients-screen
        justify-content: flex-start
        padding: 0
        font-size: 11px
        margin: 0
        .label-container
          font-size: 11px
          padding: 0
          .value-container
            font-size: 11px
          .description-container
            font-size: 11px
        .title-wrapper
          display: flex
          justify-content: center
          background-color: rgba(0,0,0,0.1)
          min-width: 40%
          width: 50%
          height: 4rem
          @media (min-width: $xl)
            height: 5rem
            .title-container
              padding: 0
              width: 70%
              span
                padding: 0
                margin: 0
                font-size: 15px
                @media (min-width: $xl)
                  font-size: 35px
                h3
                  font-size: 11px
                  @media (min-width: $xl)
                    font-size: 14px
        .credit-widget-body-container
          display: flex
          justify-content: space-evenly
          .label-container
            font-size: 11px
            width: fit-content
            @media (min-width: $xl)
              font-size: 14px
            .value-container
              font-size: 11px
              width: fit-content
              @media (min-width: $xl)
                font-size: 14px
            .description-container
              font-size: 11px
              width: fit-content
              @media (min-width: $xl)
                font-size: 14px
@media (max-width: $fix1919)
  .table-tabs
    &:last-child
      .nav-tabs
        li
          a
            font-size: 14px
      .tab-content
        .tab-pane
          .table
            .ReactTable
              .rt-thead
                .rt-tr
                  .rt-th
                    font-size: 11px
              .rt-tbody
                .rt-tr-group
                  .rt-tr
                    .rt-td
                      font-size: 11px
          .credits-history-container
            .credits-history
              .card-header
                padding: 0
                .card-header-right
                  top: 9.4rem
                  align-items: center
                  .filters-left
                    display: flex
                    .date-range-picker
                      .filter-date
                        .dropdown-container
                          .dropdown-menu-test
                            .date-button
                              height: 3.4rem
                            label
                              font-size: 11px

                    .dropdown-client
                      display: flex
                      .clients-filter
                        .dropdown-container
                          .dropdown
                            button
                              height: 3.4rem
                              label
                                font-size: 11px
              .table
                .ReactTable
                  .rt-table
                    .rt-thead
                      .rt-tr
                        padding: 0
                        .rt-th
                          display: flex
                          align-items: center
                          padding: 0
                          font-size: 11px
                          div
                            height: 4rem
                            display: flex
                            align-items: center
                            margin-bottom: 1rem
                            // justify-content: center

                            .credits-user
                              span
                                display: flex
                                align-items: center

                            .credit-branch
                              span
                                display: flex
                                align-items: center
                            .credit-accounts
                              span
                                display: flex
                                align-items: center

                            span
                              span
                                display: flex
                                align-items: center
                          &.transaction > div
                            display: flex
                            justify-content: flex-start
                          &:nth-child(4) > div
                            display: flex
                            justify-content: center
                          &.-sort-asc, &.-sort-desc

                            div
                              .credit-account
                                span
                                  display: flex
                                  align-items: center
                              .credit-branch
                                span
                                  display: flex
                                  align-items: center
                              .credit-transaction-head
                                span
                                  display: flex
                                  align-items: center
                              .credit-amount-head
                                span
                                  display: flex
                                  align-items: center
                              .credit-date-head
                                span
                                  display: flex
                                  align-items: center
                              .credits-user
                                span
                                  display: flex
                                  align-items: center

      .search-bar
        span
          position: absolute
          left: 18px
          top: 7px
          color: #7a7a7a
          font-weight: 600
          font-size: 16px
    .header-right
      .add-client
        span
          font-size: 10px
      .filter-button-client
        top: 7.3rem
        span
          font-size: 10px

  .DMTable
    padding: 24px 0 0 0
.ClientScreenWrapper
  .table-tabs .tab-content .tab-pane .credits-history-container .credits-history .table .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td
    &.account-name
      padding-right: 5px
      padding-left: 0
