@import '../../../styles/styles.scss';

.loader {
  margin: 20px 0;
}

.nwm-loader {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.nwm-loader div {
  position: absolute;
  width: 12px;
  height: 12px;
  background: $primary;
  border-radius: 25%;
  animation: nwm-loader 1.2s linear infinite;
}
.nwm-loader div:nth-child(1) {
  animation-delay: 0s;
  top: calc(37px * 120 / 100);
  left: calc(66px * 120 / 100);
  transform: rotate(90deg);
}
.nwm-loader div:nth-child(2) {
  animation-delay: -0.1s;
  top: calc(22px * 120 / 100);
  left: calc(62px * 120 / 100);
  transform: rotate(158deg);
}
.nwm-loader div:nth-child(3) {
  animation-delay: -0.2s;
  top: calc(11px * 120 / 100);
  left: calc(52px * 120 / 100);
  transform: rotate(27deg);
}
.nwm-loader div:nth-child(4) {
  animation-delay: -0.3s;
  top: calc(7px * 120 / 100);
  left: calc(37px * 120 / 100);
  transform: rotate(-2deg);
}
.nwm-loader div:nth-child(5) {
  animation-delay: -0.4s;
  top: calc(11px * 120 / 100);
  left: calc(22px * 120 / 100);
  transform: rotate(-32deg);
}
.nwm-loader div:nth-child(6) {
  animation-delay: -0.5s;
  top: calc(22px * 120 / 100);
  left: calc(11px * 120 / 100);
  transform: rotate(25deg);
}
.nwm-loader div:nth-child(7) {
  animation-delay: -0.6s;
  top: calc(37px * 120 / 100);
  left: calc(7px * 120 / 100);
  transform: rotate(1deg);
}
.nwm-loader div:nth-child(8) {
  animation-delay: -0.7s;
  top: calc(52px * 120 / 100);
  left: calc(11px * 120 / 100);
  transform: rotate(59deg);
}
.nwm-loader div:nth-child(9) {
  animation-delay: -0.8s;
  top: calc(62px * 120 / 100);
  left: calc(22px * 120 / 100);
  transform: rotate(26deg);
}
.nwm-loader div:nth-child(10) {
  animation-delay: -0.9s;
  top: calc(66px * 120 / 100);
  left: calc(37px * 120 / 100);
  transform: rotate(-3deg);
}
.nwm-loader div:nth-child(11) {
  animation-delay: -1s;
  top: calc(62px * 120 / 100);
  left: calc(52px * 120 / 100);
  transform: rotate(-31deg);
}
.nwm-loader div:nth-child(12) {
  animation-delay: -1.1s;
  top: calc(52px * 120 / 100);
  left: calc(62px * 120 / 100);
  transform: rotate(27deg);
}
// nwm-loader-mini
.nwm-loader-mini {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.nwm-loader-mini div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: $primary;
  border-radius: 25%;
  animation: nwm-loader 1.2s linear infinite;
}
.nwm-loader-mini div:nth-child(1) {
  animation-delay: 0s;
  top: calc(37px * 60 / 100);
  left: calc(66px * 60 / 100);
  transform: rotate(90deg);
}
.nwm-loader-mini div:nth-child(2) {
  animation-delay: -0.1s;
  top: calc(22px * 60 / 100);
  left: calc(62px * 60 / 100);
  transform: rotate(158deg);
}
.nwm-loader-mini div:nth-child(3) {
  animation-delay: -0.2s;
  top: calc(11px * 60 / 100);
  left: calc(52px * 60 / 100);
  transform: rotate(27deg);
}
.nwm-loader-mini div:nth-child(4) {
  animation-delay: -0.3s;
  top: calc(7px * 60 / 100);
  left: calc(37px * 60 / 100);
  transform: rotate(-2deg);
}
.nwm-loader-mini div:nth-child(5) {
  animation-delay: -0.4s;
  top: calc(11px * 60 / 100);
  left: calc(22px * 60 / 100);
  transform: rotate(-32deg);
}
.nwm-loader-mini div:nth-child(6) {
  animation-delay: -0.5s;
  top: calc(22px * 60 / 100);
  left: calc(11px * 60 / 100);
  transform: rotate(25deg);
}
.nwm-loader-mini div:nth-child(7) {
  animation-delay: -0.6s;
  top: calc(37px * 60 / 100);
  left: calc(7px * 60 / 100);
  transform: rotate(1deg);
}
.nwm-loader-mini div:nth-child(8) {
  animation-delay: -0.7s;
  top: calc(52px * 60 / 100);
  left: calc(11px * 60 / 100);
  transform: rotate(59deg);
}
.nwm-loader-mini div:nth-child(9) {
  animation-delay: -0.8s;
  top: calc(62px * 60 / 100);
  left: calc(22px * 60 / 100);
  transform: rotate(26deg);
}
.nwm-loader-mini div:nth-child(10) {
  animation-delay: -0.9s;
  top: calc(66px * 60 / 100);
  left: calc(37px * 60 / 100);
  transform: rotate(-3deg);
}
.nwm-loader-mini div:nth-child(11) {
  animation-delay: -1s;
  top: calc(62px * 60 / 100);
  left: calc(52px * 60 / 100);
  transform: rotate(-31deg);
}
.nwm-loader-mini div:nth-child(12) {
  animation-delay: -1.1s;
  top: calc(52px * 60 / 100);
  left: calc(62px * 60 / 100);
  transform: rotate(27deg);
}
@keyframes nwm-loader {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
