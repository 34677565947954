@import '../../../styles/styles.scss'

.login-screen
    max-width: 420px
    // width: 100%
    margin: 0
    display: flex
    justify-content: center
    flex-direction: column
    @media (min-width: $sm)
        min-width: 420px
    @media screen and (min-width: $lg)
        min-width: 586px
        margin: 0
        display: block
        .input .input-holder input
            width: 340px
        .input .input-holder button.show-password
            right: -90px
    .card
        border-radius: 16px
        padding: 20px 0px
        margin-bottom: 0
        @media (min-width: $xs)
            padding: 20px 30px
        @media (min-width: $xl)
            border-radius: 12px
        .wrapper-title
            border-bottom: 1px solid $gray-light
            color: $primary
            display: flex
            flex-direction: row
            align-items: center
            padding-bottom: 8px
            margin: 0 24px 30px 24px
            h2
                font-size: 13px
                font-weight: $font-bold
                margin: 0
            span
                font-size: 22px
                margin-right: 5px

            @media screen and (min-width: $md)
                margin: 0 0 30px 0
            @media screen and (min-width: $lg)
                margin: 0 0 40px 0
                padding-bottom: 13px
                h2
                    font-size: 20px
                span
                    font-size: 32px

        #login-form
            width: 100%
            margin: auto
            padding: 0 30px
            @media screen and (min-width: $xs)
                padding: 0 60px
            @media screen and (min-width: $lg)
                padding: 0 90px
            .form-body
                .forgot-password
                    display: flex
                    justify-content: flex-start
                    margin-bottom: 48px
                    .text-link-button
                        font-size: 8px
                        font-weight: 500
                        color: $primary
                        @media screen and (min-width: $lg)
                            font-size: 12px
                .error-message
                    font-size: 10px
                    @media (min-width: $xl)
                        font-size: 14px
                    &.text-warning
                        padding-bottom: 9px
                        margin: 0
                        color: #cb3333
                        font-weight: 500
                .input
                    .input-holder
                        max-width: none
                        font-size: 16px
                        font-weight: 400
                        .show-password
                            right: 16px
                            top: 3px
                            .material-symbols-filled
                                font-size: 14px
                            @media ( min-width: $lg )
                                right: 24px
                                top: 0
                                .material-symbols-filled
                                    font-size: 20px

            .form-footer
                text-align: center
                .button
                    margin-bottom: 20px
                    padding: 5px
                    min-width: 178px
                    @media screen and (min-width: $lg)
                        margin-bottom: 40px
                        padding: 12px
                        min-width: 240px
                    &.primary
                        border-radius: 8px
                        @media (min-width: $xl)
                            border-radius: 12px
                    span
                        font-size: 10px
                        font-weight: 600
                        @media screen and (min-width: $lg)
                            font-size: 14px

                    span.material-symbols-rounded.icon-left
                        font-size: 14px
                        font-weight: 600
                        margin-right: 10px
                .version
                    color: $gray-light
                    font-size: 11px
                    margin: 0
                    @media screen and (min-width: $lg)
                        font-size: 16px
                .disabled
                    .material-symbols-rounded
                        color: #aaaaaa
