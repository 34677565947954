@import '../../../styles/styles.scss'

.error-message-detail-campaign
    .snackbar
        .title
            h1
                margin-top: 16px

.delete-campaign
    &.modal-dialog
        display: flex
        justify-content: center
        .modal-content
            border-left: 2px solid #CB3333
            width: 392px
            height: 192px
            border-radius: 12px
            .modal-header
                border: 0
                display: flex
                .material-symbols-rounded
                    color: #CB3333
                h4
                    color: #CB3333
                img
                    display: none
            .modal-body
                padding: 0
            .modal-footer
                border: 0
                display: flex
                justify-content: flex-end
                .primary
                    border: none
                    background-color: #CB3333
                    min-width: 0
                    width: 129px
                    height: 45px
                    margin: 0
                    padding: 0
                .alternative-gray-modal
                    box-shadow: none
                    color: #773db0
                    min-width: 0
                    width: 129px
                    height: 45px
                    margin: 0
                    padding: 0
    .delete-all-modal
        display: flex
        justify-content: center
        .modal-content
            border-left: 2px solid #CB3333
            width: 392px
            height: 192px
            border-radius: 12px
            padding-bottom: 1rem
            .modal-header
                border: 0
                display: flex
                .material-symbols-rounded
                    color: #CB3333
                h4
                    color: #CB3333
                img
                    display: none
            .modal-body
                padding: 0 2rem 0 2rem
                p
                    text-align: left
            .modal-footer
                border: 0
                display: flex
                justify-content: flex-end
                padding: 0 2rem 0 0
                .primary
                    border: none
                    background-color: #CB3333
                    min-width: 0
                    width: 129px
                    height: 45px
                    margin: 0
                    padding: 0
                .alternative-gray-modal
                    box-shadow: none
                    color: #773db0
                    min-width: 0
                    width: 129px
                    height: 45px
                    margin: 0
                    padding: 0
.campaign-detail-screen
    .campaign-codes-count
        .campaign-codes-not-consumed
            height: calc(100vh - (260px - 54px))
            @media (min-width: $xl) 
                height: calc(100vh - (260px + 15px))
        .tab-content
            overflow-y: auto
            max-height: calc(100vh - (260px + 11px))
            @media (min-width: $xl) 
                max-height: calc(100vh - (260px + 85px))
    .row
        .campaign-main
            .tab-content
                overflow-x: hidden
            .nav-tabs
                li
                    a
                        font-size: 14px
                        padding-left: 22px
                        padding-right: 22px
                        line-height: normal
                        @media (min-width: $xl)
                            font-size: 20px
                            padding-left: 33px
                            padding-right: 33px
            .campaign-details
                .card-header
                    display: flex
                    align-items: center
                    //justify-content: space-between
                    border-bottom: 1px solid #f6f6f6
                    margin-bottom: 2rem
                    padding-bottom: 1rem
                    width: 100%
                    // &:hover
                    //     align-items: flex-start
                    //     .back
                    //         align-items: flex-start
                    //         button
                    //             margin: 0
                    //         .campaign-name
                    //             overflow: auto
                    //             white-space: normal
                    //             align-items: flex-start
                    .back
                        display: flex
                        justify-content: flex-start
                        overflow: hidden
                        align-items: center
                        flex: 1
                        /* @media (max-width: $fix1919)
                            flex-wrap: wrap */
                        .button
                            min-width: 0
                            padding: 0
                            display: flex
                            box-shadow: none
                            background-color: transparent
                            justify-content: center
                            .material-symbols-rounded
                                margin: 0
                                color: #773db0
                                background-color: transparent
                                font-weight: 600
                                font-size: 32px
                        .campaign-name
                            //width: 200px
                            position: relative
                            font-size: 16px
                            white-space: nowrap
                            overflow: hidden
                            text-overflow: ellipsis
                            margin: 0
                            flex: 1
                            @media (min-width: $xl)
                                font-size: 20px
                                //max-width: fit-content
                                //width: 380px
                                margin: 0
                                
                        .campaign-created
                            // padding-left: 1rem
                            font-size: 11px
                            color: #7a7a7a
                            font-weight: normal
                            display: flex
                            margin: 0
                        .loader
                            margin: 0
                            flex-grow: 0
                            

                    .dropdown-campaign-options
                        //padding-left: 1rem
                        .dropdown-container
                            display: flex
                            justify-content: center
                            align-items: center
                            .dropdown
                                button
                                    min-width: auto
                                    padding: 0
                                    //max-width: 3rem
                                    align-items: center
                                    label
                                        padding: 0
                                        margin: 0
                                        position: relative
                                        .material-symbols-rounded
                                            //margin-left: -1rem
                                            //width: 2.2rem
                                            font-size: 24px
                                            color: black

                                    &:hover
                                        background-color: transparent
                                        color: black
                                .dropdown-menu
                                    left: -30px !important
                                    // transform: translate3d(-15rem, 2rem, 0) !important
                                    // border-radius: 15px 0 15px 15px
                                    li
                                        padding: 0
                                        .button
                                            height: auto
                                            padding: 20px 15px
                                            text-align: flex-start
                                            border: none
                                            min-width: 100%
                                            display: flex
                                            justify-content: flex-start
                                            align-items: center
                                            // padding: 0
                                            margin: 0
                                            border-radius: 0
                                            background-color: transparent
                                            color: black
                                            &.disabled
                                                color: $gray-light
                                        &:nth-child(2)
                                            .button
                                                color: #cb3333
                                                &.disabled
                                                    color: $gray-light
                .campaign-info
                    .codes
                        .square-widget
                            margin: 0
                            .credit-widget-container
                                margin: 0
                                min-width: 100%
                        .lower-section
                            margin: 0 0 2rem 0
                            .credit-widget-container
                                margin: 2rem 0 2rem 0
                                width: 100%
                                .title-wrapper
                                    .title-container
                                        width: 100%
                                        display: flex
                                        justify-content: space-evenly
                                        align-items: center
                                        h3
                                            min-height: 100% !important
                                        span
                                            display: flex
                                            align-items: center
                                        .material-symbols-rounded
                                            font-size: 16px
                                            @media (min-width: $xl)
                                                font-size: 25px
                                                
                                
                                .credit-widget-body-container
                                    overflow: hidden
                                    text-overflow: ellipsis
                                    display: flex
                                    justify-content: center
                                    .label-container
                                        padding: 0
                                    .value-container
                                        font-weight: 400

                    .campaign-code-info
                        min-width: 0
                        .option-section
                            border-top: 1px solid #d6d6d6
                            padding-top: 4rem
                            p
                                font-size: 11px
                                font-weight: 600
                                @media (min-width: $xl)
                                    font-size: 16px
                            .option-fields
                                display: flex
                                flex-wrap: nowrap
                                flex-direction: column
                                width: 100%
                                .option-card-container
                                    display: flex
                                    flex-direction: row
                                    justify-content: space-between
                                    max-width: 100%
                                    overflow-x: auto
                                   
                                .option-card
                                    width: 100%
                                    margin: 0.21em
                                    .option-field-container
                                        text-align: center
                                        padding: 1rem
                                        margin: 0
                                        &.reference-job
                                            align-items: center
                                        &.eae-fields
                                            margin-top: 5px
                                        .title-wrapper
                                            border-bottom: 1px solid #d6d6d6
                                            .title-container
                                                h3
                                                    font-size: 10px
                                                    @media (min-width: $xl)
                                                        font-size: 12px
                                        .option-field-body-container
                                            font-weight: 700
                                            &.reference-job
                                                text-align: center
                                                width: 50%
                                                white-space: nowrap
                                                overflow: hidden
                                                text-overflow: ellipsis
                                                font-size: 16px
                                            span
                                                font-size: 11px
                                                font-weight: 700
                                                @media (min-width: $xl)
                                                    font-size: 16px
                                                
                                    &.position
                                        min-width: 100%
                                        .option-field-container
                                            display: flex
                                            .title-wrapper
                                                border-bottom: 0
                                                width: 45%
                                                display: flex
                                                justify-content: flex-start
                                                align-items: center
                                                border-right: 1px solid #d6d6d6
                                                color: #7a7a7a
                                                .title-container
                                                    max-width: 100%
                                                    height: 100%
                                                    display: flex
                                                    justify-content: center
                                                    align-items: center

                                                    h3
                                                        font-size: 14px
                                                        margin: 0
                                                .icon-container
                                                    max-width: 100%
                                                    padding: 0.2rem 1rem 0 1rem
                                    .auto-report
                                        min-width: 110px
                                        @media (max-width: $fix1919)
                                            width: fit-content
                                            max-width: fit-content
        .campaign-codes-count
            // padding-left: 0
            // padding-right: 0
            margin-top: 57px
            @media (min-width: $lg)
                margin-top: 82px
            
            .campaign-codes-consumed
                padding-left: 0
                
                div
                    // height: 60rem
                    // @media (min-width: $lg)
                    //     height: 59rem
                    
                    

                    .nav-tabs
                        li
                            width: 50%
                            text-align: center
                            a
                                padding: 24px 10px 24px 10px
                                margin-right: 0
                                p
                                    font-size: 11px
                                    font-weight: 400
                                    color: #7a7a7a
                                    margin: 0
                                    @media (min-width: $xl)
                                        font-size: 15px
                                    .greyed-quant
                                        color: grey
                                        font-weight: normal
                                        padding-left: 8px
                            &.active
                                a
                                    p
                                        color: black
                                        font-weight: 500

                    .tab-content
                        padding: 24px 15px
                        &.border-right
                            border-top-right-radius: 0px
                            border-top-left-radius: 8px
                            @media (min-width: $lg)
                                border-top-left-radius: 12px
                        &.border-left
                            border-top-right-radius: 8px
                            border-top-left-radius: 0px
                            @media (min-width: $lg)
                                border-top-right-radius: 12px
                        .tab-pane
                            .info-list
                                overflow: auto
                                list-style: none
                                margin: 0
                                padding: 0
                                li
                                    border-bottom: 1px solid #d6d6d6
                                    margin-bottom: 0.5rem
                                    padding-bottom: 0.5rem
                                    p
                                        font-size: 11px
                                        margin: 0
                                        text-overflow: ellipsis
                                        overflow: hidden
                                        padding: 0
                                        font-weight: normal
                                        @media (min-width: $xl)
                                            font-size: 14px
                                    span
                                        font-size: 9px
                                        font-weight: 600
                                        @media (min-width: $xl)
                                            font-size: 12px

            .campaign-codes-not-consumed
                padding-top: 0
                padding: 20px 20px
                border-radius: 12px
                display: flex
                flex-direction: column
                width: calc(50% - 15px)
                margin: 0 7.5px
                // height: 66.3rem
                // @media (min-width: $lg)
                //     height: 65.3rem
                // @media (min-width: $xl)
                //         height: 77rem
                .head-container
                    display: flex
                    justify-content: space-between
                    align-items: center
                    margin-bottom: 19px
                    // margin: 0 0 2rem 0
                    border-bottom: 2px solid #f6f6f6
                    padding: 15px 0
                    .not-title
                        font-size: 11px
                        font-weight: 500
                        margin: 0
                        color: #000
                        @media (min-width: $xl)
                            font-size: 15px
                    .greyed-quant
                        font-size: 11px
                        color: grey
                        font-weight: normal
                        padding-left: 8px
                        @media (min-width: $xl)
                            font-size: 14px
                .info-list
                    overflow: auto
                    list-style: none
                    flex: 1
                    
                    // margin: 0
                    padding: 0
                    li
                        border-bottom: 1px solid #d6d6d6
                        margin-bottom: 0.5rem
                        padding-bottom: 0.5rem
                        // justify-content: space-between
                        
                        .info-container
                            flex: 1
                            padding-right: 5px
                            // overflow: hidden
                            // width: 180px
                            // @media (min-width: $xl)
                            //     width: 209px
                            .email-container
                                overflow: hidden
                                flex: 1
                                display: flex
                                align-items: center
                                justify-content: space-between
                                p
                                    font-size: 11px
                                    text-overflow: ellipsis
                                    overflow: hidden
                                    padding: 0
                                    margin: 0
                                    @media (min-width: $fix1919)
                                        overflow: visible
                                        font-size: 14px
                                        text-overflow: ellipsis
                                        overflow: hidden
                                
                                .button
                                    // min-width: min-content
                                    // min-height: min-content
                                    // height: 1rem
                                    min-width: auto
                                    padding: 0 5px 0 0
                                    margin: 0
                                    color: #cb3333
                                    box-shadow: none
                                    &.disabled                                
                                        color: #aaa
                                        opacity: 1
                                    .material-symbols-rounded
                                        font-size: 18px
                                        font-weight: 400
                            span
                                    font-weight: 600
                                    margin: 0
                                    padding: 0
                                    font-size: 9px
                                    @media (min-width: $xl)
                                        font-size: 12px
                .delete-button
                    background-color: #cb3333
                    color: white
                    min-width: auto
                    margin-top: auto
        .campaign-code
            padding-left: 7.5px
            // margin-top: 73px
            @media (min-width: $lg)
                // margin-top: 82px
            @media (max-width: $fix1919)
                .Tooltip
                    button
                        .material-symbols-rounded
                            font-size: 20px
            .qr-code-card
                padding: 0px 28px 26px 31px
                border-radius: 12px
                .qr-title
                    padding: 21.5px 0
                    width: 100%
                    border-bottom: 1px solid #f6f6f6
                    margin-bottom: 1rem
                    display: flex
                    justify-content: space-between
                    align-items: center
                    font-weight: 500
                    p
                        font-size: 11pxs
                        margin: 0 0 5px 0
                        @media (min-width: $xl)
                            font-size: 15px

                    .Tooltip
                        .left
                            right: 0
                    .tooltip-container
                        div
                            position: relative
                            .associated-codes
                                position: absolute
                                left: -14rem
                                top: -1rem
                                max-width: 5rem

                .qr-body
                    display: flex
                    justify-content: center
                    @media (min-width: $lg)
                        flex-direction: row
                        display: flex
                        align-items: center
                        width: 100%
                        margin-top: 21px
                    .qr-code
                        max-width: max-content
                        display: flex
                        justify-content: center
                        border-right: 1px solid #d6d6d6
                        padding-right: 21px
                        @media (max-width: $fix1919)
                            padding-right: 14px
                        canvas
                            max-width: none !important
                            height: 90px !important
                            width: 90px !important
                            @media (min-width: $xl)
                                height: 150px !important
                                width: 150px !important
                            @media (max-width: $fix1919)
                                height: 60px !important
                                width: 60px !important

                    .qr-campaign-code
                        width: fit-content
                        font-size: 20px
                        padding: 0
                        @media (max-width: $fix1919)
                            font-size: 14px
                            padding-left: 7px
                            font-weight: 600
                            text-align: flex-start
                            margin-bottom: 15px
                        .qr
                            width: 100%
                            p
                                font-size: 11px
                                font-weight: 700
                                @media (min-width: $xl)
                                    font-size: 20px
                        @media (min-width: $lg)
                            margin-bottom: 0
                            padding: 0.5rem 0 0 0.5rem
                            margin-left: 0.5rem
                        .button
                            min-width: auto
                            max-width: auto
                            width: 126px
                            background-color: white
                            color: #773db0
                            border: 1px solid #773db0
                            @media (max-width: $fix1919)
                                width: auto
                            @media (min-width: $xl)
                                width: 177px
                            .icon-left
                                margin-right: 0
                            span
                                font-weight: 700
                                font-size: 10px
                                @media (min-width: $xl)
                                    font-size: 14px
            .campaign-code-generation
                border-radius: 12px
                form
                    .form-title
                        display: flex
                        justify-content: space-between
                        align-items: center
                        label
                            font-size: 11px
                            @media (min-width: $xl)
                                font-size: 15px
                        .Tooltip
                            .left
                                right: 0
                        .tooltip-container
                            div
                                position: relative
                                .associated-codes
                                    position: absolute
                                    left: -14rem
                                    top: -1rem
                                    max-width: 5rem
                    .top-half
                        border-top: 1px solid #f6f6f6
                        padding-top: 3rem
                        margin-top: 1rem
                        padding-bottom: 3rem
                        display: flex
                        align-items: center
                        justify-content: flex-start
                        width: 100%
                        .input-section
                            width: 100%
                            @media (min-width: $xl)
                                min-width: 270px
                                max-width: 270px
                            .input
                                margin: 0
                                .input-holder
                                    min-width: 100%
                                    input
                                        font-weight: 400

                        .upload-csv
                            width: 30%
                            display: flex
                            justify-content: space-between
                            align-items: center
                            .clear-massive
                                .button
                                    span
                                        margin: 0
                            .button
                                border: none
                                box-shadow: none
                                background-color: transparent
                                padding-right: 0
                                &.disabled-button
                                    pointer-events: none
                            .react-file-reader
                                .react-file-reader-button
                                    .button
                                        min-width: min-content
                                        // padding: 0.5rem
                                        margin: 0
                                        background-color: white
                                        box-shadow: none
                                        border: none
                                        .material-symbols-rounded
                                            margin: 0
                                            color: #773db0
                            .chart-button
                                padding-left: 1rem
                                .button
                                    min-width: min-content
                                    padding: 0.5rem
                                    margin: 0
                                    background-color: transparent
                                    box-shadow: none
                                    border: none
                                    .material-symbols-rounded
                                        margin: 0
                                        color: #773db0
                    .button
                        min-width: min-content
                        background-color: white
                        border: 1px solid #773db0
                        color: #773db0
                        .material-symbols-rounded
                            color: #773db0
                        &.disabled
                            border: 1px solid #d6d6d6

@media (max-width: $fix1919)
    .campaign-detail-screen
        .row
            .campaign-main
                padding-right: 7.5px
                .nav-tabs
                    li
                        a
                            font-size: 14px
                            padding-left: 22px
                            padding-right: 22px
                            line-height: normal
                .tab-content
                    .tab-pane
                        .campaign-details
                            .campaign-info
                                .campaign-code-info
                                    .option-section
                                        p
                                            font-size: 11px
                                        .option-fields
                                            .option-card
                                                .option-field-container

                                                    .title-wrapper
                                                        .title-container
                                                            h3
                                                                font-size: 10px
                                                .option-field-body-container
                                                    span
                                                        font-size: 11px

            .campaign-codes-count
                .campaign-codes-consumed
                    div
                        .nav-tabs
                            li
                                a
                                    p
                                        font-size: 11px
                        .tab-content
                            .tab-pane
                                .info-list
                                    overflow: auto
                                    // height: 101vh
                                    .list-item
                                        p
                                            font-size: 11px
                                            overflow: hidden
                                            text-overflow: ellipsis
                                        span
                                            font-size: 9px
                                    
                .campaign-codes-not-consumed
                    .head-container
                        .not-title
                            font-size: 11px
                        .greyed-quant
                            font-size: 11px
                    .info-list
                        overflow: auto
                        overflow-x: hidden
                        li
                            .info-container
                                // width: 180px
                                p
                                    width: 100%
                                    text-overflow: ellipsis
                                    overflow: hidden
                                    font-size: 11px
                                span
                                    font-size: 9px
            .campaign-code
                .qr-code-card
                    .qr-title
                        align-items: center
                        p
                            font-size: 11px
                            margin: 0 0 5px 0
                .campaign-code-generation
                    form
                        .form-title
                            label
                                font-size: 11px
