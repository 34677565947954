@import '../../../styles/styles.scss'

.wrapper-content
    .children-content
        .bapro-login
            position: absolute
            left: 500px
            width: 400px
            display: flex
            flex-direction: column
            align-items: center
            @media (min-width: $fix1919)
                position: absolute
                left: -90px
                width: 400px
            h4
                color: $primary
            .options-container
                display: flex
                flex-direction: column
                align-items: center
                justify-items: center
                gap: 20px
                margin: 5px
                .google-error
                    color: #cb3333
                    font-weight: 500
                    text-align: center
