@import '../../../styles/styles.scss'
.ie-container
    margin: auto 15px auto 24px
    flex: 1
    padding-inline: 32px
    padding-top: 12px
    padding-bottom: 24px
    border: 1px solid #AAAAAA
    border-radius: 12px
    background-color: #F6F6F6
    .ie-header
        display: flex
        width: 100%
        align-items: center
        border-bottom: 1px solid #AAAAAA
        padding: 0
        gap: 8px
        .material-symbols-filled
            font-size: 32px
            color: #B01E7E
        h3
            font-size: 20px
            font-weight: 500
            line-height: 1.06
            margin: 0
            color: #000
        .switch-container
            margin-left: auto
    p
        margin-inline: auto
        font-size: 16px
        font-weight: 500
        text-align: center
        margin-top: 24px
        color: #000

@media (max-width: $fix1919)
    .ie-container
        margin-left: 16px                            
        padding-inline: 20px
        padding-top: 8px
        padding-bottom: 16px
        border-radius: 8px
        gap: 5px
        .ie-header
            .material-symbols-filled
                font-size: 22px
            h3
                font-size: 14px
                line-height: 1.06
        p
            font-size: 11px
            margin-top: 16px