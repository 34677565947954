@import '../../../styles/styles.scss'

.modal
    display: flex !important
    justify-content: center
    align-items: center
    .modal-alert    
        height: fit-content
        width: fit-content
        &.delete
            .modal-content
                max-width: 430px
                width: 430px
                max-height: 215px
                border-radius: 12px
                box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15)
                border-left: solid 3px #cb3333
                background-color: #ffffff
                margin: auto
                .modal-header 
                    border: none
                    display: flex
                    justify-content: flex-start
                    h4
                        font-size: 24px
                        font-weight: 600
                        color: #cb3333
                        display: flex
                        align-items: center
                        span
                            font-size: 45px
                
                .modal-body
                    text-align: left
                    padding: 10px
                    padding-left: 18px
                
                .modal-footer 
                    display: flex
                    border: none
                    justify-content: right

                    button 
                        min-width: auto
                        padding: 12px 32px
                        margin: 0
                        &.primary
                            background-color: #cb3333
                            border: none
            
        
        
