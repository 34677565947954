.switch-container
    width: 44px
    height: 24px
    .switch
        margin-left: auto
        position: relative
        display: inline-block
        width: 44px
        height: 24px
        input
            display: none
        input:checked + .slider
            background-color: #773DB0
        input:disabled + .slider
            opacity: .65
            cursor: default
        input:checked + .slider:before
            -webkit-transform: translateX(19px)
            -ms-transform: translateX(19px)
            transform: translateX(19px)
        .slider
            position: absolute
            cursor: pointer
            top: 0
            left: 0
            right: 0
            bottom: 0
            background-color: #7A7A7A
            -webkit-transition: .4s
            transition: .4s
            border-radius: 24px
            &::before
                position: absolute
                content: ""
                height: 17px
                width: 17px
                left: 4px
                bottom: 4px
                background-color: white
                -webkit-transition: .4s
                transition: .4s
                border-radius: 50%