@import '../../../styles/styles.scss';

.credits {
  display: flex;
  flex-direction: column;

  .card-graph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .texts-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      div:nth-child(1) {
        margin-bottom: 20px;
      }

      div {
        p {
          font-size: 18px;
          margin-bottom: 5px;
          text-align: center;

          span.strong {
            font-size: 36px;
            font-weight: bold;
          }
        }
      }
    }

    svg {
      overflow: visible;
    }

    text.donutchart-innertext-value {
      color: $text-dark;
      font-family: $font-body;
      font-size: 48px;
      font-weight: bold;
      transform: translate(0px, -10px);
    }
  }
}

.donutchart-innertext-label {
  font-size: 0px; // !!!
}

.donutchart-innertext-value {
  font-size: 32px;
  font-weight: bold;
}

.donutchart-arcs-path,
.donutchart-legend-item-rect {
  opacity: 1 !important;
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  animation-name: DrawLine, FillIn;

  animation-duration: 3s, 1s;
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
}

@keyframes DrawLine {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes FillIn {
  from {
    fill-opacity: 0;
  }

  to {
    fill-opacity: 1;
  }
}

.selected.donutchart-legend-item-label,
.toggled.donutchart-legend-item-label {
  // fill: #f44336;
  text-decoration: underline;
}
