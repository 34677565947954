@import '../../../styles/styles.scss';

.dropdown-container {
  max-width: 100%;
  text-overflow: ellipsis;
  cursor: pointer;

  .input-label {
    background: white;
    border-radius: 30px;
    font-size: 11px;
    @media (min-width: $xl) {
      font-size: 16px;
    }
  }

  .dropdown {
    button {
      max-width: 100%;
      border: 1px solid $primary;
      text-align: left;
      min-width: 10rem;
      font-weight: bold;
      font-size: 15px;
      background: white;
      color: $primary;
      border-radius: 30px;
      margin-left: 0 !important;
      box-shadow: none !important;
      outline: none !important;
      padding: 4px 10px 0 20px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.loading {
        padding: 4px 10px 0 10px !important;
      }

      label {
        cursor: pointer;
        flex-shrink: 5;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.selected {
        border: 1px solid $input-border-color-selected;
      }
      &.no-border {
        border: none;
      }

      &.navbar-font {
        font-family: $font-body;
        font-weight: 500;
        color: $text-dark;
        font-size: 14px;
        justify-content: flex-end;

        &:hover {
          background: white;
          color: $primary;
        }
      }

      // actions
      &:hover {
        background: $primary;
        color: white;
      }

      &.multi {
        white-space: normal;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.gray {
        color: #919191;
        border: 1px solid #c5c5c5;
      }
    }

    .custom-dropdown-menu {
      position: absolute;
      z-index: 1000 !important;
      max-height: 200px;
      overflow: auto;
      border-radius: 15px;
      cursor: pointer;
      background-color: white !important;
      list-style: none;
      margin-top: 5px;
      padding: 0;
      width: 100%;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
      .no-items {
        border: 1px solid $gray-light;
      }

      li {
        padding: 5px 30px;
        color: $text-dark;
        font-size: 14px;
        font-weight: 300;

        &:hover {
          background-color: $gray-light;
        }

        &.selected {
          color: $primary;
        }

        &.placeholder {
          font-weight: 200;
          color: #000000;
        }
      }

      .filter-input {
        border: solid 1px $input-border-color;
        border-radius: 5px;
        padding: 0 20px;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }

    .dropdown-menu {
      max-height: 200px;
      overflow: auto;
      word-break: break-all;
      cursor: pointer;
      border: none;
      border-radius: 15px;
      margin-top: 5px;
      padding: 0;
      clip-path: inset(0px -10px -10px -10px);
      &.disabled {
        cursor: not-allowed;
      }

      li {
        color: $text-dark;
        font-size: 14px;
        font-weight: 300;
        padding: 6px 30px;

        &:hover {
          background-color: $gray-light;
        }

        &.selected::before {
          content: '• ';
          color: $primary;
        }

        &.placeholder {
          font-weight: 200;
          color: #000000;
        }
      }

      .filter-input {
        border-top: solid 1px $input-border-color;
        border-bottom: solid 1px $input-border-color;
        border-left: none;
        border-right: none;
        padding: 5px 20px;
      }
    }
  }
}

.test {
  height: 36.5px;
  max-width: 100%;
  border: 1px solid $primary;
  text-align: left;
  min-width: 10rem;
  font-weight: bold;
  font-size: 15px;
  background: white;
  color: $primary;
  border-radius: 30px;
  margin-left: 0 !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 4px 10px 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.loading {
    padding: 4px 10px 0 10px !important;
  }

  label {
    cursor: pointer;
    flex-shrink: 5;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.selected {
    border: 1px solid $input-border-color-selected;
  }
  &.no-border {
    border: none;
  }

  &.navbar-font {
    font-family: $font-body;
    font-weight: 500;
    color: $text-dark;
    font-size: 14px;
    justify-content: flex-end;

    &:hover {
      background: white;
      color: $primary;
    }
  }

  // actions
  &:hover {
    background: $primary;
    color: white;
  }

  &.multi {
    white-space: normal;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.gray {
    color: #919191;
    border: 1px solid #c5c5c5;
  }
}

.dropdown-menu-test {
  .custom-dropdown-menu {
    position: absolute;
    z-index: 1000 !important;
    max-height: 200px;
    overflow: auto;
    border-radius: 15px;
    cursor: pointer;
    background-color: white !important;
    list-style: none;
    margin-top: 5px;
    padding: 0;
    width: 100%;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);

    .no-items {
      border: 1px solid $gray-light;
    }

    li {
      padding: 5px 30px;
      color: $text-dark;
      font-size: 14px;
      font-weight: 300;

      &:hover {
        background-color: $gray-light;
      }

      &.selected {
        color: $primary;
      }

      &.placeholder {
        font-weight: 200;
        color: #000000;
      }
    }

    .filter-input {
      border: solid 1px $input-border-color;
      border-radius: 5px;
      padding: 0 20px;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .custom-dropdown-menu-components {
    width: 16pc;
    position: absolute;
    z-index: 1000 !important;
    max-height: 200px;
    border-radius: 15px;
    cursor: pointer;
    list-style: none;
    margin-top: -12px;
    padding: 0;
    width: 100%;

    .no-items {
      border: 1px solid $gray-light;
    }

    li {
      padding: 5px 30px;
      color: $text-dark;
      font-size: 14px;
      font-weight: 300;

      &:hover {
        background-color: $gray-light;
      }

      &.placeholder {
        font-weight: 200;
        color: #000000;
      }
    }
  }

  .dropdown-menu {
    max-height: 200px;
    overflow: auto;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    margin-top: 5px;
    padding: 0;

    &.disabled {
      cursor: not-allowed;
    }

    li {
      color: $text-dark;
      font-size: 14px;
      font-weight: 300;
      padding: 6px 30px;

      &:hover {
        background-color: $gray-light;
      }

      &.selected::before {
        content: '• ';
        color: $primary;
      }

      &.placeholder {
        font-weight: 200;
        color: #000000;
      }
    }

    .filter-input {
      border-top: solid 1px $input-border-color;
      border-bottom: solid 1px $input-border-color;
      border-left: none;
      border-right: none;
      padding: 5px 20px;
    }
  }
}

.ul-menu {
  width: 16pc !important;
  margin-top: 2pc !important;
}

.loading-date {
  height: 2pc;
}

.sub-item {
  margin-left: 1pc !important;
}
