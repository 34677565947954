@import '../../../styles/styles.scss'

.newIndicator
    display: flex
    justify-content: start
    align-items: center
    background-color: $yellow
    color: $light-yellow
    padding: 3px 6px
    position: absolute
    cursor: default
    z-index: 1
    border-radius: 4px
    width: 57px
    height: 20px
    @media (min-width: $xl)
        width: 83px
        height: 26px
    span
        font-size: 11px !important
        @media (min-width: $xl)
            font-size: 20px !important
    .text
        font-size: 8px
        margin: 0
        text-transform: uppercase
        font-weight: 700
        @media (min-width: $xl)
            font-size: 12px
            padding-top: 0px