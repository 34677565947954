@import '../../../styles/styles.scss'
.onBoarding
    .carousel-inner
        border-radius: 32px
        max-height: 661px
        @media ( max-width: $lg )
            max-width: 634px
        .item
            min-height: 550px
            @media ( max-width: $lg )
                min-height: auto
            .carousel-caption
                padding: 32px 32px 0
                max-height: 180px
                position: relative
                left: 0
                text-shadow: none
                text-align: left
                margin-bottom: 60px
                @media ( max-width: $lg )
                    padding: 0 24px
                    margin-bottom: 0
                    height: 120px
            .shadow
                position: absolute
                top: 0
                width: 892px
                height: 350px
                flex-grow: 0
                margin: 159px 0 0
                padding: 3.1px 10px 16.4px 188px
                opacity: 0.5
                background-blend-mode: multiply
                @media ( max-width: $lg )
                    margin: 0
                    max-width: 634px
            img
                border-top-left-radius: 32px
                border-top-right-radius: 32px
                @media ( max-width: $lg )
                    height: 355px
                   
            h3
                margin-top: 32px
                line-height: 1.06
                color: $dark
                font-size: 28px
                font-weight: $font-bold
                @media ( max-width: $lg )
                    font-size: 20px
            p
                margin-top: 22px
                font-size: $font-lg
                color: $dark
                @media ( max-width: $lg )
                    font-size: 12px