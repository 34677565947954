@import '../../../styles/styles.scss'

.UsersScreen
    .tab-content
        position: relative
        .card-header-right
            right: 15px
            position: absolute
            top: -45px
            @media (min-width: $xl)
                top: -53px
    .table
        .nav
            &.nav-tabs > li
                margin-bottom: -2px
        .tab-content
            .search-bar
                .left-side-search
                    .date-range-selector
                        .filter-date
                            .dropdown-container
                                margin-left: 16px
                                .dropdown-menu-test
                                    .date-button
                                        width: 184px
                                        padding: 2.5px 15px
                                        @media (min-width: $lg)
                                            padding: 9px 10px
                                            min-width: 264px
                                        label
                                            font-weight: 500
                                            font-size: 12px
                                            @media (min-width: $xl)
                                                font-size: 16px
                                        .material-symbols-rounded
                                            font-size: 25px
                                            font-weight: 500
                                            color: $dark
                                    .custom-dropdown-menu-components
                                        position: absolute
                                        z-index: 1
    .input-holder
        display: flex
        max-width: 305px
        input
            margin: 0
            border: 1px solid #f6f6f6
            padding: 6px 15px 6px 40px
            width: 100%
            line-height: normal
            max-width: 216px
            font-size: 12px
            @media (min-width: $lg)
                font-weight: 500
                line-height: normal
                height: 45px
                padding: 9px 15px 9px 53px
                font-size: 16px
                max-width: 304px
        .material-symbols-rounded
            position: absolute
            left: 18px
            //top: 7px
            color: #7a7a7a
            font-weight: 600
            font-size: 20px
            line-height: 32px
            @media (min-width: $lg)
                left: 24px
                //top: 10px
                line-height: 45px
                font-size: 30px
    .date-range-selector
        display: flex
        // margin-bottom: 25px
    .delete-button-container
        //margin-bottom: 25px
        .delete-button
            span
                text-transform: none
                font-weight: $font-semibold
        .button
            &.disabled
                display: none
    .ReactTable
        .rt-thead
            padding-left: 55px
            border-bottom: 1px solid $dark
            .rt-tr
                text-align: justify
                .rt-th
                    border: none
                    &.enabled
                        width: 50px !important
                    &.roles
                        margin-right: 15px
                    &.dropdown-options
                        width: 25px !important
                    &.-sort-asc, &.-sort-desc
                        box-shadow: none
                        .header-container
                            background-color: #8ee7ff
                            border-radius: 12px
                    .header-container
                        padding: 8px 12px
                        width: fit-content
                        display: flex
                        span:not(.material-symbols-rounded)
                            @media (max-width: $fix1919)
                                display: flex
                                align-items: center
                                font-size: 11px
                .centered-head-container
                    width: 100%
                    text-align: center
                    display: flex
                    justify-content: center
        .rt-tbody
            //min-height: 270px
            .rt-tr-group
                padding-left: 66px
                max-height: 80px
                .user-list
                    padding: 25px 0
                    &.disabled
                        opacity: 1
                        .rt-td
                            color: #a6a6a6
                .user-admin
                    padding: 22.5px 0
                .rt-tr
                    .rt-td
                        display: flex
                        align-items: center
                        padding: 0
                        .number
                            font-size: 14px
                            @media (max-width: $fix1919)
                                font-size: 11px
                        .created-date
                            font-size: 16px
                            @media (max-width: $fix1919)
                                font-size: 11px
                        &.full-name-cell
                            display: flex
                            flex-wrap: wrap
                            align-items: center
                            padding-left: 1px
                            span
                                text-align: flex-start
                                width: 100%
                            .admin-sub
                                display: flex
                                justify-content: flex-start
                                font-size: 11px
                                color: #7a7a7a

                                @media (max-width: $fix1919)
                                    font-size: 8px
                            .number
                                font-size: 16px
                                @media (max-width: $fix1919)
                                    font-size: 11px
                        &.user-email
                            padding-left: 5px
                        &.enabled
                            width: 50px !important
                        &.roles
                            margin-right: 15px
                        &.roles-admin
                            padding-left: 15px
                            @media (min-width: $xl)
                                padding-left: 17px
                        &.roles-account, &.roles-branch
                            padding-left: 10px
                            @media (min-width: $xl)
                                padding-left: 14px
                        &.dropdown-options
                            width: 25px !important
                    &.user-admin
                        .rt-td
                            .number
                                color: #608dec
                    .centered-cell-container
                        display: flex
                        justify-content: center
                .number
                    font-weight: $font-semibold
                    font-size: 15px
                    overflow: hidden
                    text-overflow: ellipsis
                    padding-right: 5px
                    text-align: start
                .rt-td:last-child
                    text-align: center
                    overflow: visible
                    .dropdown-container
                        button label
                            margin: 0
                            span
                                font-size: 17px
                                @media ( min-width: $lg)
                                    font-size: 24px
                        ul
                            padding: 0
                            border-radius: 8px
                            border-top-right-radius: 0
                            @media (min-width: $lg)
                                border-radius: 12px
                                border-top-right-radius: 0
                            li
                                padding: 12px 35px
                                &:hover
                                    background-color: $gray-lighter
                                    button
                                        background-color: transparent
                                &:last-child:hover
                                    border-bottom-left-radius: 8px
                                    border-bottom-right-radius: 8px
                                    @media (min-width: $lg)
                                        border-bottom-left-radius: 12px
                                        border-bottom-right-radius: 12px

                            button
                                border: 0
                                margin: 0
                                justify-content: flex-start
                                min-width: auto
                                width: 100%
                                &.delete-button
                                    color: $danger
                        button:hover
                            background-color: transparent
                            color: inherit
                            cursor: pointer
                        &.users-dropdown
                            // display: flex
                            // justify-content: center
                            width: 100%
                            img
                                display: none
                            .dropdown-menu
                                &.show
                                    // padding: 16px 0px
                                    left: -50px !important
                                    max-width: 254px
                                    min-width: auto
                                li
                                    padding: 0
                                    .button
                                        padding: 12px 35px
                                        @media (min-width: $lg)
                                            padding: 18.5px 42px
                                            display: flex
                                            align-items: center
                                        span
                                            text-transform: none
                                            font-size: 10px
                                            @media (min-width: $lg)
                                                font-size: 16px
                                        .material-symbols-rounded
                                            font-size: 15px
                                            @media (min-width: $lg)
                                                font-size: 20px
                        .dropdown > button
                            padding: 0
                            margin: 0
                            justify-content: flex-start
                            width: 100%
                            span
                                font-weight: $font-bold
                                color: $dark
        .pagination-bottom
            .paged-drawer
                &.table-pagination-container
                    border: none
                    .dropdown-container
                        .dropdown-menu
                            li
                                margin-left: 0
                    // padding-bottom: 35px
                    // padding-left: 39px
.UsersScreen
    .table
        .search-bar
            .input
                margin-bottom: 0
                width: 100%
                display: flex
                justify-content: space-between
            .left-side-search
                display: flex
