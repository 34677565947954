@import '../../../styles/styles.scss'
.external-login
    margin: auto
    max-width: 586px
    width: 100%
    @media (min-width: 585px)
        width: 586px
    left: 90px
    .card
        // margin: auto
        .wrapper-title
            border-bottom: 1px solid $gray-light
            color: $primary
            display: flex
            cursor: default
            flex-direction: row
            align-items: center
            padding-bottom: 13px
            margin-bottom: 30px
        .external-login-title
            font-size: 20px
            font-weight: $font-bold
            margin: 0
        .material-symbols-rounded
            font-size: 32px
            margin-right: 5px
            font-weight: bold
    .partner-logo
        width: 124px
        height: 124px
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
        border-radius: 50%
        object-fit: contain
    .body-form
        display: flex
        flex-direction: column
        align-items: center
        .partner-logo
            margin-bottom: 30px
        .user-login
            font-size: 14px
            font-weight: 500
            margin-top: 24px
            margin-bottom: 43px
            color: $primary
            text-decoration: none
        .button
            span
                &.material-symbols-filled
                    font-weight: 400
                    font-size: 20px