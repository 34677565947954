@import '../../../styles/styles.scss';

.input-autocomplete {
  display: flex;
  flex-direction: column;
  padding: 0;

  .input-label {
    color: $text-dark;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;

    &.required:after {
      content: ' *';
      color: $warning;
    }

    &.disabled {
      &.required:after {
        content: ' *';
      }
    }
  }

  .auto-options {
    padding: 0 !important;
    position: absolute;
    margin-top: 28px;
    min-width: 190px;
    background-color: white;
    border: 1px solid $input-border-color;
    border-radius: 6px;
    z-index: 99;
    overflow: visible;

    li {
      padding: 5px 20px !important;
      color: $text-dark;
      font-size: 14px;
      font-weight: 300;
      cursor: pointer;
    }
  }

  .error-message {
    display: none;
    margin-bottom: -20px;
  }

  .input-icon {
    border-radius: 6px;
    height: 28px;
    border: 1px solid $input-border-color;
    color: $text-dark;
    display: flex;

    img {
      min-width: 32px;
      height: 32px;
      align-self: center;
    }
  }

  input {
    flex-grow: 1;
    padding: 5px;
    border-radius: 6px !important;
    height: 32px;
    border: 1px solid $input-border-color;
    color: $text-dark;

    &:focus {
      outline: none;

      + .show-password {
        border-color: $light;
      }
    }

    &.border-error {
      border: 1px solid $warning;

      + .show-password {
        border-color: $warning;
      }
    }

    &.register-password {
      border-right: none;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .show-password {
      border: 1px solid $input-border-color;
      border-left: none;
      border-radius: 0 6px 6px 0;
      height: 48px;
      padding-right: 20px;
      color: $text-regular;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .horizontal-slider {
    background-color: $gray-light;
    border: none;
    border-radius: 6px;
    height: 6px;

    .thumb {
      margin-top: -2px;
      height: 10px;
      width: 25px;
      text-align: center;
      background-color: $gray-dark;
      border-radius: 10px;
      cursor: grab;
    }

    div[class='track'] {
      border: none;
      border-radius: 5px;
      height: 6px;
    }

    .track-0 {
      background-color: $primary;
      border-radius: 6px;
      height: 6px;
    }

    .track-1 {
      background-color: $gray-light;
      border-radius: 6px;
      height: 6px;
    }
  }

  > textarea {
    resize: none;
    border-radius: 6px;
    border: 1px solid $input-border-color;
    color: $text-dark;
    padding: 5px;

    &:focus,
    &:active {
      border: 1px solid $input-border-color;
      outline: none;
    }
  }

  &.disabled {
    label,
    input,
    textarea {
      color: $text-light;
    }
  }

  .input-label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $text-light;
  opacity: 1; /* Firefox */
}

.optional-text {
  color: $text-light;
  font-weight: 400;
  font-style: italic;
  display: none;
}

.forgot-text {
  color: $text-light;
  font-weight: 400;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

button {
  background: transparent;
  border: none;
  margin: 0;
}

button:focus {
  outline: none;
}
