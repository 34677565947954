@import '../../../styles/styles.scss';

.modal-content {
  min-width: 300px !important;
  .modal-header {
    display: flex;
    justify-content: space-between;
    modal-title: {
      margin: -moz-available;
    }

    .modal-button-onhide {
      margin: 0 0 0 auto;
      min-width: fit-content;
      color: $dark;
      background-color: none;
      box-shadow: none;
    }
  }
  .filter-modal-body {
    display: flex;
    flex-flow: column nowrap;
    padding: 30px 40px !important;

    form {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;

      .form-column {
        width: 100%;
        padding: 5px;
        padding-top: 0;
        padding-left: 0;

        .dropdown-container {
          max-width: 100%;
          max-height: 100px;
          .form-dropdown {
            button {
              &:hover {
                background-color: $primary;
                color: white;
                span {
                  color: white;
                }
              }
              label {
                font-size: 14px;
                margin-bottom: 5px;
              }
            }
          }
          .form-dropdown > button {
            max-width: 100%;
            border: 1px solid $primary;
            text-align: left;
            min-width: 10rem;
            font-weight: bold;
            font-size: 15px;
            background: white;
            color: $primary;
            border-radius: 30px;
            margin-left: 0 !important;
            box-shadow: none !important;
            outline: none !important;
            padding: 4px 10px 0px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            span {
              color: $primary;
              font-weight: normal;
              font-style: normal;
              font-size: 24px;
              line-height: 1;
              letter-spacing: normal;
              text-transform: none;
              display: inline-block;
              white-space: nowrap;
              word-wrap: normal;
              direction: ltr;
            }
          }
        }

        #profiles {
          .form-dropdown {
            button {
              label {
                font-size: 15px;
                margin-bottom: 5px;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            ul {
              li {
                font-size: 14px;
              }
            }
          }
        }

        .dropdown {
          button {
            width: 100%;
            label {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }

        .input .input-label-container .input-label {
          font-size: 14px;
          margin-top: 5px;
        }
      }

      .form-column:nth-child(1) {
        margin-right: 30px;
        width: 250px;
      }

      .form-column:nth-child(2) {
        width: 300px;

        //naw497 - if too tall, dropdown options overlap filter button
        .dropdown:nth-child(2) button label {
          -webkit-line-clamp: 2;
        }

        .age-title {
          padding: 0;
        }

        .age-row {
          display: flex;
          flex-flow: row nowrap;

          .dropdown-container:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 765px) {
  .modal-content .filter-modal-body form {
    flex-flow: row wrap;
  }
}

.inconsistent-profile-check {
  margin-left: 6px;
}
