@import '../../../styles/styles.scss';

.demographic-filter {
  .card {
    .card-map {
      .floating-button-wrapper {
        position: absolute;
        bottom: 5px;
        z-index: 999;
      }
    }
  }

  .demo-filter-list {
    display: flex;
    flex-direction: column;
    padding: 20px 25px 20px 0;

    li:last-child {
      border-bottom: none;
    }

    .demo-filter-item {
      display: flex;
      flex-grow: 1;
      padding: 10px 0 10px;
      border-bottom: solid 0.7px $input-border-color;
      justify-content: space-between;
      font-weight: 500;
      .distance {
        color: $input-border-color;
      }
    }
  }
}
