@import '../../../styles/styles.scss'

.main-persons-group-trends
    .main-container
        padding-inline: 40px
.compatibilities-and-jobs-container
    position: relative
    padding-top: 4px
    .nav-tabs
        li
            &:nth-child(1)
                // display: none
            a
                font-size: 14px
                padding: 19px 21px
                @media screen and (min-width: $xl)
                    font-size: 20px
                    padding: 25px 43px
    .tab-content
        // display: grid
        // grid-template-columns: 20% 4fr
        // @media screen and (min-width: $xl)
        //     grid-template-columns: 347px 4fr
        padding-right: 0
        padding-left: 17px
        @media (min-width: $xl)
            padding-left: 24px
        .tab-pane
            display: flex
            flex: 1
            position: relative
            &:before
                position: absolute
                content: ""
                right: 0
                top: 0
                width: 90px
                background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0) 100%)
                z-index: 999
                @media (min-width: $xl)
                    width: 130px
        .tab-variables-container
            // display: grid
            // grid-template-columns: 25% 75%
            // visibility: hidden
            // position: absolute
            &.active
                // visibility: visible
                // position: relative
            // @media screen and (min-width: $xl)
            //     grid-template-columns: 347px 100%
            .not-selected-view
                // height: 60vh
                // width: 36vw
                // margin-left: 10vw
                display: flex
                flex-direction: column
                text-align: center
                color: $gray-regular
                align-items: center
                justify-content: center
                width: 100%
                padding-inline: 90px
                .material-symbols-rounded
                    font-size: 35px
                    margin-bottom: 3%
                @media screen and (min-width: $xl)
                    .material-symbols-rounded
                        font-size: 50px
                        margin-bottom: 5px
                    p
                        font-size: 20px
                        margin-top: 5px
                p
                    .bold
                        font-weight: $font-bold
        .results
            display: flex
            flex: 1
            overflow-x: hidden
            animation: shakeX
            animation-duration: 2s
            padding-bottom: 5px
            padding-left: 5px
            scroll-behavior: smooth
            .result-column
                width: 200px
                min-width: 200px
                box-shadow: 0 1.4px 7.1px 0 rgba(0, 0, 0, 0.1)
                margin-right: 20px
                overflow: hidden
                border-radius: 12px
                display: flex
                flex-direction: column
                @media (min-width: $xl)
                    width: 280px
                    min-width: 280px
                    margin-right: 30px
                &:last-child
                    margin-right: 85px
                    @media (min-width: $xl)
                        margin-right: 170px
                    @media (min-width: 2500px)
                        margin-right: 180px
                .result-title
                    display: flex
                    justify-content: space-between
                    padding: 7px 18px
                    // height: 34px
                    @media (min-width: $xl)
                        padding: 10px 25px
                    background-color: $lightblue
                    border-top-right-radius: 12px
                    border-top-left-radius: 12px
                    cursor: pointer
                    .first-item
                        display: flex
                        font-weight: $font-bold
                        overflow: hidden
                        // width: 85%
                        .text
                            white-space: nowrap
                            text-overflow: ellipsis
                            display: block
                            width: auto
                            overflow: hidden
                            font-size: 13px
                            @media screen and (min-width: $xl)
                                font-size: 18px
                        .material-symbols-rounded
                            padding-right: 7px
                            transform: none
                            // transform: translate(-1px, -3px)
                        @media screen and (min-width: $xl)
                            font-size: 18px
                    .material-symbols-rounded
                        font-size: 20px
                        // transform: translate(-12px, -2px)
                        @media screen and (min-width: $xl)
                            font-size: 30px
                            margin-top: -2px
                .job-body
                    // max-height: 95%
                    overflow-y: scroll
                    display: flex
                    flex-direction: column
                .active
                    background-color: $lightblue-intense
                .person-card
                    padding-top: 10px
                    margin-top: 7px
                    position: relative
                    display: inline
                    border-bottom: 1px solid $gray-light
                    margin: 5px 10px
                    .compatibilities-progress-bar-container
                        padding-top: 10px
                        padding-bottom: 10px
                        @media (min-width: $xl)
                            padding-top: 15px
                            padding-bottom: 15px
                    .person-card-first-element
                        display: flex
                        justify-content: space-between
                        align-items: center
                        .material-symbols-rounded
                            font-size: 20px
                            color: $gray-regular
                            cursor: pointer
                            @media screen and (min-width: $xl)
                                font-size: 25px
                    .person-name
                        font-size: $font-sm
                        @media screen and (min-width: $xl)
                            font-size: $font-lg
                    .person-profile
                        font-size: $font-xxs
                        padding: 2px 0px
                        @media screen and (min-width: $xl)
                            font-size: $font-sm
                    .persons-progress-bar-container
                        margin: 0 13px 44px 2px
        .toolbox-column-container
            margin-right: 15px
            &.positions, &.skills
                margin-right: 30px 
            &:last-child
                margin-right: 0
            @media screen and (min-width: $xl)
                // max-width: 347px
            .toolbox-column-head
                // margin: 10px 5px 0px 16px
                font-weight: $font-semibold
                @media screen and (min-width: $xl)
                    font-size: $font-xl
                    // margin: 14px 5px 0px 18px
                .tooltip-container  
                    .material-symbols-rounded
                        @media screen and (min-width: $xl)
                            font-size: 30px
                            margin-top: -2px
                    .dropdown-menu
                        height: auto
                        margin: -141px -71px
                        transform: translate3d(-76px, -4px, 0px) !important
                        @media screen and (min-width: $xl)
                            margin: -176px -71px
                        &.tooltip-on-Jobs
                            transform: translate(-42%, 50%) !important
                        .dropdown-title
                            margin-bottom: -5px
                            padding: 0
                        .dropdown-body
                            padding: 0
                            font-size: $font-xs
                            font-weight: $font-regular
                            .font-bold
                                font-weight: $font-bold
                            @media screen and (min-width: $xl)
                                font-size: $font-sm
                .column-title
                    font-size: $font-md
                    @media screen and (min-width: $xl)
                        font-size: $font-xl
                .material-symbols-rounded.primary
                    color: $primary
                    font-variation-settings: 'FILL' 0
                .newIndicator
                    position: relative
                    
                    padding: 3px 6px
                    margin: 0 12px 0 0
                    @media screen and (min-width: $xl)
                        margin: 0 20px 0 0
            .toolbox-column-body
                .dropdowns
                    display: flex
                    min-width: 100%
                    padding: 0 5px
                    @media screen and (min-width: $xl)
                        flex-wrap: wrap
                    .dropdown-container
                        margin: 7px 0px 7px 0px
                        min-width: 50%
                        flex: 1
                        .form-dropdown
                            max-width: none
                            // height: 34px
                            margin: 0 5px
                            border: none
                            // padding: 0
                            button
                                //padding: 2px 3px
                                /* @media (min-width: $lg)
                                    padding: 1px 3px */
                                label
                                    color: #aaaaaa
                                    overflow: hidden
                                    text-overflow: ellipsis
                                    line-height: normal
                            &.selected
                                button
                                    label
                                        color: #000                                    
                                        white-space: nowrap
                                        text-overflow: ellipsis
                                        overflow: hidden
                            // @media screen and (min-width: $xl)
                                // width: 144px
                                // height: 52px
                            &.show
                                button
                                    // transform: translate(0px,6px)
                                    @media screen and (min-width: $xl)
                                        // transform: translate(0px, 13px)
                            label
                                //font-size: $font-sm
                                font-weight: $font-semibold
                                margin: 0
                                // padding-left: 5px
                                //@media screen and (min-width: $xl)
                                //    font-size: $font-lg
                            .material-symbols-rounded
                                font-size: $font-xl
                                color: $dark
                                @media screen and (min-width: $xl)
                                    font-size: 30px
                            .dropdown-menu
                                top: 32px !important
                                @media screen and (min-width: $xl)
                                    top: 35px !important
                                    clip-path: inset(0px -10px -10px -10px)
                                    border-top-left-radius: 0px
                                    border-top-right-radius: 0px
                                .search-bar
                                    margin: 3px 23px 3px 5px
                                    i
                                        margin: 0 2px
                                        width: 3vw
                                        @media screen and (min-width: $xl)
                                            margin: -2px 2px
                                li
                                    font-size: $font-sm 
                                    @media screen and (min-width: $xl)
                                        font-size: $font-md
                                .clean-filter
                                    font-size: 10px
                                    color: $danger
                                .input
                                    .input-holder
                                        input
                                            padding: 8px 15px
                .only-one
                    .dropdown-container
                        .form-dropdown
                            // min-width: 15.2vw
                            // @media screen and (min-width: $xl)
                            //     width: 303px
                            //button
                            //    padding: 0px 11px
                            &.show
                                .dropdown-menu.show
                                    // max-width: 220px
                                    @media screen and (min-width: $xl)
                                        // max-width: 303px

            .search-input
                position: relative
                width: 100%
                // padding: 0 10px
                // margin-top: 5px
                // margin-bottom: 10px
                // padding-bottom: 40px
                .search-icon
                    position: absolute
                    left: 15px
                    color: $gray-light
                    line-height: 32px
                    font-size: 20px
                    @media screen and (min-width: $lg)
                        line-height: 45px
                        font-size: 30px
                        // left: 28px
                        // top: 22px
                input
                    padding: 7.5px 15px 7.5px 40px
                    border-radius: 8px  
                    border: none
                    font-size: 11px
                    font-weight: 500
                    line-height: normal
                    width: 100%
                    @media (min-width: $lg)
                        border-radius: 12px  
                        font-size: 16px
                    &:focus-visible
                        outline: $active auto 1px
                    @media screen and (min-width: $lg)
                        padding: 10.5px 15px 10.5px 54px
                        font-size: $font-lg
                    &::placeholder
                        color: #aaaaaa
            
            .remove-selection
                font-size: $font-xs
                text-align: right
                // padding-right: 21px
                color: $danger
                cursor: pointer
                margin: 0
                font-weight: $font-semibold
                @media screen and (min-width: $xl)
                    font-size: 11px
                    .material-symbols-rounded
                        font-size: 11px
                        transform: translate(-3px, 3px)
                .material-symbols-rounded
                    font-size: $font-xs
                    transform: translate(-3px, 2px)
            .items
                // min-height: 65vh
                // max-height: 65vh
                // overflow: scroll
                // overflow-x: hidden
                // position: relative
                &.some-items-selected
                    // min-height: 62.5vh
                    // max-height: 62vh
                &.short-scroll
                    // max-height: 58vh
                    // min-height: 58vh
                    &.some-items-selected
                        // min-height: 55vh
                        // max-height: 55vh
                &.long-scroll
                    // min-height: 72.5vh
                    // max-height: 72.5vh
                    &.some-items-selected
                        // min-height: 70vh
                        // max-height: 69vh
                    .square-button
                        .item
                            width: auto
                            &.value-1
                                margin: 0px 5px
                                .title
                                    padding-top: 7.5px
                                    padding-bottom: 9.5px
                                    font-weight: $font-semibold
                                    @media (min-width: $xl)
                                        padding-top: 12px
                                        padding-bottom: 12px
                .square-button
                    position: relative
                    padding: 0
                    &::before
                        content: ""
                        position: absolute
                        left: 10%
                        bottom: 0
                        height: 1px
                        width: 80%
                        border-bottom: 1px solid $gray-light
                    input
                        margin: 0px 10px
                        min-width: 17px
                        max-width: 17px
                        height: 17px
                        @media screen and (min-width: $xl)
                            min-width: 24px
                            max-width: 24px
                            height: 24px
                    input[type="checkbox"]
                        // border-width: 1px
                        &::before
                            width: 9px
                            height: 9px
                            @media screen and (min-width: $xl)
                                width: 12px
                                height: 12px
                        @media screen and (min-width: $xl)
                            border-width:  2px 
                            // width: 12px
                            // height: 12px
                    .item
                        margin: 5px
                        width: 80%
                        cursor: default
                        .title
                            font-size: $font-sm
                            font-weight: $font-bold
                            @media screen and (min-width: $xl)
                                font-size: $font-lg
                        .sub-title
                            font-size: $font-xs
                            font-weight: $font-semibold
                            @media screen and (min-width: $xl)
                                font-size: 11px
                        .caption
                            font-size: $font-xxs
                            font-weight: $font-regular
                            white-space: nowrap
                            width: 80%
                            text-overflow: ellipsis
                            overflow: hidden
                            @media screen and (min-width: $xl)
                                font-size: 11px
            .bottom-text
                display: flex
                justify-content: space-between
                margin-top: auto
                padding: 0px 9px
            .selected
                font-size: 9px
                @media screen and (min-width: $xl)
                    font-size: $font-sm
            .select-all
                font-size: $font-xxs
                color: $primary
                cursor: pointer
                @media screen and (min-width: $xl)
                    font-size: $font-sm
    .header-right
        display: flex
        position: absolute
        top: 20px
        right: 15px
        align-items: center
        @media screen and (min-width: $xl)
            right: 20px
        button
            margin-right: 6px
            min-width: 11vw
            padding: none
            background-color: transparent

        .navigation-container
            display: flex
            align-items: center
            background-color: white
            box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.15)
            // height: 25px
            padding-right: 8px
            border-radius: 8px
            margin-left: 6px
            @media screen and (min-width: $xl)
                padding-right: 0px
            .material-symbols-rounded
                font-size: 24px
                @media (min-width: $xl)
                    font-size: 32px
                color: $primary
                padding-left: 3px
                padding-right: 3px
                width: 30%
                cursor: pointer
            .material-symbols-rounded:nth-child(2)
                position: relative
                border-right: 0.5px solid $gray-light
                width: 25%
            .material-symbols-rounded:nth-child(3)
                margin-right: -8px
        .disable
            .material-symbols-rounded
                color: $gray-light
                cursor: default

            
