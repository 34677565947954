@import '../../../styles/styles.scss'

.credit-widget-container
    line-height: 1.06
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1)
    width: initial
    border-radius: 8px
    display: flex
    flex-direction: column
    overflow: hidden
    align-items: center
    user-select: none
    background-color: #fff
    @media (min-width: $xl)
        border-radius: 12px
    &.column-view
        // min-width: 175px
        // max-width: 250px
    &.row-view
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        white-space: nowrap
        // margin: 0 25px
        @media (max-width: $md)
            flex-direction: column
            width: fit-content

    .title-wrapper
        padding: 7.5px 0
        width: 100%
        border-radius: 8px 8px 0 0
        @media (min-width: $xl)
            border-radius: 12px 12px 0 0
            padding: 8.5px 0
        &.row-view
            width: fit-content
            height: 100%
            border-radius: 9.5px 0px 0px 9.5px
            margin: 0
            padding: 4px 0
            min-width: 119px
            @media (max-width: $md)
                border-radius: 9.5px 9.5px 0 0
                width: 100%
            @media (min-width: $xl)
                min-width: 168px
                border-radius: 12px 0px 0px 12px
        &.total
            background-color: #d6d6d6
        &.available
            color: #1eb050
            background-color: #c4ffd8
        &.associated
            color: #1e7bb0
            background-color: #c4eaff
        &.consumed
            color: #cb3333
            background-color: #ffc4c4
        &.actives
            color: #ffbf60
            background-color: #fff2c4
        &.pending
            color: #ff9060
            background-color: #ffddc4
        &.campaign-available
            color: #b366ef
            background-color: #ecd4ff
        .title-container
            display: flex
            align-items: center
            justify-content: center
            &.row-view
                display: flex
                align-items: center
                justify-content: center
                width: fit-content
                margin: 0
                padding: 0.5px 11.5px 0.5px 12px
                @media (min-width: $xl)
                    width: 100%
                    padding: 2.5px 20px 2.5px 20px
                .material-symbols-rounded
                    font-size: 17px
                    margin-right: 5px
                    @media (min-width: $lg)
                        margin-right: 8px
                        font-size: 28px  
            .material-symbols-rounded
                font-size: 17px
                @media (min-width: $lg)
                    font-size: 28px
            .title
                font-size: 11px
                margin: 0
                @media (min-width: $xl)
                    font-size: 15px
    .credit-widget-body-container
        display: flex
        flex-direction: column
        align-items: center
        &.row-view
            flex-direction: row
            width: 100%
            margin: 0
        .label-container
            padding-top: 8px
            color: $gray-regular
            font-size: 9px
            margin: 0
            @media (min-width: 1500px)
                font-size: 11px
            @media (min-width: $xl)
                font-size: 12px
            &.row-view
                padding: 0px 0px 0px 37px
                color: $gray-regular
                font-weight: normal
                font-size: 9px
                @media (min-width: 1500px)
                    font-size: 11px
                @media (min-width: $xl)
                    font-size: 14px
        .value-container
            padding: 3px 0 8px 0
            font-size: 45px
            font-weight: 600
            @media (min-width: $xl)
                padding: 4px 0
                font-size: 64px
            &.row-view
                padding: 0 4px
                font-size: 9px
                font-weight: 600
                @media (min-width: 1500px)
                    font-size: 11px
                @media (min-width: $xl)
                    font-size: 14px
            &.available
                color: #1eb050
            &.associated
                color: #1e7bb0
            &.consumed
                color: #cb3333
            &.actives
                color: #ffbf60
            &.pending
                color: #ff9060
            &.campaign-available
                color: #b366ef
        .description-container
            font-size: 11px
            font-weight: 500
            padding: 0px 0px 10px 0px
            text-align: center
            @media (min-width: $xl)
                font-size: 15px
                padding: 0px 11px 24px
            /* &.column-view
                @media (min-width: $xl)
                    height: 60px */
            &.row-view
                display: flex
                align-items: center
                color: $gray-regular
                font-size: 9px
                padding: 0px 4px 0px 0px
                margin: 0
                font-weight: normal
                @media (min-width: 1500px)
                    font-size: 11px
                @media (min-width: $xl)
                    font-size: 14px
