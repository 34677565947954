@import '../../../styles/styles.scss'

.person-detail-container
    color: #000
    .tab-content
        padding: 24px
        height: auto
        .tab-pane
            overflow-y: auto
    button
        background: transparent
        border: none
        margin: 0
    .newIndicator
        position: absolute
        top: -10px
        right: -40px
    .first-col
        .candidate
            .card
                margin-bottom: 16px
                padding: 20px 24px
            .row
                &.title-candidate
                    display: flex
            .header-container
                display: flex
                width: 100%
                align-items: center
                border-bottom: 1px solid $gray-light
                .loader
                    max-width: 50px
                    margin: 0
                h3
                    margin: 0
                    font-size: $font-md
                    font-weight: 500
                    @media (min-width: $lg)
                        font-size: $font-xl
                .newIndicator
                    position: relative
                    margin-left: 9px

                button
                    color: $primary
                    font-weight: 700
                    font-size: 20px
                    margin-top: 3px
                    @media (min-width: $lg)
                        font-size: 30px
            .game-data
                .row
                    display: flex
                    .left-card
                        display: flex
                        flex-direction: column
                        h4
                            flex: 1
                            word-break: break-all
                &.card
                    margin-bottom: 12px
                    padding: 16px 12px
                    display: flex 
                    flex-direction: column
                h4
                    font-size: 28px
                    font-weight: 700
                    line-height: 1.12
                    text-align: left
                    margin-bottom: 20px
                    @media (min-width: $lg)
                        font-size: 40px                    
                .personal-data
                    font-size: 11px
                    margin-top: 6px
                    border-bottom: 1px solid $gray-light
                    padding-bottom: 6px
                    display: flex
                    align-items: center
                    &.eae-barcelona
                        color: #1e7bb0
                    @media (min-width: $lg)
                        font-size: $font-lg
                    .title
                        padding: 0
                        font-weight: bold
                        p
                            margin: 0 0 0 0
                            display: flex
                            align-items: center
                            span
                                margin: 0
                                padding: 0 9px 0 0
                                font-size: 20px
                    .description
                        width: 70%
                        max-width: 100%
                        padding-left: 40px
                        &.city
                            overflow: visible
                            text-overflow: ellipsis
                            white-space: nowrap
                        p
                            overflow: hidden
                            text-overflow: ellipsis
                            margin: 0
                    .content_copy
                        padding: 0
                        font-size: 20px
                        color: $primary
                    &.disabled
                        .title
                            color: gray-light
                        .description
                            color: gray-light
            .person-name
                display: flex
                justify-content: space-between
                align-items: center
                .name
                    h4
                        font-size: $font-xxl
                        font-weight: 700
            .campaign-button
                display: flex
                align-items: center
                border-radius: 8px
                padding: 5px 3px
                width: 100%
                margin-bottom: 6px
                @media (min-width: $fix1919)
                    margin-bottom: 8px
                &:last-child
                    margin-bottom: 0
                .arrow
                    margin-left: auto
                    color: $primary
                    transition: all .1s ease-in-out
                    font-size: 14px
                    @media (min-width: $xl)
                        font-size: 24px
                    &.open-timeline
                        transition: all .1s ease-in-out
                        transform: rotate(90deg)
                .icon
                    margin-right: 6px
                    font-size: 20px
                p
                    display: flex
                    align-items: center
                    margin: 0
                    text-align: left
                    width: 50%
                    color: #000
                    font-size: 10px
                    text-overflow: ellipsis
                    @media (min-width: $lg)
                        font-size: 14px
                    &.bold
                        color: #000
                        font-weight: 700
                    .campaign-name
                        white-space: nowrap
                        padding-left: 8px
                        // &:hover
                        //     overflow: visible
                        //     white-space: normal
                .separator
                    height: 20px
                    margin: 0 5px
                    width: 1px
                    background-color: $gray-light        
            .title-date
                border-bottom: 1px solid #d6d6d6
                font-size: 14px
                color: #7A7A7A
        .timeline
            white-space: nowrap
            position: relative
            width: 100%
            display: flex
            align-items: center
            justify-content: center
            height: 30px
            padding: 50px 0
            transition: all .1s ease-in-out
            ol
                font-size: 0
                width: 80%
                transition: all 1s
                height: 3px
                margin-top: 30px
                background-color: $light-yellow-intense
                display: flex
                justify-content: space-between
                align-items: center
                padding: 0
                li
                    position: relative
                    width: 12px
                    height: 12px
                    background-color: $light-yellow-intense
                    border-radius: 100%
                    div
                        time, p
                            font-size: 9px
                            @media ( min-width: $lg )
                                font-size: 12px
                        time
                            position: absolute
                            color: #000
                            left: 50%
                            transform: translateX(-60%)
                            bottom: -15px
                            @media ( min-width: $lg )
                                bottom: -25px
                        p
                            font-weight: 700
                            position: absolute
                            color: #000
                            min-width: 90px
                            text-align: center
                            white-space: normal
                            left: 50%
                            transform: translateX(-50%)
                            margin-top: -28px
                            display: flex
                            align-items: flex-end
                            justify-content: center
                            height: 25px
                            @media ( min-width: $xl )
                                min-width: auto
                                white-space: nowrap
        .one-result
            white-space: nowrap
            position: relative
            width: 100%
            display: flex
            align-items: center
            justify-content: center
            padding: 32px 0 0 0
            color: #7A7A7A
            .material-symbols-rounded, .material-symbols-filled
                font-size: 16px
                color: white
                margin: 5px
            .result-date
                p
                    margin-bottom: 0
                    font-weight: 700
            span                
                background: #1E6AB0
                border-radius: 100%
                height: 24px
                width: 24px
                display: flex
                align-items: center
                justify-content: center
            div
                display: flex
                flex-direction: column
                align-items: center
            ol
                margin-top: 30px
                padding: 0
                li
                    div
                        time
                            position: absolute
                            color: #000
                            font-size: 9px
                            left: 50%
                            transform: translateX(-60%)
                            bottom: -15px
                        p
                            font-weight: 700
                            position: absolute
                            color: #000
                            min-width: 90px
                            text-align: center
                            white-space: normal
                            font-size: 9px
                            left: 50%
                            transform: translateX(-50%)
                            margin-top: -28px
                            display: flex
                            align-items: flex-end
                            justify-content: center
                            height: 25px
                            @media ( min-width: $xl )
                                min-width: auto
                                white-space: nowrap
        .curriculum-container
            padding-top: 0
            padding-bottom: 0
            margin-bottom: 0
            .upload-file-container
                h3
                    margin-top: 0
                    font-weight: 700
                .message-file
                    display: flex
                    align-items: flex-end
                    span
                        font-size: 24px
    .second-col
        overflow-x: visible
        margin-top: 56px
        @media (min-width: $fix1919)
            margin-top: 81px
        .card
            overflow-x: visible
            border-radius: 12px
        .card-container
            display: flex
            .profile-description
                .header-profile-description
                    display: flex
                    flex-direction: row
                    align-items: center
                    gap: 8px
                    padding: 8px 0
                    border-bottom: 1px solid $gray-light
                    justify-content: space-between
                    .profile-indicator-container
                        display: flex
                        align-items: center
                        gap: 8px
                        .icon
                            color: #1E6AB0
                            font-size: 20px
                            @media (min-width: $fix1919)
                                font-size: 32px
                        .profile-indicator
                            margin: 0
                            font-size: 12px
                            @media (min-width: $fix1919)
                                font-size: 20px
                        h3
                            // padding: 0 0 3px 0
                            margin: 0
                            font-size: 12px
                            font-weight: 700
                            color: #000
                            @media (min-width: $lg)
                                font-size: 20px
                .buttons
                    display: flex
                    align-items: center
                    // padding-bottom: 10px
                    gap: 8px
                    .button
                        gap: 6px
                        min-width: auto
                        padding: 8px 14px
                        .icon-left
                            margin-right: 0
                        .material-symbols-rounded.icon-left.material-symbols-filled
                            -ms-transform: rotateY(180deg)
                            -webkit-transform: rotateY(180deg)
                            transform: rotateY(180deg)
                            font-weight: 600
                .tags-list
                    display: flex
                    flex-direction: row
                    color: #000
                    margin: 16px 0
                    flex-wrap: wrap
                    .tag-container
                        position: relative
                        .tag
                            font-size: 9px
                            padding: 3px 12px
                            margin-right: 7px
                            background-color: $lightblue
                            border-radius: 71px
                            cursor: pointer
                            @media (min-width: $lg)
                                font-size: $font-sm
                                padding: 4px 16px
                        .div-inactive
                            position: absolute
                            width: .1px
                            height: .1px
                            overflow: hidden
                            opacity: 0
                        .div-active
                            width: 220px
                            height: max-content
                            display: flex
                            flex-direction: row
                            justify-content: center
                            align-items: center
                            gap: 8px
                            font-size: 11px
                            font-weight: 500
                            background-color: #fff
                            position: absolute
                            left: 0
                            bottom: 9px
                            border-radius: 12px 12px 12px 0px
                            padding: 15px
                            // .description-active
                            //     flex-grow: 0
                            //     font-size: 11px
                            //     font-weight: 500
                            //     font-stretch: normal
                            //     font-style: normal
                            //     line-height: normal
                            //     letter-spacing: normal
                            //     text-align: left
                            //     color: #000
                            //     font-size: 11px
                            //     z-index: 100
                .tag-description
                    p
                        font-size: $font-sm
                        font-weight: 500
                        text-align: left
                        color: #000
                        @media (min-width: $lg)
                            font-size: $font-lg
        .timeline
            white-space: nowrap
            position: relative
            width: 100%
            display: flex
            align-items: center
            justify-content: center
            height: 30px
            padding: 50px 0
            transition: all .1s ease-in-out
            ol
                font-size: 0
                width: 80%
                transition: all 1s
                height: 3px
                margin-top: 30px
                background-color: $light-yellow-intense
                display: flex
                justify-content: space-between
                align-items: center
                padding: 0
                li
                    position: relative
                    width: 12px
                    height: 12px
                    background-color: $light-yellow-intense
                    border-radius: 100%
                    div
                        time
                            position: absolute
                            color: #000
                            font-size: 9px
                            left: 50%
                            transform: translateX(-60%)
                            bottom: -15px
                        p
                            font-weight: 700
                            position: absolute
                            color: #000
                            min-width: 90px
                            text-align: center
                            white-space: normal
                            font-size: 9px
                            left: 50%
                            transform: translateX(-50%)
                            margin-top: -28px
                            display: flex
                            align-items: flex-end
                            justify-content: center
                            height: 25px
                            @media ( min-width: $xl )
                                min-width: auto
                                white-space: nowrap
        .one-result
            white-space: nowrap
            position: relative
            width: 100%
            display: flex
            align-items: center
            justify-content: center
            padding: 32px 0 0 0
            color: #7A7A7A
            .material-symbols-rounded, .material-symbols-filled
                font-size: 16px
                color: white
                margin: 5px
            .result-date
                p
                    margin-bottom: 0
                    font-weight: 700
            span                
                background: #1E6AB0
                border-radius: 100%
                height: 24px
                width: 24px
                display: flex
                align-items: center
                justify-content: center
            div
                display: flex
                flex-direction: column
                align-items: center
            ol
                margin-top: 30px
                padding: 0
                li
                    div
                        time
                            position: absolute
                            color: #000
                            font-size: 9px
                            left: 50%
                            transform: translateX(-60%)
                            bottom: -15px
                        p
                            font-weight: 700
                            position: absolute
                            color: #000
                            min-width: 90px
                            text-align: center
                            white-space: normal
                            font-size: 9px
                            left: 50%
                            transform: translateX(-50%)
                            margin-top: -28px
                            display: flex
                            align-items: flex-end
                            justify-content: center
                            height: 25px
                            @media ( min-width: $xl )
                                min-width: auto
                                white-space: nowrap
        .curriculum-container
            padding-top: 0
            padding-bottom: 0
            margin-bottom: 0
    .buttons-container
        display: flex
        justify-content: flex-end
        position: absolute
        right: 0px
        padding: 10px 15px
        @media (min-width: $md)
            right: 56px
        @media (min-width: $lg)
            padding: 15px
        .text-link-button
            border: 1px solid $primary
        button.button 
            @media (min-width: $lg)
                span:not(.material-symbols-filled)
                    font-size: 16px
    .second-col
        overflow-x: visible
        margin-top: 56px
        @media (min-width: $fix1919)
            margin-top: 81px
        .card
            overflow-x: visible
            border-radius: 12px
            margin-bottom: 24px
            padding-top: 16px
            padding-inline: 24px
        .card-container
            display: flex
        .profile-description
            width: 100%
            .header-profile-description
                display: flex
                flex-direction: row
                align-items: center
                gap: 8px
                border-bottom: 1px solid $gray-light
                justify-content: space-between
                .profile-indicator-container
                    display: flex
                    align-items: center
                    gap: 8px
                    .icon
                        color: #1E6AB0
                        font-size: 20px
                        @media (min-width: $fix1919)
                            font-size: 32px
                    .profile-indicator
                        margin: 0
                        font-size: 12px
                        @media (min-width: $fix1919)
                            font-size: 20px
                    h3
                        // padding: 0 0 3px 0
                        margin: 0
                        font-size: 12px
                        font-weight: 700
                        color: #000
                        @media (min-width: $lg)
                            font-size: 20px
            // .buttons
            //     display: flex
            //     align-items: center
            //     padding-bottom: 10px
            //     gap: 8px
            .tags-list
                display: flex
                flex-direction: row
                color: #000
                margin: 16px 0
                flex-wrap: wrap
                .tag-container
                    position: relative
                    .tag
                        font-size: 9px
                        padding: 3px 12px
                        margin-right: 7px
                        background-color: $lightblue
                        border-radius: 71px
                        cursor: pointer
                        @media (min-width: $lg)
                            font-size: $font-sm
                            padding: 4px 16px
                    .div-inactive
                        position: absolute
                        width: .1px
                        height: .1px
                        overflow: hidden
                        opacity: 0
                    .div-active
                        width: 220px
                        height: max-content
                        display: flex
                        flex-direction: row
                        justify-content: center
                        align-items: center
                        gap: 8px
                        font-size: 11px
                        font-weight: 500
                        background-color: #fff
                        position: absolute
                        left: 0
                        bottom: 9px
                        border-radius: 12px 12px 12px 0px
                        padding: 15px
                        // .description-active
                        //     flex-grow: 0
                        //     font-size: 11px
                        //     font-weight: 500
                        //     font-stretch: normal
                        //     font-style: normal
                        //     line-height: normal
                        //     letter-spacing: normal
                        //     text-align: left
                        //     color: #000
                        //     font-size: 11px
                        //     z-index: 100
            .tag-description
                p
                    font-size: $font-sm
                    font-weight: 500
                    text-align: left
                    color: #000
                    @media (min-width: $lg)
                        font-size: $font-lg
        .container-ie
            .card
                padding-bottom: 0
                
    .notes-container
        overflow-x: visible
        overflow-y: auto
        display: flex
        flex: 1
        flex-direction: column
        max-width: 100%
        height: 100%
        .header-container
            display: flex
            width: 100%
            align-items: center
            border-bottom: 1px solid $gray-lighter
            padding-bottom: 14px
            padding-top: 15px
            padding-inline: 18px
            button
                span
                    display: flex
                    font-size: 24px
                    color: $primary
            h3
                margin: 0
                font-size: $font-md
                font-weight: 500
                padding: 0 0 0 5px
                @media (min-width: $lg)
                    font-size: $font-xl
        .name-container
            display: flex
            align-items: center
            justify-content: space-between
            padding-left: 20px
            h4
                font-size: 40px
                font-weight: 700
            .campaign-name-container
                display: flex
                align-items: center
                gap: 8px
                padding-inline: 12px
                span
                    font-size: 20px
                    color: #7a7a7a
                p
                    margin: 0
                    color: #7a7a7a
                label
                    font-weight: 700
                    margin: 0

        .notes
            overflow: hidden
            flex: 1
            padding-inline: 12px
            padding-top: 47px
            display: flex
            flex-direction: column
            padding-bottom: 10px
            @media (min-width: $lg)
                padding-top: 32px
                    // padding-inline: 50px
            .line
                height: 100%
                width: 2px
                background-color: $light-yellow-intense
                // margin: 10px 0
                margin-left: 6px
            .note-list
                overflow-y: scroll
                flex: 1
                padding: 0 30px 0 0
                @keyframes backInUp
                    0%
                        transform: translateY(1200px)
                        opacity: 0.7
                    80%
                        transform: translateY(0px)
                        opacity: 0.7
                    100%
                        opacity: 1
                @keyframes backInDown
                    0%
                        transform: translateY(-1200px)
                        opacity: 0.7
                    80%
                        transform: translateY(0px)
                        opacity: 0.7
                    100%
                        opacity: 1
                .animation-to-up
                    animation: backInUp .4s
                .animation-to-down
                    animation: backInDown .4s
                .note-card
                    &:last-child
                        .line
                            height: calc(100% - 10px)
                    margin-left: 62px
                    //width: 100%
                    position: relative
                    .card
                        padding: 0
                    .line
                        height: calc(100% + 30px)
                        width: 2px
                        background-color: $light-yellow-intense
                        margin: 10px 0
                        margin-bottom: 0
                        margin-left: 6px
                        position: absolute
                        left: -50.5px
                    &::before
                        content: ""
                        width: 13px
                        height: 13px
                        background-color: $light-yellow-intense
                        border-radius: 100%
                        position: absolute
                        top: 8px
                        left: -50px

                    &.card
                        padding: 0
                        border-radius: 8px
                        @media (min-width: $lg)
                            border-radius: 12px
                    .header
                        color: white
                        font-weight: 700
                        max-height: 32px
                        font-size: 10px
                        width: 100%
                        background-color: $light-yellow-intense
                        border-top-left-radius: 8px
                        border-top-right-radius: 8px
                        display: flex
                        align-items: center
                        justify-content: space-between
                        padding: 0 16px
                        @media (min-width: $lg)
                            border-top-left-radius: 12px
                            border-top-right-radius: 12px
                            font-size: $font-md
                        span
                            margin-right: 10px
                        .buttons
                            display: flex
                            align-items: center
                            margin: 0
                            padding: 0
                            gap: 5px
                            button
                                min-width: auto
                                width: auto
                                padding: 0
                                margin-top: 3px
                                margin-left: 3px
                            span
                                margin: 0
                                padding: 0
                    .description
                        padding: 11px 57px 17px 16px
                        font-size: 11px
                        border-bottom-left-radius: 8px
                        border-bottom-right-radius: 8px
                        overflow-wrap: break-word
                        @media (min-width: $lg)
                            border-bottom-left-radius: 12px
                            border-bottom-right-radius: 12px
                            font-size: $font-lg
                        &.featured
                            background-color: $light-yellow
                    .user-name
                        position: absolute
                        bottom: 5px
                        right: 16px
                        font-size: 10px
                        font-weight: 500
                        color: $gray-light
                        @media (min-width: $lg)
                            font-size: $font-md
                        &.featured
                            color: $light-yellow-intense
        .add-note
            //width: 90%
            //padding: 0
            padding-left: 20px
            padding-right: 15px
            @media ( min-width: $lg)
                padding-left: 20px
                padding-right: 15px
            textarea
                resize: none
                width: 100%
                margin: 0
                padding: 13px 22px 4px 22px
                border-top-left-radius: 8px
                border-top-right-radius: 8px
                border: 1px solid $gray-light
                border-bottom: none
                height: 80px
                margin-bottom: 0
                font-size: 11px
                @media (min-width: $lg)
                    font-size: $font-lg
                    border-top-left-radius: 12px
                    border-top-right-radius: 12px
                &::placeholder
                    font-size: 11px
                    font-weight: 400
                    color: $gray-light
                    @media (min-width: $lg)
                        font-size: $font-lg
                &:focus
                    outline: none
            .send
                display: flex
                margin: 0
                align-items: center
                justify-content: space-between
                padding: 15px 22px
                background-color: rgba(217, 217, 217, 0.23)
                color: #7a7a7a
                margin-top: -5px
                border: 1px solid $gray-light
                border-top: none
                border-bottom-left-radius: 8px
                border-bottom-right-radius: 8px
                @media (min-width: $lg)
                    border-bottom-left-radius: 12px
                    border-bottom-right-radius: 12px
                span
                    font-size: 10px
                    @media (min-width: $lg)
                        font-size: 12px
                    &.error-note
                        color: #cb3333
                button
                    &.button
                        width: auto
                        min-width: auto
                        box-shadow: 0 2.8px 10.7px 0 rgba(0, 0, 0, 0.05)
                        border-radius: 8px
                        padding: 4px 15px
                        .icon-left
                            font-size: 18px
