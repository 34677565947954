@import '../../../styles/styles.scss'
// // Variables
// $colorEffect: transparent
// @function hoverLabel($val)
// 	@if $val == 

.container-fluid.dashboard-screen
	.wrapper-first-row, .wrapper-second-row
		display: flex
		margin-bottom: 30px
		& > div > div
			height: 100%
	.credits-sumary
		.credits-summary-wrapper
			height: 100%
		.card
			height: auto
			padding: 0 22.5px
		.col-md-4
			padding: 0
		.credits-widget-container
			height: 100%
			padding-bottom: 25px
			.title-wrapper
				&.column-view
					padding: 4px 0px
					@media (min-width: $lg) 
						padding: 10px 0px
				.title-container
					&.column-view
						.material-symbols-rounded
							font-size: 20px
							@media (min-width: $lg)
								font-size: 32px
					&.row-view
						padding-top: 6px
						padding-bottom: 6px
						.material-symbols-rounded
							padding-right: 5px
							@media (min-width: $lg)
								padding-right: 8px
			.credit-widget-body-container
				&.row-view
					span
						font-size: 11px
						@media (min-width: $lg)
							font-size: 15px

			@media (max-width: $md)
				display: flex
				flex-direction: column
				align-items: center
			.title-dashboard-container
				display: flex
				align-items: center
				gap: 15px
				@media (min-width: $xl)
					align-items: flex-start
					padding: 18px 0 0 0
				padding: 16px 0 18px 0
				border-bottom: 1px solid #f6f6f6
				margin: 0 0 19px 0
				.credits-title
					font-size: 14px
					margin: 0
					font-weight: 500
					@media (min-width: $xl)
						font-size: 20px
						padding-bottom: 18px
			.credits-body
				.credits-card
					gap: 18px
				.credits-row
					padding-top: 18px
					padding-bottom: 16px
					.credit-widget-body-container
						display: flex
						justify-content: center
						.label-container
							padding: 0						
					.col-xs-12
						padding-bottom: 18px
						
			.buttons-container
				button.button
					width: 100%
					min-width: auto
					margin-bottom: 10px
				& > div:last-child
					button.button
						margin-bottom: 0
				.outlined
					border: 2px solid $primary
					// gap: 8px
					// margin: 0 0 14px 0

				.primary
					// gap: 8px

	.filters
		display: flex
		align-items: center
		flex-basis: 150px
		margin-top: -10px
		// > *
		// 	margin-bottom: 10px
		// 	display: flex
		// 	align-items: flex-end

		.dropdown-filter
			margin-right: 10px
			margin-left: 15px
			// flex-grow: 1
			// width: 25%
			// min-width: 150px
			// max-width: 350px

			.dropdown button label
				overflow: hidden
				text-overflow: ellipsis
				display: -webkit-box
				-webkit-line-clamp: 1
				-webkit-box-orient: vertical

		.partner-filter
			margin-right: 10px
			flex-grow: 0.5
			min-width: 100px

		.filter-container
			display: flex
			align-items: center
			// min-width: 230px

			button
				display: flex
				justify-content: flex-start
				span
					&:last-child
						text-transform: initial
	.dropdown-container
		.form-dropdown
			// min-width: 300px
			// height: 36.48px

		ul.dropdown-menu.show
			max-width: 100%
			border-top-left-radius: 0px
			margin-top: 0px
			min-width: auto
			clip-path: inset(0px -10px -10px -10px)
			border-top-right-radius: 0px
			@media (min-width: $xl)
				max-width: 184px
				margin-top: 0px
				transform: none
			li
				font-family: Poppins
				font-size: 11px
				font-weight: 500
				@media (min-width: $lg)
					font-size: 16px
	.apexcharts-canvas
		.apexcharts-bar-area:hover
			fill: #50b5d0
		.apexcharts-datalabels
			.apexcharts-data-labels
				.apexcharts-datalabel:hover
					fill: #50b5d0
			

		

	.apexcharts-legend.position-bottom.center,
	.apexcharts-legend.position-top.center
		display: none

	.container-fluid.dashboard-screen
		.donutchart-arcs-path
			border-radius: 6px

	.total-number
		font-weight: 700
		font-size: 20px
		padding-bottom: 20px

	.map-city
		display: flex
		justify-content: space-between
		flex-grow: 1
		border-bottom: solid 0.5px lightgray
		padding-bottom: 5px
		margin-bottom: 5px
		max-height: 250px

		.city-name
			font-weight: 500
			font-size: 14px

		.city-number
			color: $text-light

	#mymap
		height: 220px
		border-radius: 10px
		z-index: 997

	.doughnut-chart
		height: auto
		display: flex
		flex-direction: row
		flex-grow: 1
		justify-content: center
		min-height: 250px

		text.donutchart-innertext-value
			font-size: 35px
			color: $text-dark
			font-family: $font-body
			font-weight: bold
			transform: translate(0px, -20px)
			@media (min-width: $xl)
				transform: translate(0px, -15px)
				font-size: 50px
	.donutchart-arcs-path,
	.donutchart-legend-item-rect,
	.donutchart-legend-item-label
		cursor: pointer

	.donutchart-innertext-label
		font-size: 18px
		font-weight: 500
		transform: translate(0px, -20px)
		@media (min-width: $xl)
			font-size: 14px

	.donutchart-innertext-value
		font-size: 50px
		font-weight: bold
		transform: translate(0px, 20px)

	.donutchart-innertext-total
		font-family: "Poppins", sans-serif
		font-size: 18px
		font-weight: 500
		transform: translate(0px, 70px)
		@media (min-width: $xl)
			font-size: 14px

	.donutchart-arcs-path,
	.donutchart-legend-item-rect
		fill-opacity: 0
		animation-timing-function: ease-in-out
		animation-fill-mode: forwards

		animation-name: DrawLine, FillIn

		stroke-dasharray: 1500
		stroke-dashoffset: 1500

	.no-scroll-y
		overflow-y: hidden !important

	.no-wrap
		white-space: nowrap

	.dashboard-progress-bar-container
		display: flex
		flex-flow: column nowrap
		align-items: center

		.text-header
			color: #344563
			font-family: Poppins
			font-size: 14px
			font-weight: 500
			line-height: 1.06
			&.maxValue
				color: #50b5d0

		.progress-bar
			background-color: #f6f6f6
			border-radius: 6px
			height: 25px

		// se pasaron estos estilos a la hoja de ProgressBar.sass
		// .bar
		// 	background-color: #344563
		// 	height: 25px
		// 	border-radius: 6px
		// 	&.maxValue
		// 		background-color: #50b5d0

	.card
		border-radius: 8px
		margin-bottom: 0
		// height: 100%
		@media (min-width: $xl)
			border-radius: 12px
		.card-header
			border-bottom: solid 1px $gray-light
	
	.age-distribution
		.apexcharts-canvas
			.apexcharts-bar-area
				fill: #344563
			.apexcharts-bar-area:hover
				fill: #50b5d0
			.apexcharts-datalabels
				.apexcharts-data-labels
					.apexcharts-datalabel:hover
						fill: #50b5d0
		.chart-header:not(.right)
			p
				font-size: 14px
				font-weight: 500
				@media (min-width: $xl)
					font-size: 20px
		.chart-header.right
			p
				font-size: 10px
				font-weight: 500
				@media (min-width: $xl)
					font-size: 14px
	.demographic-dist
		.distribution-content
			.leaflet-container
				min-height: 347px
		.chart-header:not(.right)
			p
				font-size: 14px
				font-weight: 500
				@media (min-width: $xl)
					font-size: 20px
		.chart-header.right
			p
				font-size: 10px
				font-weight: 500
				@media (min-width: $xl)
					font-size: 14px
	.profiles-chart
		.chart-header:not(.right)
			p
				font-size: 14px
				font-weight: 500
				@media (min-width: $xl)
					font-size: 20px
		.chart-header.right
			p
				font-size: 10px
				font-weight: 500
				@media (min-width: $xl)
					font-size: 14px
	.profiles-categories
		.chart-header:not(.right)
			p
				font-size: 14px
				font-weight: 500
				@media (min-width: $xl)
					font-size: 20px
		.chart-header.right
			p
				font-size: 10px
				font-weight: 500
				@media (min-width: $xl)
					font-size: 14px
@media screen and (min-width: 1000px)
	.apply-date-button
		margin-left: 16.5rem !important
	.toggle
		display: inline-flex

@media screen and (max-width: 1000px)
	.chart-header > p
		white-space: normal !important

.filter-right
	display: flex
	margin-top: 10px
	margin-bottom: 20px
	flex-wrap: wrap
	.dropdown-container
		.dropdown-menu-test
			button.date-button
				height: auto
				label
					font-weight: 500
				&.active
					label
						color: black
		.form-dropdown
			&.show
				border-bottom: none
			&.selected
				button
					label
						color: black	
			button
				min-width: 131px
				@media (min-width: $xl)
					min-width: 184px
				label
					font-weight: 500
					color: #aaaaaa
	

.date-filter-dashboard
	// margin-top: 10px
	// margin-bottom: 3px
	.filter-date
		.dropdown-container
			.dropdown-menu-test
				.date-button
					@media (min-width: $xl)
						min-width: max-content

.dashboard-screen .filters
	display: flex
	align-items: flex-start
	flex-wrap: wrap
	.filter-container
		.square-button
			@media (min-width: $xl)
				padding: 0

