@import '../../../styles/styles.scss'

.modal-share
    height: fit-content
    width: fit-content
    .modal-content
        border-radius: 12px
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15)
        border-left: solid 5px $primary
        background-color: #ffffff
        width: 587px
        .modal-header
            border: none
            display: flex
            align-items: center
            height: 70px
            padding: 24px 40px
            .header-container
                display: flex
                flex: 1
                align-items: center
                border-bottom: 1px solid #f6f6f6
                h4
                    font-size: 20px
                    font-weight: 600
                    color: $primary
                    display: flex
                    align-items: center
                    flex: 1
                    span
                        font-size: 32px
                        padding: 0 8px 0 0
                .tooltip-container
                    margin-left: auto
        .modal-body
            text-align: left
            padding: 24px 40px
            h5
                font-size: 16px
                font-weight: 700
            .email-input-container
                max-height: 150px
                overflow-y: auto
                width: 515px
                margin-bottom: 30px
                margin-top: 16px
                .email-input
                    width: 100%
                    max-width: 500px
                    min-height: 40px
                    border: 1px solid #d6d6d6
                    border-radius: 8px
                    padding: 2px 8px
                    display: flex
                    flex-wrap: wrap
                    margin: 0
                    position: relative
                    label[for="email-input"]
                        width: 100%
                        height: 100%
                        position: absolute
                        top: 0
                        left: 0
                        z-index: 2
                        cursor: pointer
                    .email-tag
                        color: #000
                        background-color: $gray-lighter
                        font-weight: 500
                        padding: 2px 4px
                        border-radius: 12px
                        margin-bottom: 2px
                        margin-right: 4px
                        z-index: 3
                    input
                        border: none
                        height: 100%
                        width: 100%
                        margin: auto 0
                        &:focus
                            outline: none
            .checkboxs
                display: flex
                align-items: center
                justify-content: flex-start
                .square-button
                    margin-right: 32px
                    label
                        font-size: 14px
                        font-weight: 400

        .modal-footer
            display: flex
            border: none
            justify-content: right
            padding: 15px 40px
            button
                min-width: auto
                padding: 12px 32px
                margin: 0
                &.primary
                    border: none
