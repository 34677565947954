@import '../../../styles/styles.scss'

.user-change-password-modal
	.modal-dialog
		min-width: 600px
	.modal-content
		min-width: 600px
		border-radius: 12px
		border-left: 5px solid $primary
		padding: 0px 27px
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15)		
		.li 
			font-weight: 300
		.modal-text 
			font-size: $font-body
			color: $text-regular
			padding: 20px 0
			font-weight: 400
		.modal-body 
			padding: 0px
			.input 
				margin: 18px 0 0 0
				.input-holder
					max-width: none
					.show-password
						top: -1px
		.drawer 
			padding: 30px 52px
		&.bottom 
			margin-top: 20px
			padding: 20px 40px
		.show-password 
			right: 30px   
	.modal-header 
		padding: 15px 0
		display: flex
		align-items: center
		border-bottom: 1px solid #000
		img 
			cursor: pointer
		.material-symbols-filled
			color: $primary
			font-size: 20px
	.modal-title 
		letter-spacing: 0.05em
		flex-grow: 1
		//margin: 3.4px 120px 14px 8px;
		font-family: Poppins
		font-size: 20px
		font-weight: bold
		font-stretch: normal
		font-style: normal
		line-height: 1.06
		letter-spacing: normal
		text-align: left
		color: $primary    
	.modal-footer
		padding: 15px 0
		display: flex
		flex-direction: row
		justify-content: right
		border: 0
		.button.button.text-link-button
			min-width: 140px
			margin: 0
		.button.button.primary
			min-width: 140px
			margin: 0
	.tooltipContainer 
		display: flex
		position: absolute
	.fa-question-circle:before 
		content: '\f059'
		margin-left: 170px
		margin-top: -14px
	.notify
		font-family: Poppins
		font-size: 10px
		font-weight: normal
		font-stretch: normal
		font-style: normal
		line-height: normal
		letter-spacing: normal
		text-align: left