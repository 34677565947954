@import '../../../styles/styles.scss'

.delete-campaign
  display: flex
  justify-content: center
  .modal-content
    border-left: 2px solid #CB3333
    width: 392px
    height: 192px
    .modal-header
      border: 0
      display: flex
      .material-symbols-rounded
        color: #CB3333
      h4
        color: #CB3333
    .modal-body
      display: flex
      justify-content: center
      margin: 2rem 0 1rem 0
    .modal-footer
      border: 0
      display: flex
      justify-content: flex-end
      
      .button
        margin: 0
        border: 0
        min-width: 0
      .button-delete
        background-color: #CB3333
        color: white
      .alternative-gray-modal
        color: #773db0
        box-shadow: none
        
    
  

