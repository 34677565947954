@import '../../../styles/styles.scss';

.payment {
  .modal-content {
    min-width: 0px;
    background-color: white;
    border-radius: 20px;

    .modal-header {
      padding: 10px 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .modal-title {
      width: 100%;
      font-family: $font-body;
    }

    .li {
      font-weight: 300;
    }

    .modal-text {
      font-size: $font-body;
      color: $text-regular;
      padding: 20px 0;
      font-weight: 400;
    }

    .modal-body {
      display: flex;
      justify-content: center;
      padding: 20px 40px;

      .campaign-code-info {
        overflow: auto;
      }

      .campaign-name {
        // display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;
        word-wrap: break-word;
        text-align: center;
      }

      .campaign-code {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        margin: 5px 2px 10px 2px;
      }

      .qr {
        margin: 5px 2px 30px 2px;
        display: flex;
        justify-content: center;
      }
    }

    .drawer {
      padding: 30px 52px;
    }

    &.bottom {
      margin-top: 20px;
      padding: 20px 40px;
    }

    .modal-footer {
      padding: 20px 20px 40px;
      text-align: center;
      border-top: none;

      .button {
        padding-left: 100px;
        padding-right: 100px;
      }
    }
  }
}

.modal-header {
  img {
    cursor: pointer;
  }
}
