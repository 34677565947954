@import '../../../styles/styles.scss'

.dropdown.DropdownHelp
    button.material-symbols-rounded
        width: 26.7px
        z-index: 2
        position: relative
    .dropdown-menu
        margin-left: -260px
        max-width: 288px
        padding: 16px 15px 0px 15px
        border: none
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05)
        z-index: 1
        &.show
            right: -3px
            top: calc(100% - 3px)
            border-radius: 12px
            border-top-right-radius: 3px
        .dropdown-title
            font-weight: $font-semibold
            font-size: $font-xl
            padding-bottom: 7px
            border-bottom: 0.5px solid $gray-light
        .dropdown-body
            font-weight: $font-semibold
            margin: 8px 0 16px
            font-size: $font-sm
        .dropdown-footer
            text-align: center
            .redirect-link
                display: flex
                justify-content: center
                align-items: center
                border: 2px solid $primary
                border-radius: 8px
                text-decoration: none
                font-weight: $font-bold
                font-size: 12px
                padding: 12px 0
                &:hover
                    color: $primary
                &:visited
                    color: $primary
                span
                    font-size: 17px
                    padding: 0 10px 0 0
            button.button
                width: 100%
                box-shadow: none
                color: $primary
                &.text-link-button
                    margin: 8px 0
                    font-weight: $font-bold
                    span:not(.material-symbols-rounded)
                        font-size: $font-sm
                        padding-bottom: 2.5px
