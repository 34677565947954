@import '../../../styles/styles.scss';

.change-password-modal {
  .modal-content {
    min-width: 650px;
    background-color: white;
    border-radius: 15px;

    .modal-header {
      height: 74px;
      padding-left: 30px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start !important;
    }
    .modal-title {
      letter-spacing: 0.05em;
      flex-grow: 1;
    }

    .li {
      font-weight: 300;
    }

    .modal-text {
      font-size: $font-body;
      color: $text-regular;
      padding: 20px 0;
      font-weight: 400;
    }

    .modal-body {
      padding: 30px;
    }

    .drawer {
      padding: 30px 52px;
    }

    &.bottom {
      margin-top: 20px;
      padding: 20px 40px;
    }
    .show-password {
      right: 30px;
    }
  }
}

.modal-header {
  img {
    cursor: pointer;
  }
}

.tooltipContainer {
  display: flex;
  position: absolute;
}

.fa-question-circle:before {
  content: '\f059';
  margin-left: 170px;
  margin-top: -14px;
}
