@import '../../../styles/styles.scss'

.upload-file-container
    padding: 0

    .title-file
        display: flex
        justify-content: space-between
    .message-file
        display: flex
        color: #888
        padding-bottom: 20px
        span
            margin-right: 9px
        p
            font-size: 10px
            margin-bottom: 0
            @media (min-width: $lg)
                font-size: 12px
                &.description
                    font-size: 14px
    .file-charged
        display: flex
        align-items: flex-start
        justify-content: flex-start
        padding: 20px 0
        .attach_file
            color: #888
            margin-right: 7px
        .image-preview
            border-radius: 100%
            width: 50px
            height: 50px
            margin-right: 7px
        p
            font-weight: 700
            font-size: 12px
            display: flex
            flex-direction: column
            width: 150px
            @media (min-width: $lg)
                max-width: 200px
            .date-file
                color: #888
                font-size: 10px
                font-weight: 500
            span
                overflow: hidden
                text-overflow: ellipsis
                max-width: 200px
                white-space: nowrap

        button
            margin: 0
            padding: 0
            margin-top: -4px
            margin-left: 22px
            &.disabled
                opacity: 0.5
                cursor: not-allowed
            span
                color: red
                font-size: 20px
    .input-file
        margin-left: auto
        display: flex
        input[type="file"]#input-file
            width: 0.1px
            height: 0.1px
            opacity: 0
            overflow: hidden
            position: absolute
            z-index: -1
        label[for="input-file"]
            font-size: 10px
            padding: 8px 30px
            line-height: normal
            font-weight: 700
            color: #fff
            background-color: $primary
            cursor: pointer
            text-transform: uppercase
            border-radius: 8px
            text-align: center
            margin: auto 0
            display: flex
            align-items: center
            justify-content: center
            @media (min-width: $lg)
                padding: 12px 30px
                font-size: 16px
            &.disabled
                background-color: $gray-light
                color: #888
                cursor: not-allowed
            .attach_file
                margin-top: 0
                margin-bottom: 0
                margin-right: 6px
                font-size: 18px
                @media (min-width: $lg)
                    font-size: 20px
    button
        &.button
            border: 1px solid $primary
            min-height: auto
            min-width: auto
            // width: 156px
            margin-left: auto
            margin-top: auto
            margin-bottom: auto
            padding: 8px 30px
            font-size: 10px
            .icon-left
                font-size: 18px
            span
                color: $primary
                font-size: 14px
    .iframe-container
        display: flex
        position: fixed
        top: 0
        left: 0
        width: 100vw
        height: 100vh
        background-color: rgba(0,0,0, .5 )
        z-index: 1000000
        .video-viewer
            display: flex
            margin: auto
            height: 70vh
        iframe
            position: absolute
            top: 0
            height: 100vh
            margin: 0 auto
            width: 700px
            left: 0
            right: 0
            border: none
