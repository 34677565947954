@import '../../../styles/styles.scss';

.email-report-confirm-modal {
  .modal-dialog {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: 0;
    max-width: 100vw;
    display: flex;
  }
  .modal-content {
    width: 392px;
    max-width: 392px;
    margin-top: auto;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    border-left: solid 5px $primary;
    background-color: #ffffff;
    width: 587px;
    height: fit-content;
    //max-height: 217px;
    margin-top: auto;
    margin-bottom: auto;
    margin-inline: auto;
    //margin: 0;
    .modal-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      flex-direction: row;
      span {
        font-size: 32px;
        padding: 0 8px 0 0;
        color: $primary;
      }
      .modal-title {
        width: 194px;
        height: 21px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.06;
        letter-spacing: normal;
        text-align: left;
        color: $primary;
      }
    }

    .li {
      font-weight: 300;
    }

    .modal-text {
      font-size: $font-body;
      color: $text-regular;
      padding: 20px 0;
      font-weight: 400;
    }

    .modal-body {
      padding: 30px;
      padding-bottom: 0;
      font-size: 14px;
    }

    .drawer {
      padding: 30px 52px;
    }

    &.bottom {
      margin-top: 20px;
      padding: 20px 40px;
    }
  }

  .modal-footer {
    display: flex;
    border: none;
    justify-content: right;
    button {
      box-shadow: none;
      min-width: auto;
      padding: 12px 32px;
      margin: 0;
    }
    .cancel-button {
      color: $primary;
    }
  }
}
